<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">Subventions</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openSubsidyModal()"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          Ajouter une subvention
        </base-button>
        <!--<base-button
          type="info"
          icon
          size="sm"
          @click="downloadSubsidiesReport()"
        >
          <i class="fas fa-file-pdf"></i>
          Rapport des Subventions
        </base-button>-->
      </div>
    </div>

    <div class="row" v-if="event.subsidies && event.subsidies.length > 0">
      <div class="col-12 text-right">
        <base-button
          @click="collapseAll"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-compress-arrows-alt"></i>
          </span>
          Tout plier
        </base-button>
        <base-button
          @click="expandAll"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-expand-arrows-alt"></i>
          </span>
          Tout déplier
        </base-button>
      </div>
      <div class="col-12">
        <el-collapse :value="collapsed">
          <el-collapse-item
            v-for="subsidy in event.subsidies"
            v-bind:key="subsidy.id"
            :name="subsidy.id"
          >
            <template slot="title">
              <span class="display-6">
                <i class="ni ni-money-coins" />
                $ {{ subsidy.amount }} - {{ subsidy.name }}
              </span>
            </template>
            <div>
              <card class="slot-item">
                <div class="row align-items-center">
                  <div class="col-auto text-center">
                    <div class="display-5">$ {{ subsidy.amount }}</div>
                  </div>
                  <div class="col ml-4">
                    <div class="text-md mb-3 text-center">
                      <h1>
                        {{ subsidy.name }}
                      </h1>
                    </div>
                    <div>
                      <p class="text-md text-muted mb-3">
                        {{ subsidy.excerpt }}
                      </p>
                    </div>
                    <div>
                      <div class="text-info" v-if="subsidy.user">
                        ● Sponsor
                        <a href="#" @click.prevent="goToUser(subsidy.user)">{{
                          subsidy.user.name
                        }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <base-button
                      type="primary"
                      size="sm"
                      @click="openSubsidyModal(subsidy)"
                    >
                      <i class="fas fa-edit"></i>
                      Modifier
                    </base-button>
                  </div>
                </div>
              </card>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <!-- Modal add slot -->
    <modal :show.sync="showModal" modal-classes="modal-secondary">
      <form class="" @submit.prevent="saveAddSubsidy">
        <base-input
          label="Nom"
          placeholder="Nom"
          v-model="subsidyModel.name"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.name" />

        <base-input
          label="Description"
          placeholder="Description"
          input-classes="form-control-alternative"
        >
          <textarea
            class="form-control"
            rows="3"
            v-model="subsidyModel.excerpt"
          ></textarea>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />

        <base-input
          label="Montant"
          placeholder="Montant"
          v-model="subsidyModel.amount"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.amount" />

        <base-input label="Sponsor">
          <el-select
            filterable
            name="Sponsor"
            v-model="subsidyModel.user.id"
            prepend-icon="fas fa-calendar"
          >
            <el-option :value="null" label="Non défini"> </el-option>
            <el-option
              v-for="user in all_users"
              :key="user.id"
              :value="user.id"
              :label="user.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.user" />
      </form>

      <template slot="footer" v-if="!subsidyModel.id">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveAddSubsidy"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Ajouter une subvention
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closeSubsidyModal"
          :disabled="loading"
        >
          Fermer
        </button>
      </template>

      <template slot="footer" v-if="subsidyModel.id">
        <base-button
          native-type="submit"
          type="primary"
          class="new-event--add"
          @click="saveEditSubsidy"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Modifier
        </base-button>
        <base-button
          type="danger"
          @click="deleteSubsidy(subsidyModel.id)"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Supprimer
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="closeSubsidyModal"
          :disabled="loading"
        >
          Fermer
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Collapse, CollapseItem, Select, Option } from "element-ui";
import { cloneDeep, snakeCase } from "lodash";
import swal from "sweetalert2";

export default {
  name: "event-view-subsidy",

  components: {
    BaseInput,
    ValidationError,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["event"],

  data() {
    return {
      loading: false,
      showModal: false,
      all_users: [],
      collapsed: [],
      subsidyModel: {
        type: "event-subsidies",
        name: null,
        excerpt: null,
        amount: null,
        relationshipNames: ["event", "user"],
        user: {
          type: "users",
          id: null,
        },
        event: {
          type: "events",
          id: null,
        },
      },
    };
  },

  methods: {
    expandAll() {
      this.collapsed = this.event.subsidies.map((item) => item.id);
    },

    collapseAll() {
      this.collapsed = [];
    },

    openSubsidyModal(subsidy) {
      if (subsidy) {
        this.subsidyModel.id = subsidy.id;
        this.subsidyModel.name = subsidy.name;
        this.subsidyModel.excerpt = subsidy.excerpt;
        this.subsidyModel.amount = subsidy.amount;
        this.subsidyModel.user = {
          type: "users",
          id: subsidy.user ? subsidy.user.id : null,
        };
      } else {
        delete this.subsidyModel.id;
        this.subsidyModel.name = null;
        this.subsidyModel.excerpt = null;
        this.subsidyModel.amount = null;
        this.subsidyModel.user = {
          type: "users",
          id: null,
        };
      }
      this.resetApiValidation();
      this.showModal = true;
    },

    closeSubsidyModal() {
      this.showModal = false;
      this.loading = false;
    },

    goToUser(user) {
      this.$router.push({
        name: "Edit User",
        params: { id: user.id },
      });
    },

    async getUsers() {
      try {
        await this.$store.dispatch("users/list", {
          sort: "name",
          filter: { active: 1 },
        });
        this.all_users = await this.$store.getters["users/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async deleteSubsidy(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("eventSubsidies/destroy", id);
        this.$emit("subsidiesUpdated", null);

        this.$notify({
          type: "success",
          message: "Subsidy supprimé.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveAddSubsidy() {
      this.loading = true;
      const subsidyData = cloneDeep(this.subsidyModel);
      subsidyData.event.id = this.event.id;
      subsidyData.amount = parseFloat(subsidyData.amount);
      if (!subsidyData.user.id) {
        subsidyData.user = null;
      }

      try {
        await this.$store.dispatch("eventSubsidies/add", subsidyData);
        this.$emit("subsidiesUpdated", null);

        this.$notify({
          type: "success",
          message: "Kiosque ajouté.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveEditSubsidy() {
      this.loading = true;
      const subsidyData = cloneDeep(this.subsidyModel);
      subsidyData.event.id = this.event.id;
      subsidyData.amount = parseFloat(subsidyData.amount);
      if (!subsidyData.user.id) {
        subsidyData.user = null;
      }

      try {
        await this.$store.dispatch("eventSubsidies/update", subsidyData);
        this.$emit("subsidiesUpdated", null);

        this.$notify({
          type: "success",
          message: "Kiosque Modifié.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async downloadSubsidiesReport() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "events/getSubsidiesReport",
          this.event.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.event.name)}-subventions.pdf`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
  },

  created() {
    this.getUsers();
  },

  watch: {},
};
</script>
