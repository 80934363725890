export const TAX_TYPE_PERCENTAGE = "PERCENTAGE";
export const TAX_TYPE_AMOUNT = "AMOUNT";
export const TAX_TPS_NAME = "TPS (5%)";
export const TAX_TPS_VALUE = 5;
export const TAX_TVQ_NAME = "TVQ (9.975%)";
export const TAX_TVQ_VALUE = 9.975;

export const INVOICE_STATUS_DRAFT = "DRAFT";
export const INVOICE_STATUS_SENT = "SENT";
export const INVOICE_STATUS_PAID = "PAID";
export const INVOICE_STATUS_CANCELED = "CANCELED";

export const invoiceStatusesOption = {
  [INVOICE_STATUS_DRAFT]: "Brouillon",
  [INVOICE_STATUS_SENT]: "Envoyée",
  [INVOICE_STATUS_PAID]: "Payée",
  [INVOICE_STATUS_CANCELED]: "Annulée",
};
