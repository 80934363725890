<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <h3 class="mb-0">
                {{ registration.name }}
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="editRegistration()"
                  v-if="
                    registration.status == REGISTRATION_STATUS_DRAFT ||
                    registration.status == REGISTRATION_STATUS_REDRAFT
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                    Modifier
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="md"
                  @click="createRegistrationInvoice()"
                  :disabled="loading"
                  v-if="canInvoiceRegistration()"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-single-copy-04"></i>
                    Facturer
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="md"
                  @click="regenerateRegistrationInvoice()"
                  :disabled="loading"
                  v-if="registration.status == REGISTRATION_STATUS_REDRAFT"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-single-copy-04"></i>
                    Refacturer
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="md"
                  @click="completeRegistration()"
                  :disabled="loading"
                  v-if="registration.status == REGISTRATION_STATUS_REDRAFT"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-check-bold"></i>
                    Completer
                  </span>
                </base-button>

                <base-button
                  type="info"
                  icon
                  size="md"
                  @click="downloadReceipt()"
                  :disabled="loading"
                  v-if="registration.status === REGISTRATION_STATUS_COMPLETED"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-file-pdf"></i>
                    Télécharger le reçu
                  </span>
                </base-button>

                <base-button
                  type="info"
                  icon
                  size="md"
                  @click="
                    () => {
                      showSendReceiptModal = true;
                    }
                  "
                  :disabled="loading"
                  v-if="registration.status === REGISTRATION_STATUS_COMPLETED"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-paper-plane"></i>
                    Envoyer le reçu
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="md"
                  @click="redraftRegistration()"
                  :disabled="loading"
                  v-if="registration.status === REGISTRATION_STATUS_COMPLETED"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                    Editer
                  </span>
                </base-button>

                <base-button
                  type="danger"
                  icon
                  size="md"
                  @click="cancelRegistration()"
                  :disabled="loading"
                  v-if="registration.status !== REGISTRATION_STATUS_CANCELED"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-fat-remove"></i>
                    Annuler
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                Retour a la liste
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    Global
                  </span>
                  <registration-view-global :registration="registration" />
                </tab-pane>

                <tab-pane title="guests" id="2">
                  <span slot="title">
                    <i class="ni ni-calendar-grid-58" />
                    Accompagnants
                  </span>
                  <registration-guests
                    :registration="registration"
                    @guestsUpdated="get"
                  />
                </tab-pane>

                <tab-pane title="slots" id="3">
                  <span slot="title">
                    <i class="ni ni-single-02" />
                    Plages
                  </span>
                  <registration-view-slots
                    :registration="registration"
                    @slotsUpdated="get"
                  />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>

    <send-receipt-modal
      :registration="registration"
      :showModal="showSendReceiptModal"
      @onCloseModal="closeSendReceiptModal"
    />
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Tabs, TabPane } from "@/components";
import RegistrationViewGlobal from "./partials/RegistrationViewGlobal.vue";
import RegistrationViewSlots from "./partials/RegistrationViewSlots.vue";
import RegistrationGuests from "./partials/RegistrationGuests.vue";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import {
  REGISTRATION_STATUS_CANCELED,
  REGISTRATION_STATUS_COMPLETED,
  REGISTRATION_STATUS_DRAFT,
  REGISTRATION_STATUS_REDRAFT,
} from "../../constants/registrations";
import {
  TAX_TPS_NAME,
  TAX_TPS_VALUE,
  TAX_TVQ_NAME,
  TAX_TVQ_VALUE,
  TAX_TYPE_PERCENTAGE,
} from "../../constants/invoices";
import { snakeCase } from "lodash";
import SendReceiptModal from "./partials/SendReceiptModal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    RegistrationViewGlobal,
    RegistrationViewSlots,
    RegistrationGuests,
    SendReceiptModal,
  },

  data() {
    return {
      registration: {
        type: "registrations",
        price: 0,
        user: {},
        role: {},
        eventPackages: {},
        event: {},
        invoices: [],
        activeInvoices: [],
        guests: [],
      },
      event: {},
      loading: false,
      REGISTRATION_STATUS_DRAFT: REGISTRATION_STATUS_DRAFT,
      REGISTRATION_STATUS_CANCELED: REGISTRATION_STATUS_CANCELED,
      REGISTRATION_STATUS_COMPLETED: REGISTRATION_STATUS_COMPLETED,
      REGISTRATION_STATUS_REDRAFT: REGISTRATION_STATUS_REDRAFT,
      showSendReceiptModal: false,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        this.loading = true;
        const id = this.$route.params.id;
        await this.$store.dispatch("registrations/get", id);
        this.registration = this.$store.getters["registrations/registration"];

        await this.$store.dispatch(
          "events/get",
          this.registration.events[0].id
        );
        this.event = this.$store.getters["events/event"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      }
    },

    async editRegistration(row) {
      this.$router.push({
        name: "Edit Registration",
        params: { id: this.registration.id },
      });
    },

    async createRegistrationInvoice() {
      this.loading = true;
      let invoice = null;
      let invoiceItem = null;

      try {
        const invoiceData = {
          type: "invoices",
          billing_firstname: this.registration.user.firstname || "Non défini",
          billing_lastname: this.registration.user.lastname || "Non défini",
          billing_country: this.registration.user.country || "Canada",
          billing_state: this.registration.user.state || "Quebec",
          billing_city: this.registration.user.city || "Non défini",
          billing_zipcode: this.registration.user.zipcode || "Non défini",
          billing_address: this.registration.user.address || "Non défini",
          billing_email:
            this.registration.user.email || "non-defini@non-defini.com",
          excerpt: "",
          relationshipNames: ["recipient"],
        };

        await this.$store.dispatch("invoices/add", invoiceData);
        invoice = await this.$store.getters["invoices/invoice"];

        for (const pack of this.registration.eventPackages) {
          const invoiceItemData = {
            type: "invoice-items",
            code: this.registration.code,
            excerpt: `Inscription à l'évènement ${pack.event.name} - ${pack.name}`,
            unit_price: pack.price,
            quantity: 1,
            discount: 0,
            taxes: [
              // {
              //   name: TAX_TPS_NAME,
              //   type: TAX_TYPE_PERCENTAGE,
              //   value: TAX_TPS_VALUE,
              // },
              // {
              //   name: TAX_TVQ_NAME,
              //   type: TAX_TYPE_PERCENTAGE,
              //   value: TAX_TVQ_VALUE,
              // },
            ],
            relationshipNames: ["invoice", "invoiceable"],
            invoice: {
              type: "invoices",
              id: invoice.id,
            },
            invoiceable: {
              type: "registrations",
              id: this.registration.id,
            },
          };

          await this.$store.dispatch("invoiceItems/add", invoiceItemData);
          invoiceItem = await this.$store.getters["invoices/invoice"];
        }

        for (const addon of this.registration.addons) {
          const addonsGuests = this.registration.guests.filter((guest) => {
            const guestTimeSlots = guest.eventTimeSlots.map((slot) => slot.id);
            return guestTimeSlots.includes(addon.eventTimeSlot.id);
          });

          const invoiceItemData = {
            type: "invoice-items",
            code: addon.eventTimeSlot.name,
            excerpt: `Addon ${addon.eventTimeSlot.name} , Evenement ${addon.eventPackage.event.name}`,
            unit_price: addon.price,
            quantity: 1 + addonsGuests.length,
            discount: 0,
            taxes: [
              // {
              //   name: TAX_TPS_NAME,
              //   type: TAX_TYPE_PERCENTAGE,
              //   value: TAX_TPS_VALUE,
              // },
              // {
              //   name: TAX_TVQ_NAME,
              //   type: TAX_TYPE_PERCENTAGE,
              //   value: TAX_TVQ_VALUE,
              // },
            ],
            relationshipNames: ["invoice", "invoiceable"],
            invoice: {
              type: "invoices",
              id: invoice.id,
            },
            invoiceable: {
              type: "event-package-addons",
              id: addon.id,
            },
          };

          await this.$store.dispatch("invoiceItems/add", invoiceItemData);
          invoiceItem = await this.$store.getters["invoices/invoice"];
        }

        await this.get();

        this.loading = false;
        this.$notify({
          type: "success",
          message: "Invoice added successfully.",
        });

        this.editRegistrationInvoice();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        if (invoice.id) {
          await this.$store.dispatch("invoices/destroy", invoice.id);
        }
        if (invoiceItem.id) {
          await this.$store.dispatch("invoiceItems/destroy", invoiceItem.id);
        }
      }
    },

    editRegistrationInvoice() {
      this.$router.push({
        name: "Edit Invoice",
        params: { id: this.registration.activeInvoices[0].id },
      });
    },

    viewRegistrationInvoice() {
      this.$router.push({
        name: "View Invoice",
        params: { id: this.registration.activeInvoices[0].id },
      });
    },

    async cancelRegistration() {
      const confirmation = await swal.fire({
        title: `Annuler cette inscription?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch(
          "registrations/cancel",
          this.registration.id
        );
        this.registration = this.$store.getters["registrations/registration"];
        this.$notify({
          type: "success",
          message: `Inscription annulée`,
        });
        this.loading = false;
      } catch (error) {
        let message = null;
        if (error.response.data.errors.length > 0) {
          message = error.response.data.errors[0].detail;
        }
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong! : ${message}`,
        });
        this.loading = false;
      }
    },

    async redraftRegistration() {
      const confirmation = await swal.fire({
        title: `Editer cette inscription?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch(
          "registrations/redraft",
          this.registration.id
        );
        this.registration = this.$store.getters["registrations/registration"];
        this.$notify({
          type: "success",
          message: `Inscription editee`,
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
        let message = null;
        if (error.response.data.errors.length > 0) {
          message = error.response.data.errors[0].detail;
        }
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong! : ${message}`,
        });
        this.loading = false;
      }
    },

    async regenerateRegistrationInvoice() {
      this.loading = true;
      let invoice = this.registration.activeInvoices[0];
      let invoiceItem = null;

      try {
        await this.$store.dispatch("invoices/get", invoice.id);
        invoice = await this.$store.getters["invoices/invoice"];

        for (const item of invoice.items) {
          if (item.invoiceable) {
            if (item.invoiceable.type === "registrations") {
              await this.$store.dispatch("invoiceItems/destroy", item.id);
            }
            if (item.invoiceable.type === "event-package-addons") {
              await this.$store.dispatch("invoiceItems/destroy", item.id);
            }
          }
        }

        for (const pack of this.registration.eventPackages) {
          const invoiceItemData = {
            type: "invoice-items",
            code: this.registration.code,
            excerpt: `Inscription à l'évènement ${pack.event.name} - ${pack.name}`,
            unit_price: pack.price,
            quantity: 1,
            discount: 0,
            taxes: [
              // {
              //   name: TAX_TPS_NAME,
              //   type: TAX_TYPE_PERCENTAGE,
              //   value: TAX_TPS_VALUE,
              // },
              // {
              //   name: TAX_TVQ_NAME,
              //   type: TAX_TYPE_PERCENTAGE,
              //   value: TAX_TVQ_VALUE,
              // },
            ],
            relationshipNames: ["invoice", "invoiceable"],
            invoice: {
              type: "invoices",
              id: invoice.id,
            },
            invoiceable: {
              type: "registrations",
              id: this.registration.id,
            },
          };

          await this.$store.dispatch("invoiceItems/add", invoiceItemData);
          invoiceItem = await this.$store.getters["invoices/invoice"];
        }

        for (const addon of this.registration.addons) {
          const addonsGuests = this.registration.guests.filter((guest) => {
            const guestTimeSlots = guest.eventTimeSlots.map((slot) => slot.id);
            return guestTimeSlots.includes(addon.eventTimeSlot.id);
          });

          const invoiceItemData = {
            type: "invoice-items",
            code: addon.eventTimeSlot.name,
            excerpt: `Addon ${addon.eventTimeSlot.name} , Evenement ${addon.eventPackage.event.name}`,
            unit_price: addon.price,
            quantity: 1 + addonsGuests.length,
            discount: 0,
            taxes: [
              // {
              //   name: TAX_TPS_NAME,
              //   type: TAX_TYPE_PERCENTAGE,
              //   value: TAX_TPS_VALUE,
              // },
              // {
              //   name: TAX_TVQ_NAME,
              //   type: TAX_TYPE_PERCENTAGE,
              //   value: TAX_TVQ_VALUE,
              // },
            ],
            relationshipNames: ["invoice", "invoiceable"],
            invoice: {
              type: "invoices",
              id: invoice.id,
            },
            invoiceable: {
              type: "event-package-addons",
              id: addon.id,
            },
          };

          await this.$store.dispatch("invoiceItems/add", invoiceItemData);
          invoiceItem = await this.$store.getters["invoices/invoice"];
        }

        await this.get();

        this.loading = false;
        this.$notify({
          type: "success",
          message: "Invoice added successfully.",
        });

        this.viewRegistrationInvoice();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        if (invoice.id) {
          await this.$store.dispatch("invoices/destroy", invoice.id);
        }
        if (invoiceItem.id) {
          await this.$store.dispatch("invoiceItems/destroy", invoiceItem.id);
        }
      }
    },

    canInvoiceRegistration() {
      return (
        this.registration.activeInvoices.length === 0 &&
        this.registration.status !== REGISTRATION_STATUS_CANCELED
      );
    },

    async completeRegistration() {
      const confirmation = await swal.fire({
        title: `Completer cette inscription?`,
        html: `
          <div style="font-weight: 900;">
            L'inscription doit avoir ete refacturee et La facture doit avoir ete completement payee pour pour completer l'inscription<br>
            <span style="font-weight: 600;"> Si la facture n'est pas payee merci d'aller rajouter un paiement ou rebourser le paiement pour completer l'inscription</span>
          </div>
        `,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch(
          "registrations/complete",
          this.registration.id
        );
        this.registration = this.$store.getters["registrations/registration"];
        this.$notify({
          type: "success",
          message: `Inscription editee`,
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
        let message = null;
        if (error.response.data.errors.length > 0) {
          message = error.response.data.errors[0].detail;
        }
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong! : ${message}`,
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List Registrations" });
    },

    async downloadReceipt(slot) {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "registrations/getReceipt",
          this.registration.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.registration.events[0].name)}_${snakeCase(
            this.registration.code
          )}-recu.pdf`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    closeSendReceiptModal(refresh) {
      this.showSendReceiptModal = false;
      if (refresh) {
        this.get();
      }
    },
  },
};
</script>
