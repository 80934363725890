var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Liste des factures")])]),_c('div',{staticClass:"col-6 text-right"},[_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.addInvoice()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-edit"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v("Ajouter une facture")])])],1)])]),_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"},[_c('div',[_c('base-input',{attrs:{"type":"search","prepend-icon":"fas fa-search","placeholder":"Code","clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('div',[_c('base-input',{attrs:{"placeholder":"Dates"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
                  allowInput: true,
                  mode: 'range',
                  locale: _vm.flatPickrLocale,
                },"placeholder":"Dates"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1),_c('div',[_c('el-select',{attrs:{"filterable":true,"placeholder":"Destinataire"},model:{value:(_vm.recipient),callback:function ($$v) {_vm.recipient=$$v},expression:"recipient"}},[_c('el-option',{attrs:{"value":0,"label":"Tous"}}),_vm._l((_vm.users),function(user){return _c('el-option',{key:user.id,attrs:{"value":user.id,"label":user.name}})})],2)],1),_c('div',[_c('el-select',{attrs:{"placeholder":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('el-option',{attrs:{"value":0,"label":"Tous"}}),_vm._l((_vm.statusesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":key,"label":value}})})],2)],1)]),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"},[_c('div',[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1)],1)]),_c('el-table',{staticClass:"table-responsive align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.invoices},on:{"sort-change":_vm.sortChange}},[(_vm.loading)?_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('img',{staticStyle:{"height":"100px","width":"100px"},attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":"Code","min-width":"80px","prop":"code"}}),_c('el-table-column',{attrs:{"label":"Crée le","min-width":"100px","prop":"created_at","sortable":"created_at"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("moment")(row.created_at,"LLLL"))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Destinataire","min-width":"100px","prop":"recipient"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [(row.recipient)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goToUser(row.recipient)}}},[_vm._v(" "+_vm._s(_vm.users[row.recipient.id] ? _vm.users[row.recipient.id].name : null)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Statut","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('invoice-status-badge',{attrs:{"invoice":row}})]}}])}),_c('el-table-column',{attrs:{"label":"Montant","prop":"pricing.total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_vm._v(" $"+_vm._s(row.pricing.total.toFixed(2))+" ")]}}])}),_c('el-table-column',{attrs:{"min-width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('el-tooltip',{attrs:{"content":"View","placement":"top"}},[_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewInvoice(row)}}},[_c('i',{staticClass:"fas fa-eye"})])]),_c('el-tooltip',{attrs:{"content":"Edit","placement":"top"}},[(row.status === _vm.INVOICE_STATUS_DRAFT)?_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.editInvoice(row)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e()]),_c('el-tooltip',{attrs:{"content":"Delete","placement":"top"}},[(row.status === _vm.INVOICE_STATUS_DRAFT)?_c('a',{staticClass:"table-action table-action-delete",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteInvoice(row.id)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e()])],1)}}])})],1)],1),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Affichage de "+_vm._s(_vm.total ? _vm.from + 1 : 0)+" à "+_vm._s(_vm.to)+" sur "+_vm._s(_vm.total)+" entrées "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.selectedRows.length)+" lignes sélectionnées ")]):_vm._e()])]),_c('base-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }