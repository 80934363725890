<template>
  <!-- Modal add slot -->
  <modal :show.sync="showModal" modal-classes="modal-secondary">
    <form class="" @submit.prevent="sendReceipt">
      <base-input v-model="sendReceiptModel.bcc" label="BCC"> </base-input>
      <validation-error :errors="apiValidationErrors.bcc" />
      <span v-for="(error, idx) in apiValidationErrors" :key="idx">
        <small class="argon-error" v-text="error[0]" />
      </span>
    </form>

    <template slot="footer">
      <button type="submit" class="btn btn-primary" @click="sendReceipt">
        Envoyer le reçu
      </button>
      <button type="button" class="btn btn-link ml-auto" @click="closeModal">
        Fermer
      </button>
    </template>
  </modal>
</template>

<script>
import swal from "sweetalert2";
import { Tooltip, Select, Option, Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    ValidationError,
  },

  mixins: [formMixin],

  props: ["registration", "showModal"],

  data() {
    return {
      sendReceiptModel: {
        bcc: "",
      },
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh);
    },

    async sendReceipt() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const sendReceiptData = {
          bcc: this.sendReceiptModel.bcc
            ? this.sendReceiptModel.bcc.split(",")
            : [],
          registration_id: this.registration.id,
        };

        await this.$store.dispatch(
          "registrations/sendReceipt",
          sendReceiptData
        );
        this.$notify({
          type: "success",
          message: "Reçu envoyé",
        });
        this.closeModal(true);
        swal.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
  },
};
</script>
