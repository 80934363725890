<template>
  <!-- Modal add slot -->
  <modal :show="showModal" modal-classes="modal-secondary">
    <form class="" @submit.prevent="sendReceipt">
      <base-input label="Role">
        <el-select name="Role" v-model="sendReceiptModel.role">
          <el-option value="" label="Tous" />
          <el-option
            v-for="role in event.allowedRoles"
            :key="role.id"
            :value="role.id"
            :label="role.name"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.role" />

      <base-input v-model="sendReceiptModel.bcc" label="BCC" placeholder="BCC">
      </base-input>
      <validation-error :errors="apiValidationErrors.bcc" />
      <span v-for="(error, idx) in apiValidationErrors" :key="idx">
        <small class="argon-error" v-text="error[0]" />
      </span>
    </form>

    <template slot="footer">
      <button type="submit" class="btn btn-primary" @click="sendReceipt">
        Envoyer le reçu
      </button>
      <button type="button" class="btn btn-link ml-auto" @click="closeModal">
        Fermer
      </button>
    </template>
  </modal>
</template>

<script>
import swal from "sweetalert2";
import { Tooltip, Select, Option, Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  components: {
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    ValidationError,
  },

  mixins: [formMixin],

  props: ["event", "showModal"],

  data() {
    return {
      sendReceiptModel: {
        bcc: "",
        role: "",
      },
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh);
    },

    async sendReceipt() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const sendReceiptData = {
          bcc: this.sendReceiptModel.bcc
            ? this.sendReceiptModel.bcc.split(",")
            : [],
          event_id: this.event.id,
          role: this.sendReceiptModel.role,
        };

        await this.$store.dispatch(
          "events/sendRegistrationsReceipts",
          sendReceiptData
        );
        this.$notify({
          type: "success",
          message: "Reçu envoyés",
        });
        this.closeModal(true);
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
  },
};
</script>
