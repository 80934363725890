<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ event.name }}</span>
      <span class="col-12">{{ event.excerpt }}</span>
    </div>

    <div class="row">
      <div class="col">
        <dl class="row">
          <dt class="col-sm-4">Créé le</dt>
          <dd class="col-sm-8">
            {{ event.created_at | moment("LLLL") }}
          </dd>

          <dt class="col-sm-4">Créé par</dt>
          <dd class="col-sm-8">
            <a href="#" @click.prevent="goToUser(event.user)">{{
              event.user ? event.user.name : null
            }}</a>
          </dd>

          <dt class="col-sm-4">Date debut preinscription</dt>
          <dd class="col-sm-8">
            <span v-if="event.early_registration_date">{{
              event.early_registration_date | moment("LL")
            }}</span>
          </dd>

          <dt class="col-sm-4">Date de fin d'inscription</dt>
          <dd class="col-sm-8">
            <span v-if="event.early_registration_date">{{
              event.registration_limit_date | moment("LL")
            }}</span>
          </dd>

          <dt class="col-sm-4">Date debut</dt>
          <dd class="col-sm-8">
            <span v-if="event.start_date">{{
              event.start_date | moment("LL")
            }}</span>
          </dd>

          <dt class="col-sm-4">Date fin</dt>
          <dd class="col-sm-8">
            <span v-if="event.end_date">{{
              event.end_date | moment("LL")
            }}</span>
          </dd>

          <dt class="col-sm-4">Roles</dt>
          <dd class="col-sm-8">
            <a
              v-for="role in event.allowedRoles"
              v-bind:key="role.id"
              href="#"
              @click.prevent="goToRole(role)"
              >{{ role.name }},
            </a>
          </dd>
        </dl>
      </div>
    </div>

    <div>
      <base-header class="pb-6 content__title content__title--calendar">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0">Dates</h6>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
            <a
              href="#"
              @click.prevent="prev"
              class="fullcalendar-btn-prev btn btn-sm btn-default"
            >
              <i class="fas fa-angle-left"></i>
            </a>
            <a
              href="#"
              @click.prevent="next"
              class="fullcalendar-btn-next btn btn-sm btn-default"
            >
              <i class="fas fa-angle-right"></i>
            </a>
            <base-button
              class="btn btn-sm btn-default"
              :class="{ active: defaultView === 'dayGridMonth' }"
              @click="changeView('dayGridMonth')"
            >
              Mois
            </base-button>
            <base-button
              class="btn btn-sm btn-default"
              :class="{ active: defaultView === 'dayGridWeek' }"
              @click="changeView('dayGridWeek')"
            >
              Semaine
            </base-button>
            <base-button
              class="btn btn-sm btn-default"
              :class="{ active: defaultView === 'timeGridDay' }"
              @click="changeView('timeGridDay')"
            >
              Jour
            </base-button>
          </div>
        </div>
      </base-header>

      <div class="container-fluid mt--6">
        <div class="row">
          <div class="col">
            <!-- Fullcalendar -->
            <div class="card card-calendar">
              <!-- Card header -->
              <div class="card-header">
                <!-- Title -->
                <h5 class="h3 mb-0">{{ viewTitle }}</h5>
              </div>
              <!-- Card body -->
              <div class="card-body p-0 card-calendar-body">
                <full-calendar
                  ref="fullCalendar"
                  class="calendar"
                  contentHeight="auto"
                  :locale="locale"
                  :events="eventsCalendar"
                  :plugins="calendarPlugins"
                  :theme="false"
                  :selectable="true"
                  :selectHelper="true"
                  :defaultView="defaultView"
                  :headerToolbar="headerToolbar"
                  @eventClick="onEventClick"
                >
                </full-calendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import moment from "moment";

export default {
  name: "event-view-global",

  components: {
    FullCalendar,
  },

  props: ["event"],

  data() {
    return {
      calendarPlugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      headerToolbar: {
        left: "title",
        center: "",
        right: "",
      },
      eventsCalendar: [],
      defaultView: "dayGridMonth",
      locale: frLocale,
      viewTitle: "",
    };
  },

  computed: {},

  methods: {
    goToRole(role) {
      this.$router.push({
        name: "Edit Role",
        params: { id: role.id },
      });
    },
    goToUser(user) {
      this.$router.push({
        name: "Edit User",
        params: { id: user.id },
      });
    },
    onEventClick({ el, event }) {
      //console.log(event);
      //console.log(el);
    },
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },
    changeView(viewType) {
      this.defaultView = viewType;
      this.calendarApi().changeView(viewType);
      if (this.eventsCalendar[0]) {
        this.calendarApi().changeView(viewType, this.eventsCalendar[0].start);
      }
    },
    next() {
      this.calendarApi().next();
      this.refreshViewTitle();
    },
    prev() {
      this.calendarApi().prev();
      this.refreshViewTitle();
    },
    refreshViewTitle() {
      this.viewTitle = this.calendarApi().view.title;
      window.dispatchEvent(new Event("resize"));
    },
    refreshCalendar() {
      this.eventsCalendar = [];
      this.event.eventDates.forEach((eventDate, index) => {
        eventDate.eventTimeSlots.forEach((slot, idx) => {
          this.eventsCalendar.push({
            title: `${slot.name}`,
            description: `${slot.excerpt}`,
            start: slot.start_time,
            end: slot.end_time,
            className: "bg-blue",
            display: "block",
            allDay: false,
          });
        });
      });
      if (this.eventsCalendar[0]) {
        this.calendarApi().changeView(
          this.defaultView,
          this.eventsCalendar[0].start
        );
      }
      this.refreshViewTitle();
    },
  },

  mounted() {},

  watch: {
    event(event) {
      this.refreshCalendar();
    },
  },
};
</script>
<style lang="scss">
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "~@/assets/sass/core/vendors/fullcalendar";
</style>
