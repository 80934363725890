<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Liste des inscriptions</h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="addRegistration()"
              >
                <span class="btn-inner--icon"><i class="fas fa-edit"></i></span>
                <span class="btn-inner--text">Ajouter une inscription</span>
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div class="row col-12">
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div>
                <base-input
                  v-model="code"
                  type="search"
                  prepend-icon="fas fa-search"
                  placeholder="Code..."
                  clearable
                />
              </div>

              <el-select placeholder="Status" v-model="status">
                <el-option :value="0" label="Tous"> </el-option>
                <el-option
                  v-for="(value, key) in statusesOption"
                  :key="key"
                  :value="key"
                  :label="value"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-5"
            >
              <el-select
                filterable
                name="Utilisateur"
                v-model="user"
                placeholder="Utilisateur"
              >
                <el-option :value="0" label="Tous" />
                <el-option
                  v-for="user in all_users"
                  :key="user.id"
                  :value="user.id"
                  :label="user.name"
                >
                </el-option>
              </el-select>

              <el-select
                filterable
                name="Role"
                v-model="role"
                placeholder="Role"
              >
                <el-option :value="0" label="Tous" />
                <el-option
                  v-for="role in all_roles"
                  :key="role.id"
                  :value="role.id"
                  :label="role.name"
                >
                </el-option>
              </el-select>

              <el-select
                filterable
                name="Evenements"
                v-model="event"
                placeholder="Evenements"
              >
                <el-option :value="0" label="Tous" />
                <el-option
                  v-for="event in all_events"
                  :key="event.id"
                  :value="event.id"
                  :label="event.name"
                >
                </el-option>
              </el-select>

              <el-select
                filterable
                name="Forfait"
                v-model="eventPackage"
                placeholder="Forfait"
              >
                <el-option :value="0" label="Tous" />
                <el-option
                  v-for="pack in all_event_packages"
                  :key="pack.id"
                  :value="pack.id"
                  :label="pack.name"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="registrations"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column label="Code" prop="code">
              <template slot-scope="{ row }">
                <span>{{ row.code }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Statut" prop="status">
              <template slot-scope="{ row }">
                <registration-status-badge :registration="row" />
              </template>
            </el-table-column>

            <el-table-column label="Utilisateur" prop="user">
              <template slot-scope="{ row }">
                <span>{{ row.user.name }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Role" prop="role">
              <template slot-scope="{ row }">
                <span>{{ row.role.name }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Evenement" prop="event">
              <template slot-scope="{ row }">
                <div v-for="event in row.events" :key="event.id">
                  <a> {{ event.name }} <br /> </a>
                  <span
                    class="text-muted"
                    v-for="pack in row.eventPackages"
                    :key="pack.id"
                  >
                    {{ pack.name }} <br />
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              label="Date"
              prop="date"
              min-width="100px"
              sortable="custom"
            >
              <template slot-scope="{ row }">
                <span>{{ row.date | moment("LLLL") }}</span>
              </template>
            </el-table-column>

            <el-table-column min-width="50px" align="center">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip content="View" placement="top">
                  <a
                    type="text"
                    @click="viewRegistration(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-eye"></i>
                  </a>
                </el-tooltip>

                <el-tooltip content="Edit" placement="top">
                  <a
                    type="text"
                    @click="editRegistration(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                    v-if="row.status === REGISTRATION_STATUS_DRAFT"
                  >
                    <i class="fas fa-edit"></i>
                  </a>
                </el-tooltip>

                <el-tooltip content="Delete" placement="top">
                  <a
                    type="text"
                    @click="deleteRegistration(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                    v-if="row.status === REGISTRATION_STATUS_DRAFT"
                  >
                    <i class="fas fa-trash"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Affichage de {{ total ? from + 1 : 0 }} à {{ to }} sur
              {{ total }} entrées

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} lignes sélectionnées
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { BasePagination, RegistrationStatusBadge } from "@/components";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import "flatpickr/dist/flatpickr.css";
import {
  registrationStatusesOption,
  REGISTRATION_STATUS_DRAFT,
} from "../../constants/registrations";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    RegistrationStatusBadge,
  },

  props: [],

  data() {
    return {
      code: null,
      selectedRows: [],
      all_users: [],
      all_roles: [],
      all_events: [],
      all_event_packages: [],
      sort: "-date",
      statusesOption: registrationStatusesOption,
      total: 0,
      user: null,
      role: null,
      event: null,
      eventPackage: null,
      status: null,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 200, 500],
      },
      registrations: [],
      loading: false,
      REGISTRATION_STATUS_DRAFT: REGISTRATION_STATUS_DRAFT,
    };
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    code: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    event: {
      handler: "changeEvent",
      immediate: true,
    },
    user: {
      handler: "getList",
      immediate: true,
    },
    role: {
      handler: "getList",
      immediate: true,
    },
    eventPackage: {
      handler: "getList",
      immediate: true,
    },
    status: {
      handler: "getList",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.code ? { code: this.code } : {}),
            ...(this.user ? { user: this.user } : {}),
            ...(this.role ? { role: this.role } : {}),
            ...(this.eventPackage ? { eventPackage: this.eventPackage } : {}),
            ...(this.status ? { status: this.status } : {}),
            ...(this.event ? { event: this.event } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "user,role,eventPackages,events",
        };

        await this.$store.dispatch("registrations/list", params);
        this.registrations = this.$store.getters["registrations/list"];
        this.total = this.$store.getters["registrations/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    addRegistration() {
      this.$router.push({ name: "Add Registration" });
    },

    async editRegistration(row) {
      this.$router.push({
        name: "Edit Registration",
        params: { id: row.id },
      });
    },

    async viewRegistration(row) {
      this.$router.push({
        name: "View Registration",
        params: { id: row.id },
      });
    },

    async deleteRegistration(id) {
      const confirmation = await swal.fire({
        title: `Supprimer cette inscription?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("registration/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Inscription supprimée!",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    changeEvent() {
      this.eventPackage = null;
      this.getEventPackages();
      this.getList();
    },

    async getUsers() {
      try {
        await this.$store.dispatch("users/list", {
          sort: "name",
          filter: { active: 1 },
        });
        this.all_users = await this.$store.getters["users/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async getRoles() {
      try {
        await this.$store.dispatch("roles/list", { sort: "name" });
        this.all_roles = await this.$store.getters["roles/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async getEvents() {
      try {
        await this.$store.dispatch("events/list", { sort: "name" });
        this.all_events = await this.$store.getters["events/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async getEventPackages() {
      try {
        let params = {
          sort: "name",
          filter: {
            ...(this.event ? { event: this.event } : {}),
          },
        };
        await this.$store.dispatch("eventPackages/list", params);
        this.all_event_packages = await this.$store.getters[
          "eventPackages/dropdown"
        ];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },

  created() {
    this.getUsers();
    this.getRoles();
    this.getEvents();
  },
};
</script>
