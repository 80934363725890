<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">Details</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openInvoiceItemModal()"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          Ajouter une ligne
        </base-button>
      </div>
    </div>

    <div class="row mb-5">
      <el-table
        class="table-responsive align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="invoice.items"
      >
        <el-table-column label="Code" prop="code" min-width="300">
          <template v-slot="{ row }">
            <div v-if="row.invoiceable">
              <a href="#" @click.prevent="goToInvoiceable(row.invoiceable)">
                {{ row.code }} {{ getItemType(row) }}<br />
                <span class="text-muted">
                  {{ row.excerpt }}
                </span>
              </a>
            </div>
            <div v-if="!row.invoiceable">
              {{ row.code }} {{ getItemType(row) }}<br />
              <span class="text-muted">
                {{ row.excerpt }}
              </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Prix unitaire" prop="unit_price">
          <template v-slot="{ row }"> ${{ row.unit_price }} </template>
        </el-table-column>

        <el-table-column label="Quantité" prop="quantity" />

        <!-- <el-table-column label="Sous total" prop="discount">
          <template v-slot="{ row }">
            ${{ row.pricing.subtotal.toFixed(2) }}
          </template>
        </el-table-column> -->

        <!-- <el-table-column label="Remise" prop="discount">
          <template v-slot="{ row }">
            ${{ row.pricing.discount_amount.toFixed(2) }}
            <span class="text-muted">({{ row.discount }}%)</span>
          </template>
        </el-table-column> -->

        <!-- <el-table-column label="Taxes">
          <template v-slot="{ row }">
            ${{ row.pricing.taxes.total.toFixed(2) }}
          </template>
        </el-table-column> -->

        <el-table-column label="Total">
          <template v-slot="{ row }">
            ${{ row.pricing.total.toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column min-width="50px" align="center">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip content="Edit" placement="top">
              <a
                type="text"
                @click="openInvoiceItemModal(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-edit"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>

    <div class="row mb-5">
      <div class="col-4"></div>
      <div class="col-8">
        <dl class="row mb-0">
          <dt class="col-sm-9">
            <base-input
              label="Appliquer un code promo"
              placeholder="Code promo"
              v-model="promoCode"
              input-classes="form-control-alternative"
            >
            </base-input>
          </dt>
          <dd
            class="col-sm-3 text-right mb-3"
            style="
              display: flex;
              justify-content: flex-end;
              flex-direction: column;
            "
          >
            <base-button
              type="info"
              @click="applyPromoCode()"
              :disabled="loading || !promoCode"
            >
              <span v-if="loading" class="btn-inner--icon">
                <i class="fas fa-spinner fa-spin"></i>
              </span>
              Appliquer
            </base-button>
          </dd>
        </dl>
      </div>
    </div>

    <div class="row mb-5" v-if="invoice.pricing">
      <div class="col-4"></div>
      <div class="col-8">
        <dl class="row mb-0">
          <dt class="col-sm-6">Sous total</dt>
          <dd class="col-sm-6 text-right">
            ${{ invoice.pricing.subtotal.toFixed(2) }}
          </dd>
        </dl>
        <dl class="row mb-0 pl-5">
          <dt class="col-sm-6 text-muted">Total inscription</dt>
          <dd class="col-sm-6 text-muted text-right">
            ${{ invoice.pricing.totalRegistrations.toFixed(2) }}
          </dd>
        </dl>
        <dl class="row mb-0 pl-5">
          <dt class="col-sm-6 text-muted">Total Social</dt>
          <dd class="col-sm-6 text-muted text-right">
            ${{ invoice.pricing.totalSocial.toFixed(2) }}
          </dd>
        </dl>

        <dl class="row mb-0" v-if="invoice.pricing.discounts.total">
          <dt class="col-sm-6">Remises</dt>
          <dd class="col-sm-6 text-right">
            ${{ invoice.pricing.discounts.total.toFixed(2) * -1 }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(discount, index) in invoice.pricing.discounts.details"
              v-bind:key="index"
            >
              <dt class="col-sm-8 pl-5 text-muted">
                <button
                  class="btn btn-sm btn-danger"
                  v-if="discount.promoCode"
                  @click="removePromoCode(discount.promoCode)"
                >
                  <i class="fa fa-times"> </i>
                </button>
                {{ discount.name }} {{ getDiscountType(discount) }}
              </dt>
              <dd class="col-sm-4 text-right text-muted">
                ${{ discount.amount.toFixed(2) * -1 }}
              </dd>
            </dl>
          </div>
        </dl>

        <!-- <dl class="row mb-0" v-if="invoice.pricing.taxes.total">
          <dt class="col-sm-6">Taxes</dt>
          <dd class="col-sm-6 text-right">
            ${{ invoice.pricing.taxes.total.toFixed(2) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(tax, index) in invoice.pricing.taxes.details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ tax.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                ${{ tax.amount.toFixed(2) }}
              </dd>
            </dl>
          </div>
        </dl> -->

        <dl class="row mb-0">
          <dt class="col-sm-6">Total</dt>
          <dd class="col-sm-6 text-right">
            ${{ invoice.pricing.total.toFixed(2) }}
          </dd>
        </dl>

        <dl class="row mb-0">
          <dt class="col-sm-6">Total payé</dt>
          <dd class="col-sm-6 text-right">
            ${{ invoice.total_paied.toFixed(2) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-6">Reste à payé</dt>
          <dd class="col-sm-6 text-right">
            ${{
              (
                invoice.pricing.total.toFixed(2) -
                invoice.total_paied.toFixed(2)
              ).toFixed(2)
            }}
          </dd>
        </dl>
      </div>
    </div>

    <!-- Modal add slot -->
    <modal :show.sync="showModal" modal-classes="modal-secondary">
      <form class="" @submit.prevent="saveAddInvoiceItem">
        <base-input label="Type">
          <el-select
            name="Type"
            v-model="invoiceItemModel.invoiceable.type"
            prepend-icon="fas fa-calendar"
            @change="changeInvoiceableType"
          >
            <el-option :value="null" label="Aucun"> </el-option>
            <el-option value="eventKiosks" label="Kiosque"> </el-option>
            <!--<el-option value="registrations" label="Inscriptions"> </el-option>-->
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.invoiceable" />

        <base-input label="Produit" v-if="invoiceItemModel.invoiceable.type">
          <el-select
            filterable
            name="Produit"
            v-model="invoiceItemModel.invoiceable.id"
            prepend-icon="fas fa-calendar"
            @change="changeInvoiceable"
          >
            <el-option
              v-for="el in all_invoiceables"
              :key="el.id"
              :value="el.id"
              :label="el.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error
          v-if="invoiceItemModel.invoiceable.type"
          :errors="apiValidationErrors.invoiceable"
        />

        <base-input
          label="Code"
          placeholder="Code"
          v-model="invoiceItemModel.code"
          input-classes="form-control-alternative"
          v-if="!invoiceItemModel.invoiceable.type"
        >
        </base-input>
        <validation-error
          v-if="!invoiceItemModel.invoiceable.type"
          :errors="apiValidationErrors.code"
        />

        <base-input
          label="Description"
          placeholder="Description"
          input-classes="form-control-alternative"
        >
          <textarea
            class="form-control"
            rows="3"
            v-model="invoiceItemModel.excerpt"
          ></textarea>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />

        <base-input
          label="Prix ($)"
          placeholder="Prix ($)"
          v-model="invoiceItemModel.unit_price"
          type="number"
          step="0.0001"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.unit_price" />

        <base-input
          label="Quantité"
          placeholder="Quantité"
          v-model="invoiceItemModel.quantity"
          type="number"
          step="1"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.quantity" />

        <!-- <base-checkbox v-model="invoiceItemModel.has_tps" class="mb-3">
          <span class="form-control-label">Taxé TPS (5%)</span>
        </base-checkbox>

        <base-checkbox v-model="invoiceItemModel.has_tvq" class="mb-3">
          <span class="form-control-label">Taxé TVQ (9.975%)</span>
        </base-checkbox> -->

        <!-- <base-input
          label="Remise (%)"
          placeholder="Remise"
          v-model="invoiceItemModel.discount"
          type="number"
          step="0.0001"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.discount" /> -->

        <base-input label="Categorie" v-if="!invoiceItemModel.invoiceable.type">
          <el-select
            filterable
            name="Categorie"
            v-model="invoiceItemModel.package_type"
          >
            <el-option :value="PACKAGE_TYPE_REGISTRATION" label="Inscription">
            </el-option>
            <el-option :value="PACKAGE_TYPE_SOCIAL" label="Social"> </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.package_type" />
      </form>

      <template slot="footer" v-if="!invoiceItemModel.id">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveAddInvoiceItem"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Ajouter une ligne
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closeInvoiceItemModal"
          :disabled="loading"
        >
          Fermer
        </button>
      </template>

      <template slot="footer" v-if="invoiceItemModel.id">
        <base-button
          native-type="submit"
          type="primary"
          class="new-invoice-item--add"
          @click="saveEditInvoiceItem"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Modifier
        </base-button>
        <base-button
          type="danger"
          @click="deleteInvoiceItem(invoiceItemModel.id)"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Supprimer
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="closeInvoiceItemModal"
          :disabled="loading"
        >
          Fermer
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import swal from "sweetalert2";
import ValidationError from "@/components/ValidationError.vue";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { camelCase, cloneDeep, kebabCase } from "lodash";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  TAX_TPS_NAME,
  TAX_TPS_VALUE,
  TAX_TVQ_NAME,
  TAX_TVQ_VALUE,
  TAX_TYPE_PERCENTAGE,
} from "../../../constants/invoices";
import {
  PACKAGE_TYPE_REGISTRATION,
  PACKAGE_TYPE_SOCIAL,
} from "@/constants/promoCodes";

export default {
  name: "invoice-view-details",

  components: {
    ValidationError,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["invoice"],

  data() {
    return {
      loading: false,
      showModal: false,
      all_invoiceables: [],
      invoiceItemModel: {
        type: "invoice-items",
        code: null,
        excerpt: null,
        unit_price: null,
        quantity: null,
        discount: null,
        relationshipNames: ["invoice", "invoiceable"],
        invoice: {
          type: "invoices",
          id: null,
        },
        invoiceable: {
          type: null,
          id: null,
        },
        pricing: {},
      },
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      promoCode: null,
      PACKAGE_TYPE_REGISTRATION,
      PACKAGE_TYPE_SOCIAL,
    };
  },

  computed: {},

  methods: {
    getItemType(item) {
      if (item.package_type === "REGISTRATION") {
        return "(inscription)";
      } else if (item.package_type === "SOCIAL") {
        return "(social)";
      } else if (item.invoiceable.type == "registrations") {
        return "(inscription)";
      } else if (item.invoiceable.type == "event-package-addons") {
        return "(social)";
      }
    },

    getDiscountType(discount) {
      if (discount.discount_registration_type == "REGISTRATION") {
        return "(inscription)";
      } else if (discount.discount_registration_type == "SOCIAL") {
        return "(social)";
      }
    },

    openInvoiceItemModal(invoiceItem) {
      if (invoiceItem) {
        this.invoiceItemModel.id = invoiceItem.id;
        this.invoiceItemModel.code = invoiceItem.code;
        this.invoiceItemModel.excerpt = invoiceItem.excerpt;
        this.invoiceItemModel.unit_price = invoiceItem.unit_price;
        this.invoiceItemModel.quantity = invoiceItem.quantity;
        this.invoiceItemModel.discount = invoiceItem.discount;
        this.invoiceItemModel.has_tps = invoiceItem.pricing.taxes.details.some(
          (tax) => tax.name === TAX_TPS_NAME
        );
        this.invoiceItemModel.has_tvq = invoiceItem.pricing.taxes.details.some(
          (tax) => tax.name === TAX_TVQ_NAME
        );
        this.invoiceItemModel.invoice = {
          type: "invoices",
          id: invoiceItem.invoice ? invoiceItem.invoice.id : null,
        };
        this.invoiceItemModel.invoiceable = {
          type: invoiceItem.invoiceable
            ? camelCase(invoiceItem.invoiceable.type)
            : null,
          id: invoiceItem.invoiceable ? invoiceItem.invoiceable.id : null,
        };
        if (invoiceItem.invoiceable && invoiceItem.invoiceable.type) {
          this.getInvoiceables(camelCase(invoiceItem.invoiceable.type));
        }
      } else {
        delete this.invoiceItemModel.id;
        this.invoiceItemModel.code = null;
        this.invoiceItemModel.excerpt = null;
        this.invoiceItemModel.unit_price = 0;
        this.invoiceItemModel.quantity = 1;
        this.invoiceItemModel.discount = 0;
        this.invoiceItemModel.has_tps = null;
        this.invoiceItemModel.has_tvq = null;
        this.invoiceItemModel.invoice = {
          type: "invoices",
          id: null,
        };
        this.invoiceItemModel.invoiceable = {
          type: null,
          id: null,
        };
      }
      this.resetApiValidation();
      this.showModal = true;
    },

    closeInvoiceItemModal() {
      this.showModal = false;
      this.loading = false;
    },

    async getInvoiceables(type) {
      try {
        let sort = "name";
        if (type === "registrations") {
          sort = "-date";
        }
        await this.$store.dispatch(type + "/list", {
          sort: sort,
        });
        this.all_invoiceables = await this.$store.getters[type + "/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async changeInvoiceableType(type) {
      if (!type) {
        return;
      }
      await this.getInvoiceables(type);
      this.invoiceItemModel.invoiceable.id = null;
    },

    async changeInvoiceable(id) {
      const invoiceables = await this.$store.getters[
        this.invoiceItemModel.invoiceable.type + "/list"
      ];
      const invoiceable = invoiceables.find((item) => item.id === id);
      this.invoiceItemModel.unit_price = invoiceable.price;

      if (invoiceable.type === "event-kiosks") {
        this.invoiceItemModel.code = "Kiosque : " + invoiceable.full_kiosk_name;
      } else if (invoiceable.type === "registrations") {
        this.invoiceItemModel.code = "Inscription : " + invoiceable.code;
      }
    },

    async deleteInvoiceItem(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("invoiceItems/destroy", id);
        this.$emit("invoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: "InvoiceItem supprimé.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveAddInvoiceItem() {
      this.loading = true;
      const invoiceItemData = cloneDeep(this.invoiceItemModel);
      invoiceItemData.invoice.id = this.invoice.id;
      invoiceItemData.unit_price = parseFloat(invoiceItemData.unit_price);
      invoiceItemData.quantity = parseInt(invoiceItemData.quantity, 10);
      invoiceItemData.discount = parseFloat(invoiceItemData.discount);
      if (!invoiceItemData.invoiceable.id) {
        invoiceItemData.invoiceable = null;
      }
      invoiceItemData.taxes = [];
      if (invoiceItemData.has_tps) {
        invoiceItemData.taxes.push({
          name: TAX_TPS_NAME,
          type: TAX_TYPE_PERCENTAGE,
          value: TAX_TPS_VALUE,
        });
      }
      if (invoiceItemData.has_tvq) {
        invoiceItemData.taxes.push({
          name: TAX_TVQ_NAME,
          type: TAX_TYPE_PERCENTAGE,
          value: TAX_TVQ_VALUE,
        });
      }
      delete invoiceItemData.has_tps;
      delete invoiceItemData.has_tvq;
      if (invoiceItemData.invoiceable) {
        invoiceItemData.invoiceable.type = kebabCase(
          invoiceItemData.invoiceable.type
        );
      }

      try {
        await this.$store.dispatch("invoiceItems/add", invoiceItemData);
        this.$emit("invoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: "Kiosque ajouté.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveEditInvoiceItem() {
      this.loading = true;
      const invoiceItemData = cloneDeep(this.invoiceItemModel);
      invoiceItemData.invoice.id = this.invoice.id;
      invoiceItemData.unit_price = parseFloat(invoiceItemData.unit_price);
      invoiceItemData.quantity = parseInt(invoiceItemData.quantity, 10);
      invoiceItemData.discount = parseFloat(invoiceItemData.discount);
      if (!invoiceItemData.invoiceable.id) {
        invoiceItemData.invoiceable = null;
      }
      invoiceItemData.taxes = [];
      if (invoiceItemData.has_tps) {
        invoiceItemData.taxes.push({
          name: TAX_TPS_NAME,
          type: TAX_TYPE_PERCENTAGE,
          value: TAX_TPS_VALUE,
        });
      }
      if (invoiceItemData.has_tvq) {
        invoiceItemData.taxes.push({
          name: TAX_TVQ_NAME,
          type: TAX_TYPE_PERCENTAGE,
          value: TAX_TVQ_VALUE,
        });
      }
      delete invoiceItemData.has_tps;
      delete invoiceItemData.has_tvq;
      if (invoiceItemData.invoiceable) {
        invoiceItemData.invoiceable.type = kebabCase(
          invoiceItemData.invoiceable.type
        );
      }

      try {
        await this.$store.dispatch("invoiceItems/update", invoiceItemData);
        this.$emit("invoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: "Ligne facture rajoutee",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async goToInvoiceable(invoiceable) {
      if (invoiceable.type === "registrations") {
        this.$router.push({
          name: "View Registration",
          params: { id: invoiceable.id },
        });
      } else if (invoiceable.type === "event-kiosks") {
        await this.$store.dispatch("eventKiosks/get", invoiceable.id);
        const kiosk = this.$store.getters["eventKiosks/eventKiosk"];
        this.$router.push({
          name: "View Event",
          params: { id: kiosk.event.id },
        });
      }
    },

    async applyPromoCode() {
      this.loading = true;
      try {
        await this.$store.dispatch("invoices/applyPromoCode", {
          invoiceId: this.invoice.id,
          promoCode: this.promoCode,
        });
        this.$emit("invoiceItemsUpdated", null);
        this.promoCode = null;
        this.$notify({
          type: "success",
          message: "Code appliqué.",
        });

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        let errorText = "Erreur lors de l'application du code";
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.errors) {
              for (const err of error.response.data.errors) {
                errorText = err.detail ? err.detail : err.title;
              }
            }
          }
        }
        await swal.fire({
          title: errorText,
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        this.loading = false;
      }
    },

    async removePromoCode(promoCode) {
      this.loading = true;
      try {
        await this.$store.dispatch("invoices/removePromoCode", {
          invoiceId: this.invoice.id,
          promoCode: promoCode,
        });
        this.$emit("invoiceItemsUpdated", null);

        this.$notify({
          type: "success",
          message: "Code Supprimé.",
        });

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        let errorText = "Erreur lors de la suppression du code";
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.errors) {
              for (const err of error.response.data.errors) {
                errorText = err.detail ? err.detail : err.title;
              }
            }
          }
        }
        await swal.fire({
          title: errorText,
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
