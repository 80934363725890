<template>
  <badge :type="getBadgeType()">
    {{ statusesOptions[registration.status] }}
  </badge>
</template>

<script>
import {
  registrationStatusesOption,
  REGISTRATION_STATUS_DRAFT,
  REGISTRATION_STATUS_INVOICED,
  REGISTRATION_STATUS_COMPLETED,
  REGISTRATION_STATUS_CANCELED,
  REGISTRATION_STATUS_REDRAFT,
} from "../../../constants/registrations";

export default {
  name: "registration-status-badge",

  components: {},

  mixins: [],

  props: ["registration"],

  data() {
    return {
      statusesOptions: registrationStatusesOption,
    };
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.registration.status) {
        case REGISTRATION_STATUS_DRAFT:
          return "primary";
        case REGISTRATION_STATUS_INVOICED:
          return "default";
        case REGISTRATION_STATUS_COMPLETED:
          return "success";
        case REGISTRATION_STATUS_CANCELED:
          return "danger";
        case REGISTRATION_STATUS_REDRAFT:
          return "primary";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
