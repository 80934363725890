import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "@/views/GeneralViews/NotFoundPage.vue";

// Admin pages
import ListRolePage from "@/views/Admin/RoleManagement/ListRolePage.vue";
import EditRolePage from "@/views/Admin/RoleManagement/EditRolePage.vue";
import AddRolePage from "@/views/Admin/RoleManagement/AddRolePage.vue";

import ListUserPage from "@/views/Admin/UserManagement/ListUserPage.vue";
import EditUserPage from "@/views/Admin/UserManagement/EditUserPage.vue";
import AddUserPage from "@/views/Admin/UserManagement/AddUserPage.vue";

// Events pages
import ListEventPage from "@/views/EventManagement/ListEventPage.vue";
import EditEventPage from "@/views/EventManagement/EditEventPage.vue";
import ViewEventPage from "@/views/EventManagement/ViewEventPage.vue";
import AddEventPage from "@/views/EventManagement/AddEventPage.vue";

// Invoices pages
import ListInvoicePage from "@/views/InvoiceManagement/ListInvoicePage.vue";
import EditInvoicePage from "@/views/InvoiceManagement/EditInvoicePage.vue";
import ViewInvoicePage from "@/views/InvoiceManagement/ViewInvoicePage.vue";
import AddInvoicePage from "@/views/InvoiceManagement/AddInvoicePage.vue";

// Payments pages
import ListPaymentPage from "@/views/PaymentManagement/ListPaymentPage.vue";
import ViewPaymentPage from "@/views/PaymentManagement/ViewPaymentPage.vue";
import AddPaymentPage from "@/views/PaymentManagement/AddPaymentPage.vue";

// Registrations pages
import ListRegistrationPage from "@/views/RegistrationManagement/ListRegistrationPage.vue";
import EditRegistrationPage from "@/views/RegistrationManagement/EditRegistrationPage.vue";
import ViewRegistrationPage from "@/views/RegistrationManagement/ViewRegistrationPage.vue";
import AddRegistrationPage from "@/views/RegistrationManagement/AddRegistrationPage.vue";

// Me pages
import Profile from "@/views/Me/Profile.vue";

// Dashboard pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue");

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/UserProfile.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import admin from "@/middleware/admin";

let meMenu = {
  path: "/me",
  component: DashboardLayout,
  name: "Me",
  children: [
    {
      path: "profile",
      name: "Profile",
      components: { default: Profile },
      meta: { middleware: auth },
    },
  ],
};

let adminMenu = {
  path: "/admin",
  component: DashboardLayout,
  name: "Admin",
  children: [
    {
      path: "roles",
      name: "Roles",
      components: { default: ListRolePage },
      meta: { middleware: admin },
    },
    {
      path: "roles/add",
      name: "Add Role",
      components: { default: AddRolePage },
      meta: { middleware: admin },
    },
    {
      path: "roles/edit/:id",
      name: "Edit Role",
      components: { default: EditRolePage },
      meta: { middleware: admin },
    },
    {
      path: "users",
      name: "List Users",
      components: { default: ListUserPage },
      meta: { middleware: admin },
    },
    {
      path: "users/add",
      name: "Add User",
      components: { default: AddUserPage },
      meta: { middleware: admin },
    },
    {
      path: "users/edit/:id",
      name: "Edit User",
      components: { default: EditUserPage },
      meta: { middleware: admin },
    },
  ],
};

let eventsMenu = {
  path: "/events",
  component: DashboardLayout,
  name: "Evenements",
  children: [
    {
      path: "",
      name: "List Events",
      components: { default: ListEventPage },
      meta: { middleware: admin },
    },
    {
      path: "add",
      name: "Add Event",
      components: { default: AddEventPage },
      meta: { middleware: admin },
    },
    {
      path: "view/:id",
      name: "View Event",
      components: { default: ViewEventPage },
      meta: { middleware: admin },
    },
    {
      path: "edit/:id",
      name: "Edit Event",
      components: { default: EditEventPage },
      meta: { middleware: admin },
    },
  ],
};

let invoicesMenu = {
  path: "/invoices",
  component: DashboardLayout,
  name: "Invoices",
  children: [
    {
      path: "",
      name: "List Invoices",
      components: { default: ListInvoicePage },
      meta: { middleware: admin },
    },
    {
      path: "add",
      name: "Add Invoice",
      components: { default: AddInvoicePage },
      meta: { middleware: admin },
    },
    {
      path: "view/:id",
      name: "View Invoice",
      components: { default: ViewInvoicePage },
      meta: { middleware: admin },
    },
    {
      path: "edit/:id",
      name: "Edit Invoice",
      components: { default: EditInvoicePage },
      meta: { middleware: admin },
    },
  ],
};

let paymentsMenu = {
  path: "/payments",
  component: DashboardLayout,
  name: "Payments",
  children: [
    {
      path: "",
      name: "List Payments",
      components: { default: ListPaymentPage },
      meta: { middleware: admin },
    },
    {
      path: "add",
      name: "Add Payment",
      components: { default: AddPaymentPage },
      meta: { middleware: admin },
    },
    {
      path: "view/:id",
      name: "View Payment",
      components: { default: ViewPaymentPage },
      meta: { middleware: admin },
    },
  ],
};

let registrationsMenu = {
  path: "/registrations",
  component: DashboardLayout,
  name: "Registrations",
  children: [
    {
      path: "",
      name: "List Registrations",
      components: { default: ListRegistrationPage },
      meta: { middleware: admin },
    },
    {
      path: "add",
      name: "Add Registration",
      components: { default: AddRegistrationPage },
      meta: { middleware: admin },
    },
    {
      path: "view/:id",
      name: "View Registration",
      components: { default: ViewRegistrationPage },
      meta: { middleware: admin },
    },
    {
      path: "edit/:id",
      name: "Edit Registration",
      components: { default: EditRegistrationPage },
      meta: { middleware: admin },
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User,
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest },
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  meMenu,
  adminMenu,
  eventsMenu,
  invoicesMenu,
  paymentsMenu,
  registrationsMenu,
  pagesMenu,
  {
    path: "/",
    component: DashboardLayout,
    name: "Dashboard layout",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { middleware: auth },
      },
      {
        path: "*",
        name: "NotFound",
        component: NotFound,
      },
    ],
  },
  authPages,
];

export default routes;
