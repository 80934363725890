<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">Utilisateur spéciaux</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openCustomUserModal()"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          Ajouter un utilisateur
        </base-button>
      </div>
    </div>

    <div class="row" v-if="event.customUsers && event.customUsers.length > 0">
      <div class="col-12">
        <card
          class="slot-item"
          v-for="customUser in event.customUsers"
          v-bind:key="customUser.id"
        >
          <div class="row align-items-center">
            <div class="col-10 text-left">
              <div class="display-5">
                <a href="#" @click.prevent="goToUser(customUser.user)">
                  {{ customUser.user.name }}
                </a>
                =>
                <a href="#" @click.prevent="">
                  {{ customUser.role.name }}
                </a>
              </div>
            </div>
            <div class="col-2">
              <div>
                <base-button
                  class="my-auto"
                  type="primary"
                  size="sm"
                  @click="openCustomUserModal(customUser)"
                  v-if="!customUser.is_invoice_paied"
                >
                  <i class="fas fa-edit"></i>
                  Modifier
                </base-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!-- Modal add slot -->
    <modal :show.sync="showModal" modal-classes="modal-secondary">
      <form class="" @submit.prevent="saveAddCustomUser">
        <base-input label="Utilisateur">
          <el-select
            filterable
            name="Utilisateur"
            v-model="customUserModel.user.id"
          >
            <el-option
              v-for="user in all_users"
              :key="user.id"
              :value="user.id"
              :label="user.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.user" />

        <base-input label="Role">
          <el-select name="Role" v-model="customUserModel.role.id">
            <el-option
              v-for="role in all_roles"
              :key="role.id"
              :value="role.id"
              :label="role.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.role" />
      </form>

      <template slot="footer" v-if="!customUserModel.id">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveAddCustomUser"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Ajouter un utilisateur
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closeCustomUserModal"
          :disabled="loading"
        >
          Fermer
        </button>
      </template>

      <template slot="footer" v-if="customUserModel.id">
        <base-button
          native-type="submit"
          type="primary"
          class="new-event--add"
          @click="saveEditCustomUser"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Modifier
        </base-button>
        <base-button
          type="danger"
          @click="deleteCustomUser(customUserModel.id)"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Supprimer
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="closeCustomUserModal"
          :disabled="loading"
        >
          Fermer
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Collapse, CollapseItem, Select, Option } from "element-ui";
import { cloneDeep } from "lodash";

export default {
  name: "event-view-custom-user",

  components: {
    BaseInput,
    ValidationError,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["event"],

  data() {
    return {
      loading: false,
      showModal: false,
      all_users: [],
      all_roles: [],
      customUserModel: {
        type: "event-custom-users",
        relationshipNames: ["event", "user", "role"],
        user: {
          type: "users",
          id: null,
        },
        role: {
          type: "roles",
          id: null,
        },
        event: {
          type: "events",
          id: null,
        },
      },
    };
  },

  methods: {
    openCustomUserModal(customUser) {
      if (customUser) {
        this.customUserModel.id = customUser.id;
        this.customUserModel.user = {
          type: "users",
          id: customUser.user ? customUser.user.id : null,
        };
        this.customUserModel.role = {
          type: "roles",
          id: customUser.role ? customUser.role.id : null,
        };
        this.customUserModel.event = {
          type: "events",
          id: customUser.event ? customUser.event.id : null,
        };
      } else {
        delete this.customUserModel.id;
        this.customUserModel.user = {
          type: "users",
          id: null,
        };
        this.customUserModel.role = {
          type: "roles",
          id: null,
        };
        this.customUserModel.event = {
          type: "events",
          id: null,
        };
      }
      this.resetApiValidation();
      this.showModal = true;
    },

    closeCustomUserModal() {
      this.showModal = false;
      this.loading = false;
    },

    goToUser(user) {
      this.$router.push({
        name: "Edit User",
        params: { id: user.id },
      });
    },

    async getUsers() {
      try {
        await this.$store.dispatch("users/list", {
          sort: "name",
          filter: { active: 1 },
        });
        this.all_users = await this.$store.getters["users/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async getRoles() {
      try {
        this.all_roles = this.event.allowedRoles.map((role) => {
          return { id: role.id, name: role.name };
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async deleteCustomUser(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("eventCustomUsers/destroy", id);
        this.$emit("customUsersUpdated", null);

        this.$notify({
          type: "success",
          message: "CustomUser supprimé.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveAddCustomUser() {
      this.loading = true;
      const customUserData = cloneDeep(this.customUserModel);
      customUserData.event.id = this.event.id;

      try {
        await this.$store.dispatch("eventCustomUsers/add", customUserData);
        this.$emit("customUsersUpdated", null);

        this.$notify({
          type: "success",
          message: "Kiosque ajouté.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveEditCustomUser() {
      this.loading = true;
      const customUserData = cloneDeep(this.customUserModel);
      customUserData.event.id = this.event.id;

      try {
        await this.$store.dispatch("eventCustomUsers/update", customUserData);
        this.$emit("customUsersUpdated", null);

        this.$notify({
          type: "success",
          message: "Kiosque Modifié.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
  },

  created() {
    this.getUsers();
    this.getRoles();
  },

  watch: {
    event(event) {
      this.getRoles();
    },
  },
};
</script>
