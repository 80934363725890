<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-invoices-center">
            <div class="col-8">
              <h3 class="mb-0">Modifier une facture</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                Retour a la liste
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <h2>Addresse de facturation</h2>

              <div class="row">
                <div class="col">
                  <base-input label="Destinataire">
                    <el-select
                      name="Destinataire"
                      v-model="invoice.recipient.id"
                      @change="selectRecipient"
                    >
                      <el-option :value="null" label="Non défini"> </el-option>
                      <el-option
                        v-for="user in all_users"
                        :key="user.id"
                        :value="user.id"
                        :label="user.name"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.recipient" />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <base-input
                    label="Courriel"
                    v-model="invoice.billing_email"
                  />
                  <validation-error
                    :errors="apiValidationErrors.billing_email"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <base-input label="Nom" v-model="invoice.billing_firstname" />
                  <validation-error
                    :errors="apiValidationErrors.billing_firstname"
                  />
                </div>
                <div class="col">
                  <base-input
                    label="Prenom"
                    v-model="invoice.billing_lastname"
                  />
                  <validation-error
                    :errors="apiValidationErrors.billing_lastname"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <base-input label="Pays">
                    <el-select
                      name="Pays"
                      v-model="invoice.billing_country"
                      @change="selectCountry"
                    >
                      <el-option
                        v-for="country in getAllCountries()"
                        :key="country[0]"
                        :value="country[0]"
                        :label="country[0]"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.billing_country"
                  />
                </div>
                <div class="col">
                  <base-input label="Region">
                    <el-select name="Region" v-model="invoice.billing_state">
                      <el-option
                        v-for="region in getRegions()"
                        :key="region[0]"
                        :value="region[0]"
                        :label="region[0]"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.billing_state"
                  />
                </div>
                <div class="col">
                  <base-input label="Ville" v-model="invoice.billing_city" />
                  <validation-error
                    :errors="apiValidationErrors.billing_city"
                  />
                </div>
                <div class="col">
                  <base-input
                    label="Code postal"
                    v-model="invoice.billing_zipcode"
                  />
                  <validation-error
                    :errors="apiValidationErrors.billing_zipcode"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <base-input
                    label="Addresse"
                    v-model="invoice.billing_address"
                  />
                  <validation-error
                    :errors="apiValidationErrors.billing_address"
                  />
                </div>
              </div>

              <h2 class="mt-5">Note</h2>
              <div class="row">
                <div class="col">
                  <base-input>
                    <html-editor v-model="invoice.excerpt" name="editor" />
                  </base-input>
                  <validation-error :errors="apiValidationErrors.excerpt" />
                </div>
              </div>

              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Modifier une facture
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import "flatpickr/dist/flatpickr.css";
import { allCountries } from "country-region-data";
import { cloneDeep } from "lodash";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    BaseButton,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  data() {
    return {
      selectedCountry: null,
      all_users: [],
      invoice: {
        type: "invoices",
        billing_firstname: null,
        billing_lastname: null,
        billing_country: "Canada",
        billing_state: "Quebec",
        billing_city: null,
        billing_zipcode: null,
        billing_address: null,
        billing_email: null,
        excerpt: "",
        relationshipNames: ["recipient"],
        recipient: {
          type: "users",
          id: null,
        },
      },
    };
  },
  created() {
    this.get();
    this.getUsers();
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List Invoices" });
    },

    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("invoices/get", id);
        const invoice = this.$store.getters["invoices/invoice"];
        if (!invoice.recipient) {
          invoice.recipient = {
            type: "users",
            id: null,
          };
        }
        this.invoice = invoice;
        this.selectCountry(this.invoice.billing_country);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async viewInvoice(row) {
      this.$router.push({
        name: "View Invoice",
        params: { id: this.invoice.id },
      });
    },

    async handleSubmit() {
      const invoiceData = cloneDeep(this.invoice);
      if (!invoiceData.recipient.id) {
        invoiceData.recipient = null;
      }

      try {
        await this.$store.dispatch("invoices/update", invoiceData);
        this.invoice = await this.$store.getters["invoices/invoice"];
        if (!this.invoice.recipient) {
          this.invoice.recipient = {
            type: "users",
            id: null,
          };
        }
        this.$notify({
          type: "success",
          message: "Facture mise a jour.",
        });
        this.viewInvoice();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.invoice.id) {
          await this.$store.dispatch("invoices/destroy", this.invoice.id);
        }
      }
    },

    async getUsers() {
      try {
        await this.$store.dispatch("users/list", {
          sort: "name",
          filter: { active: 1 },
        });
        this.all_users = await this.$store.getters["users/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    getAllCountries() {
      return allCountries;
    },

    selectCountry(countryName) {
      const country = allCountries.find((el) => el[0] == countryName);
      this.selectedCountry = country;
    },

    getRegions() {
      return this.selectedCountry ? this.selectedCountry[2] : [];
    },

    selectRecipient(recipientId) {
      const recipient = this.all_users.find((el) => el.id == recipientId);
      if (recipient) {
      }
    },
  },
};
</script>
