<template>
  <div class="pl-0 pr-0">
    <div>
      <div class="row">
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <div>
            <base-input
              v-model="code"
              type="search"
              prepend-icon="fas fa-search"
              placeholder="Code..."
              clearable
            />
          </div>
        </div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-5"
        >
          <el-select placeholder="Status" v-model="status">
            <el-option :value="0" label="Tous"> </el-option>
            <el-option
              v-for="(value, key) in statusesOption"
              :key="key"
              :value="key"
              :label="value"
            >
            </el-option>
          </el-select>

          <el-select
            filterable
            name="Utilisateur"
            v-model="user"
            placeholder="Utilisateur"
          >
            <el-option :value="0" label="Tous" />
            <el-option
              v-for="user in all_users"
              :key="user.id"
              :value="user.id"
              :label="user.name"
            >
            </el-option>
          </el-select>

          <el-select filterable name="Role" v-model="role" placeholder="Role">
            <el-option :value="0" label="Tous" />
            <el-option
              v-for="role in event.allowedRoles"
              :key="role.id"
              :value="role.id"
              :label="role.name"
            >
            </el-option>
          </el-select>

          <el-select
            filterable
            name="Forfait"
            v-model="eventPackage"
            placeholder="Forfait"
          >
            <el-option :value="0" label="Tous" />
            <el-option
              v-for="pack in event.packages"
              :key="pack.id"
              :value="pack.id"
              :label="pack.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table
        class="table-responsive align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="registrations"
        @sort-change="sortChange"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>

        <el-table-column label="Code" prop="code">
          <template slot-scope="{ row }">
            <span>{{ row.code }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Statut" prop="status">
          <template slot-scope="{ row }">
            <registration-status-badge :registration="row" />
          </template>
        </el-table-column>

        <el-table-column label="Utilisateur" prop="user">
          <template slot-scope="{ row }">
            <span>{{ row.user.name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Role" prop="role">
          <template slot-scope="{ row }">
            <span>{{ row.role.name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Forfait" prop="eventPackage">
          <template slot-scope="{ row }">
            <span
              v-for="eventPackage in row.eventPackages"
              :key="eventPackage.id"
            >
              {{ eventPackage.name }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          label="Date"
          prop="date"
          min-width="100px"
          sortable="custom"
        >
          <template slot-scope="{ row }">
            <span>{{ row.date | moment("LLLL") }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="Date envoi reçu"
          prop="receipt_sent_last_date"
          min-width="100px"
          sortable="custom"
        >
          <template slot-scope="{ row }">
            <span>{{ row.receipt_sent_last_date | moment("LLLL") }}</span>
          </template>
        </el-table-column>

        <el-table-column min-width="50px" align="center">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip content="Modifier" placement="top">
              <a
                type="text"
                @click="viewRegistration(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-4"
    >
      <div class="">
        <p class="card-category">
          Affichage de {{ total ? from + 1 : 0 }} à {{ to }} sur
          {{ total }} entrées

          <span v-if="selectedRows.length">
            &nbsp; &nbsp; {{ selectedRows.length }} lignes sélectionnées
          </span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import { BasePagination, RegistrationStatusBadge } from "@/components";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import { registrationStatusesOption } from "../../../constants/registrations";

export default {
  name: "event-view-registration-list",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    RegistrationStatusBadge,
  },

  props: ["event", "all_users"],

  data() {
    return {
      code: null,
      selectedRows: [],
      sort: "date",
      statusesOption: registrationStatusesOption,
      total: 0,
      user: null,
      role: null,
      eventPackage: null,
      status: null,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 200, 500],
      },
      registrations: [],
      loading: false,
    };
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    code: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    event: {
      handler: "getList",
      immediate: true,
    },
    user: {
      handler: "getList",
      immediate: true,
    },
    role: {
      handler: "getList",
      immediate: true,
    },
    eventPackage: {
      handler: "getList",
      immediate: true,
    },
    status: {
      handler: "getList",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      if (!this.event.id) {
        return;
      }
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.code ? { code: this.code } : {}),
            ...(this.user ? { user: this.user } : {}),
            ...(this.role ? { role: this.role } : {}),
            ...(this.eventPackage ? { eventPackage: this.eventPackage } : {}),
            ...(this.status ? { status: this.status } : {}),
            ...{ event: this.event.id },
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "user,role,eventPackages",
        };

        await this.$store.dispatch("registrations/list", params);
        this.registrations = this.$store.getters["registrations/list"];
        this.total = this.$store.getters["registrations/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    viewRegistration(row) {
      this.$router.push({
        name: "View Registration",
        params: { id: row.id },
      });
    },
  },
};
</script>
