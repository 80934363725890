<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">Liste des factures</h3>
            </div>
            <div class="col-6 text-right">
              <base-button type="primary" icon size="sm" @click="addInvoice()">
                <span class="btn-inner--icon"><i class="fas fa-edit"></i></span>
                <span class="btn-inner--text">Ajouter une facture</span>
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"
          >
            <div>
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                placeholder="Code"
                clearable
              />
            </div>

            <div>
              <base-input placeholder="Dates">
                <flat-picker
                  :config="{
                    allowInput: true,
                    mode: 'range',
                    locale: flatPickrLocale,
                  }"
                  class="form-control datepicker"
                  v-model="dateRange"
                  placeholder="Dates"
                >
                </flat-picker>
              </base-input>
            </div>

            <div>
              <el-select :filterable="true" placeholder="Destinataire" v-model="recipient">
                <el-option :value="0" label="Tous"> </el-option>
                <el-option
                  v-for="user in users"
                  :key="user.id"
                  :value="user.id"
                  :label="user.name"
                >
                </el-option>
              </el-select>
            </div>

            <div>
              <el-select placeholder="Status" v-model="status">
                <el-option :value="0" label="Tous"> </el-option>
                <el-option
                  v-for="(value, key) in statusesOptions"
                  :key="key"
                  :value="key"
                  :label="value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mb-4"
          >
            <div>
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="invoices"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column label="Code" min-width="80px" prop="code" />

            <el-table-column
              label="Crée le"
              min-width="100px"
              prop="created_at"
              sortable="created_at"
            >
              <template slot-scope="{ row }">
                {{ row.created_at | moment("LLLL") }}
              </template>
            </el-table-column>

            <el-table-column
              label="Destinataire"
              min-width="100px"
              prop="recipient"
            >
              <template slot-scope="{ row }">
                <a
                  href="#"
                  @click.prevent="goToUser(row.recipient)"
                  v-if="row.recipient"
                >
                  {{
                    users[row.recipient.id]
                      ? users[row.recipient.id].name
                      : null
                  }}
                </a>
              </template>
            </el-table-column>

            <el-table-column label="Statut" prop="status">
              <template slot-scope="{ row }">
                <invoice-status-badge :invoice="row" />
              </template>
            </el-table-column>

            <el-table-column label="Montant" prop="pricing.total">
              <template slot-scope="{ row }">
                ${{ row.pricing.total.toFixed(2) }}
              </template>
            </el-table-column>

            <el-table-column min-width="50px" align="center">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip content="View" placement="top">
                  <a
                    type="text"
                    @click="viewInvoice(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fas fa-eye"></i>
                  </a>
                </el-tooltip>

                <el-tooltip content="Edit" placement="top">
                  <a
                    type="text"
                    @click="editInvoice(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                    v-if="row.status === INVOICE_STATUS_DRAFT"
                  >
                    <i class="fas fa-edit"></i>
                  </a>
                </el-tooltip>

                <el-tooltip content="Delete" placement="top">
                  <a
                    type="text"
                    @click="deleteInvoice(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                    v-if="row.status === INVOICE_STATUS_DRAFT"
                  >
                    <i class="fas fa-trash"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Affichage de {{ total ? from + 1 : 0 }} à {{ to }} sur
              {{ total }} entrées

              <span v-if="selectedRows.length">
                &nbsp; &nbsp; {{ selectedRows.length }} lignes sélectionnées
              </span>
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { French } from "flatpickr/dist/l10n/fr.js";
import {
  invoiceStatusesOption,
  INVOICE_STATUS_DRAFT,
} from "../../constants/invoices";
import InvoiceStatusBadge from "./partials/InvoiceStatusBadge.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    flatPicker,
    InvoiceStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      query: null,
      recipient: null,
      dateRange: null,
      status: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 200, 500],
      },
      invoices: [],
      users: {},
      statusesOptions: invoiceStatusesOption,
      loading: true,
      flatPickrLocale: French,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
    };
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    dateRange: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    recipient: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    status: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        const vDateRange = this.dateRange
          ? this.dateRange.split(this.flatPickrLocale.rangeSeparator)
          : [];

        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { code: this.query } : {}),
            ...(vDateRange.length == 2
              ? {
                  created_at: [
                    vDateRange[0] + " 00:00:00",
                    vDateRange[1] + " 23:59:59",
                  ],
                }
              : {}),
            ...(this.recipient ? { recipient: this.recipient } : {}),
            ...(this.status ? { status: this.status } : {}),
          },
          page: {
            number: this.pagination.currentPage
              ? this.pagination.currentPage
              : 1,
            size: this.pagination.perPage ? this.pagination.perPage : 20,
          },
          include: "",
        };

        await this.$store.dispatch("invoices/list", params);
        this.invoices = this.$store.getters["invoices/list"];
        this.total = this.$store.getters["invoices/listTotal"];

        this.getUsers();

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    async getUsers() {
      try {
        await this.$store.dispatch("users/list", { sort: "name" });
        const usersArr = await this.$store.getters["users/list"];
        this.users = {};
        usersArr.forEach((user) => {
          this.users[user.id] = user;
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async deleteInvoice(id) {
      const confirmation = await swal.fire({
        title: `Supprimer cet facture?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("invoices/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: "Facture supprimé!",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },

    addInvoice() {
      this.$router.push({ name: "Add Invoice" });
    },

    async editInvoice(row) {
      this.$router.push({
        name: "Edit Invoice",
        params: { id: row.id },
      });
    },

    async viewInvoice(row) {
      this.$router.push({
        name: "View Invoice",
        params: { id: row.id },
      });
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    goToUser(user) {
      this.$router.push({
        name: "Edit User",
        params: { id: user.id },
      });
    },
  },
};
</script>
