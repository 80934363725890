import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/registrations`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };
  const includes = [
    "user",
    "role",
    "events",
    "invoices",
    "eventPackages",
    "eventPackages.addons",
    "eventPackages.addons.eventTimeSlot",
    "eventPackages.addons.eventTimeSlot.eventDate",
    "eventPackages.addons.eventTimeSlot.guests",
    "eventPackages.eventTimeSlots",
    "eventPackages.eventTimeSlots.eventDate",
    "eventPackages.eventTimeSlots.guests",
    "eventTimeSlots",
    "eventTimeSlots.eventDate",
    "eventTimeSlots.guests",
    "addons",
    "addons.eventTimeSlot",
    "addons.eventTimeSlot.eventDate",
    "addons.eventTimeSlot.guests",
    "guests",
  ];
  return axios
    .get(`${url}/registrations/${id}?include=${includes.join(",")}`, options)
    .then((response) => {
      let registration = jsona.deserialize(response.data);
      delete registration.links;
      return registration;
    });
}

function add(registration) {
  const payload = jsona.serialize({
    stuff: registration,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/registrations?include=user,role,eventPackages`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(registration) {
  const payload = jsona.serialize({
    stuff: registration,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/registrations/${registration.id}?include=user,role,eventPackages`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/registrations/${id}`, options);
}

function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [
    "user",
    "role",
    "events",
    "invoices",
    "eventPackages",
    "eventPackages.addons",
    "eventPackages.addons.eventTimeSlot",
    "eventPackages.addons.eventTimeSlot.eventDate",
    "eventPackages.addons.eventTimeSlot.guests",
    "eventPackages.eventTimeSlots",
    "eventPackages.eventTimeSlots.eventDate",
    "eventPackages.eventTimeSlots.guests",
    "eventTimeSlots",
    "eventTimeSlots.eventDate",
    "eventTimeSlots.guests",
    "addons",
    "addons.eventTimeSlot",
    "addons.eventTimeSlot.eventDate",
    "addons.eventTimeSlot.guests",
    "guests",
  ];

  return axios
    .get(
      `${url}/registrations/${id}/cancel?include=${includes.join(",")}`,
      options
    )
    .then((response) => {
      let registration = jsona.deserialize(response.data);
      delete registration.links;
      return registration;
    });
}

function getReceipt(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(`${url}/registrations/${id}/reports/receipt`, options);
}


function sendReceipt(data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/registrations/${data.registration_id}/reports/receipt/send`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}


function redraft(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [
    "user",
    "role",
    "events",
    "invoices",
    "eventPackages",
    "eventPackages.addons",
    "eventPackages.addons.eventTimeSlot",
    "eventPackages.addons.eventTimeSlot.eventDate",
    "eventPackages.addons.eventTimeSlot.guests",
    "eventPackages.eventTimeSlots",
    "eventPackages.eventTimeSlots.eventDate",
    "eventPackages.eventTimeSlots.guests",
    "eventTimeSlots",
    "eventTimeSlots.eventDate",
    "eventTimeSlots.guests",
    "addons",
    "addons.eventTimeSlot",
    "addons.eventTimeSlot.eventDate",
    "addons.eventTimeSlot.guests",
    "guests",
  ];

  return axios
    .get(
      `${url}/registrations/${id}/redraft?include=${includes.join(",")}`,
      options
    )
    .then((response) => {
      let registration = jsona.deserialize(response.data);
      delete registration.links;
      return registration;
    });
}
function complete(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [
    "user",
    "role",
    "events",
    "invoices",
    "eventPackages",
    "eventPackages.addons",
    "eventPackages.addons.eventTimeSlot",
    "eventPackages.addons.eventTimeSlot.eventDate",
    "eventPackages.addons.eventTimeSlot.guests",
    "eventPackages.eventTimeSlots",
    "eventPackages.eventTimeSlots.eventDate",
    "eventPackages.eventTimeSlots.guests",
    "eventTimeSlots",
    "eventTimeSlots.eventDate",
    "eventTimeSlots.guests",
    "addons",
    "addons.eventTimeSlot",
    "addons.eventTimeSlot.eventDate",
    "addons.eventTimeSlot.guests",
    "guests",
  ];

  return axios
    .get(
      `${url}/registrations/${id}/complete?include=${includes.join(",")}`,
      options
    )
    .then((response) => {
      let registration = jsona.deserialize(response.data);
      delete registration.links;
      return registration;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  cancel,
  getReceipt,
  sendReceipt,
  redraft,
  complete,
};
