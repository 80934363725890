export const PAYMENT_STATUS_DRAFT = "DRAFT";
export const PAYMENT_STATUS_COMPLETED = "COMPLETED";
export const PAYMENT_STATUS_CANCELED = "CANCELED";

export const PAYMENT_SOURCE_MANUAL = "MANUAL";
export const PAYMENT_SOURCE_PAYPAL = "PAYPAL";

export const paymentStatusesOption = {
  [PAYMENT_STATUS_DRAFT]: "Brouillon",
  [PAYMENT_STATUS_COMPLETED]: "Completé",
  [PAYMENT_STATUS_CANCELED]: "Annulé",
};

export const paymentSourcesOption = {
  [PAYMENT_SOURCE_MANUAL]: "Manuel",
  [PAYMENT_SOURCE_PAYPAL]: "Paypal",
};
