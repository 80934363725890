<template>
  <badge :type="getBadgeType()">{{ statusesOptions[invoice.status] }}</badge>
</template>

<script>
import {
  invoiceStatusesOption,
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_SENT,
} from "../../../constants/invoices";

export default {
  name: "invoice-status-badge",

  components: {},

  mixins: [],

  props: ["invoice"],

  data() {
    return {
      statusesOptions: invoiceStatusesOption,
    };
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.invoice.status) {
        case INVOICE_STATUS_DRAFT:
          return "primary";
        case INVOICE_STATUS_SENT:
          return "default";
        case INVOICE_STATUS_PAID:
          return "success";
        case INVOICE_STATUS_CANCELED:
          return "danger";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
