<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">Forfaits</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openPackageModal()"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          Ajouter un forfait
        </base-button>
      </div>
    </div>

    <div class="row" v-if="event.packages && event.packages.length > 0">
      <div class="col-12 text-right">
        <base-button
          @click="collapseAll"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-compress-arrows-alt"></i>
          </span>
          Tout plier
        </base-button>
        <base-button
          @click="expandAll"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-expand-arrows-alt"></i>
          </span>
          Tout déplier
        </base-button>
      </div>
      <div class="col-12">
        <el-collapse :value="collapsed">
          <el-collapse-item
            v-for="pack in event.packages"
            v-bind:key="pack.id"
            :name="pack.id"
          >
            <template slot="title">
              <span class="display-6">
                <i class="ni ni-box-2" />
                $ {{ pack.price }} - {{ pack.name }}
              </span>
            </template>
            <div>
              <card class="slot-item">
                <div class="row align-items-center">
                  <div class="col-auto text-center">
                    <div class="display-5">$ {{ pack.price }}</div>
                  </div>
                  <div class="col ml-4">
                    <div class="text-md mb-3 text-center">
                      <h1>
                        {{ pack.name }}
                      </h1>
                    </div>
                    <div>
                      <p
                        class="text-md text-muted mb-3"
                        v-html="pack.excerpt"
                      ></p>
                    </div>
                    <div class="text-md mb-3">
                      <span class="display-6 font-weight-600">Roles : </span>
                      <span
                        class="display-7 font-weight-500"
                        v-for="role in pack.roles"
                        v-bind:key="role.id"
                      >
                        {{ role.name }},
                      </span>
                    </div>
                    <div class="text-md mb-3">
                      <span class="display-6 font-weight-600">
                        Forfait requis :
                      </span>
                      <span
                        class="mb-0 ml-1 text-success display-6"
                        v-if="pack.required_package"
                      >
                        <i class="ni ni-check-bold" />
                        Oui
                      </span>
                      <span
                        class="mb-0 ml-1 text-danger display-6"
                        v-if="!pack.required_package"
                      >
                        <i class="ni ni-fat-remove" />
                        Non
                      </span>
                    </div>
                    <div class="text-md mb-3">
                      <span class="display-6 font-weight-600"> Type : </span>
                      <span class="display-7 font-weight-500">
                        {{ eventPackagesStatusesOption[pack.package_type] }}
                      </span>
                    </div>
                    <div
                      class="mb-3"
                      v-for="eventDate in event.eventDates"
                      v-bind:key="eventDate.id"
                    >
                      <div class="display-6 mb-1 font-weight-600">
                        {{ eventDate.date | moment("LL") }}
                      </div>
                      <div
                        v-for="slot in eventDate.eventTimeSlots"
                        v-bind:key="slot.id"
                      >
                        <div
                          :class="[
                            { 'text-success': isSlotInPack(pack, slot) },
                            {
                              'text-danger text-muted': !isSlotInPack(
                                pack,
                                slot
                              ),
                            },
                            'display-7 mb-1 ml-4 font-weight-500',
                          ]"
                        >
                          <i
                            class="ni ni-check-bold"
                            v-if="isSlotInPack(pack, slot)"
                          />
                          <i
                            class="ni ni-fat-remove"
                            v-if="!isSlotInPack(pack, slot)"
                          />
                          <i
                            class="ni ni-money-coins ml-1 text-info"
                            v-if="isSlotAddon(pack, slot)"
                          />
                          {{ slot.start_time | moment("LT") }} -
                          {{ slot.end_time | moment("LT") }} : {{ slot.name }}

                          <span v-if="isSlotAddon(pack, slot)">
                            ( ${{ getSlotPrice(pack, slot) }} )
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div>
                      <base-button
                        type="primary"
                        size="sm"
                        @click="openPackageModal(pack)"
                        :disabled="loading"
                      >
                        <i class="fas fa-edit"></i>
                        Modifier
                      </base-button>
                      <br />
                      <base-button
                        type="info"
                        size="sm"
                        @click="downloadRegistrationsReport(pack)"
                      >
                        <i class="fas fa-file-pdf"></i>
                        Rapport inscriptions
                      </base-button>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <!-- Modal add slot -->
    <modal :show.sync="showModal" modal-classes="modal-secondary" size="lg">
      <div class="row py-3 align-items-center">
        <div class="col-12">
          <p class="text-muted text-center">
            Veuillez sélectionner les plages qui font partie du forfait.Une
            plage sélectionné avec un prix est considéré comme un supplément. Si
            la plage sélectionné n'a pas de prix, elle est incluse dans le
            forfait. Un forfait exige au minimum une plage incluse.
          </p>
        </div>
      </div>
      <form class="" @submit.prevent="saveAddPackage">
        <base-input
          label="Nom"
          placeholder="Nom"
          v-model="packageModel.name"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.name" />

        <base-input
          label="Description"
          placeholder="Description"
          input-classes="form-control-alternative"
        >
          <html-editor v-model="packageModel.excerpt" name="description" />
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />

        <base-input
          label="Prix"
          placeholder="Prix"
          v-model="packageModel.price"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.price" />

        <base-input input-classes="form-control-alternative">
          <base-checkbox v-model="packageModel.required_package">
            Forfait requis
          </base-checkbox>
        </base-input>
        <validation-error :errors="apiValidationErrors.required_package" />

        <base-input label="Type">
          <el-select name="type" v-model="packageModel.package_type">
            <el-option
              v-for="(value, key) in eventPackagesStatusesOption"
              :key="key"
              :value="key"
              :label="value"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.package_type" />

        <base-input label="Roles">
          <el-select multiple name="role" v-model="roles">
            <el-option
              v-for="role in event.allowedRoles"
              :key="role.id"
              :value="role.id"
              :label="role.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.roles" />

        <div class="mb-3 event-dates-block">
          <div class="text-center form-control-label mb-3">Plages</div>
          <div
            v-for="eventDate in event.eventDates"
            v-bind:key="eventDate.id"
            class="mb-2"
          >
            <span class="form-control-label mb-1">
              {{ eventDate.date | moment("LL") }}
            </span>
            <div
              v-for="slot in eventDate.eventTimeSlots"
              v-bind:key="slot.id"
              class="row mb-1 ml-3 height-40"
            >
              <base-checkbox
                v-if="slotsModel[slot.id]"
                class="col-8 mt-2 height-30"
                v-model="slotsModel[slot.id].checked"
              >
                <i
                  v-if="
                    !!slotsModel[slot.id].price && slotsModel[slot.id].checked
                  "
                  class="ni ni-money-coins ml-1 text-info"
                />
                {{ slot.start_time | moment("LT") }} -
                {{ slot.end_time | moment("LT") }} : {{ slot.name }}
              </base-checkbox>
              <base-input
                v-if="slotsModel[slot.id] && slotsModel[slot.id].checked"
                placeholder="Prix"
                class="col-4 height-30"
                input-classes="small-input"
                v-model="slotsModel[slot.id].price"
              >
              </base-input>
            </div>
          </div>
          <validation-error :errors="apiValidationErrors.eventTimeSlots" />
          <validation-error :errors="apiValidationErrors.addons" />
        </div>
      </form>

      <template slot="footer" v-if="!packageModel.id">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveAddPackage"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Ajouter un forfait
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closePackageModal"
          :disabled="loading"
        >
          Fermer
        </button>
      </template>

      <template slot="footer" v-if="packageModel.id">
        <base-button
          native-type="submit"
          type="primary"
          class="new-event--add"
          @click="saveEditPackage"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Modifier
        </base-button>
        <base-button
          type="danger"
          @click="deletePackage(packageModel.id)"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Supprimer
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="closePackageModal"
          :disabled="loading"
        >
          Fermer
        </base-button>
      </template>
    </modal>
  </div>
</template>
<style>
.event-dates-block input.form-control.small-input {
  padding: 0 12px !important;
  height: 30px;
}
.event-dates-block div.height-40 {
  height: 40px;
}
.event-dates-block div.height-30 {
  height: 30px;
}
.event-dates-block div.custom-checkbox .custom-control-label {
  padding: 2px 0 0 0;
}
</style>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Collapse, CollapseItem, Select, Option } from "element-ui";
import { cloneDeep, snakeCase } from "lodash";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import {
  eventPackagesStatusesOption,
  PACKAGE_TYPE_REGISTRATION,
} from "@/constants/eventPackages";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  name: "event-view-slot",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["event"],

  data() {
    return {
      loading: false,
      showModal: false,
      collapsed: [],
      roles: [],
      slotsModel: {},
      packageModel: {
        type: "event-packages",
        name: null,
        excerpt: "",
        price: null,
        relationshipNames: ["event", "eventTimeSlots", "roles"],
        event: {
          type: "events",
          id: null,
        },
        eventTimeSlots: [],
        roles: [],
        package_type: PACKAGE_TYPE_REGISTRATION,
      },
      eventPackagesStatusesOption: eventPackagesStatusesOption,
    };
  },

  methods: {
    expandAll() {
      this.collapsed = this.event.packages.map((item) => item.id);
    },

    collapseAll() {
      this.collapsed = [];
    },

    openPackageModal(pack) {
      if (pack) {
        this.packageModel.id = pack.id;
        this.packageModel.name = pack.name;
        this.packageModel.excerpt = pack.excerpt;
        this.packageModel.price = pack.price;
        (this.packageModel.package_type = pack.package_type),
          (this.packageModel.eventTimeSlots = []);
        this.packageModel.roles = [];
        this.roles = pack.roles.map((item) => item.id);
      } else {
        delete this.packageModel.id;
        this.packageModel.name = null;
        this.packageModel.excerpt = null;
        this.packageModel.price = null;
        this.packageModel.eventTimeSlots = [];
        this.packageModel.roles = [];
        (this.packageModel.package_type = PACKAGE_TYPE_REGISTRATION),
          (this.roles = []);
      }
      this.slotsModel = this.getEventSlots(pack);
      this.resetApiValidation();
      this.showModal = true;
    },

    closePackageModal() {
      this.showModal = false;
      this.loading = false;
    },

    isSlotInPack(pack, slot) {
      return (
        pack.eventTimeSlots.map((item) => item.id).includes(slot.id) ||
        pack.addons.map((item) => item.eventTimeSlot.id).includes(slot.id)
      );
    },

    isSlotAddon(pack, slot) {
      return pack.addons.map((item) => item.eventTimeSlot.id).includes(slot.id);
    },

    getSlotPrice(pack, slot) {
      for (const addon of pack.addons) {
        if (addon.eventTimeSlot.id === slot.id) {
          return addon.price;
        }
      }
      return 0;
    },

    getEventSlots(pack) {
      const slots = {};
      for (const date of this.event.eventDates) {
        for (const slot of date.eventTimeSlots) {
          if (pack) {
            const addon = pack.addons.find(
              (item) => item.eventTimeSlot.id === slot.id
            );
            const timeSlot = pack.eventTimeSlots.find(
              (item) => item.id === slot.id
            );
            if (timeSlot) {
              slots[slot.id] = {
                id: slot.id,
                slotId: slot.id,
                checked: true,
                price: null,
              };
            } else if (addon) {
              slots[slot.id] = {
                id: slot.id,
                slotId: slot.id,
                addonId: addon.id,
                checked: true,
                price: addon.price,
              };
            } else {
              slots[slot.id] = {
                id: slot.id,
                slotId: slot.id,
                checked: false,
                price: null,
              };
            }
          } else {
            slots[slot.id] = {
              id: slot.id,
              slotId: slot.id,
              checked: false,
              price: null,
            };
          }
        }
      }
      return slots;
    },

    async deletePackage(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("eventPackages/destroy", id);
        this.$emit("packagesUpdated", null);

        this.$notify({
          type: "success",
          message: "Package supprimé.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveAddPackage() {
      this.loading = true;
      const packageData = cloneDeep(this.packageModel);
      packageData.event.id = this.event.id;
      packageData.price = parseFloat(packageData.price);

      /** Split timeslots and addons (Addons have price) */
      const addons = [];
      for (const slot of Object.values(this.slotsModel)) {
        if (slot.checked) {
          if (slot.price) {
            addons.push(slot);
          } else {
            packageData.eventTimeSlots.push({
              type: "event-time-slots",
              id: slot.id,
            });
          }
        }
      }
      /** END Split*/

      this.roles.forEach((item) => {
        packageData.roles.push({
          type: "roles",
          id: item,
        });
      });

      try {
        await this.$store.dispatch("eventPackages/add", packageData);
        const pack = this.$store.getters["eventPackages/eventPackage"];

        /** update addons */
        for (const addon of addons) {
          const addonData = {
            type: "event-package-addons",
            relationshipNames: ["eventPackage", "eventTimeSlot"],
            eventPackage: {
              type: "event-packages",
              id: pack.id,
            },
            eventTimeSlot: {
              type: "event-time-slots",
              id: addon.slotId,
            },
            price: addon.price,
          };
          await this.$store.dispatch("eventPackageAddons/add", addonData);
        }

        this.$emit("packagesUpdated", null);

        this.$notify({
          type: "success",
          message: "Forfait ajouté.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveEditPackage() {
      this.loading = true;
      const packageData = cloneDeep(this.packageModel);
      packageData.event.id = this.event.id;
      packageData.price = parseFloat(packageData.price);

      /** Split timeslots and addons (Addons have price) */
      const addons = [];
      for (const slot of Object.values(this.slotsModel)) {
        if (slot.checked) {
          if (slot.price) {
            addons.push(slot);
          } else {
            packageData.eventTimeSlots.push({
              type: "event-time-slots",
              id: slot.id,
            });
          }
        }
      }
      /** END Split*/

      this.roles.forEach((item) => {
        packageData.roles.push({
          type: "roles",
          id: item,
        });
      });

      try {
        await this.$store.dispatch("eventPackages/update", packageData);

        /** update addons */
        const pack = this.event.packages.find(
          (item) => item.id === packageData.id
        );
        const oldPackAddons = pack.addons.map((item) => item.id);
        const newPackAddons = [];

        for (const addon of addons) {
          const addonData = {
            type: "event-package-addons",
            relationshipNames: ["eventPackage", "eventTimeSlot"],
            eventPackage: {
              type: "event-packages",
              id: packageData.id,
            },
            eventTimeSlot: {
              type: "event-time-slots",
              id: addon.slotId,
            },
            price: addon.price,
          };
          if (addon.addonId) {
            addonData.id = addon.addonId;
            await this.$store.dispatch("eventPackageAddons/update", addonData);
          } else {
            await this.$store.dispatch("eventPackageAddons/add", addonData);
          }
          newPackAddons.push(
            this.$store.getters["eventPackageAddons/eventPackageAddon"].id
          );
        }

        /** Remove addons not checked but in pack */
        const addonsToRemove = oldPackAddons.filter(
          (item) => !newPackAddons.includes(item)
        );
        for (const addonId of addonsToRemove) {
          await this.$store.dispatch("eventPackageAddons/destroy", addonId);
        }

        this.$emit("packagesUpdated", null);

        this.$notify({
          type: "success",
          message: "Forfait Modifié.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async downloadRegistrationsReport(pack) {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "eventPackages/getRegistrationsReport",
          pack.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.event.name)}_${snakeCase(
            pack.name
          )}-inscriptions.pdf`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
  },

  created() {},

  watch: {},
};
</script>
