<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">Plages</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openSlotModal"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          Ajouter une plage
        </base-button>
      </div>
    </div>

    <div class="row" v-if="event.eventDates && event.eventDates.length > 0">
      <div class="col-12 text-right">
        <base-button
          @click="collapseAll"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-compress-arrows-alt"></i>
          </span>
          Tout plier
        </base-button>
        <base-button
          @click="expandAll"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-expand-arrows-alt"></i>
          </span>
          Tout déplier
        </base-button>
      </div>
      <div class="col-12">
        <el-collapse :value="collapsed">
          <el-collapse-item
            v-for="(eventDate, index) in event.eventDates"
            v-bind:key="eventDate.id"
            :name="eventDate.id"
          >
            <template slot="title">
              <span class="display-6">
                <i class="ni ni-calendar-grid-58" />
                Jour {{ index + 1 }} -
              </span>
              <span class="display-5 ml-1">
                {{ eventDate.date | moment("LL") }}
              </span>
            </template>
            <div>
              <card
                class="slot-item"
                v-for="slot in eventDate.eventTimeSlots"
                v-bind:key="slot.id"
              >
                <div class="row align-items-center">
                  <div class="col-auto text-center">
                    <div class="display-5">
                      {{ slot.start_time | moment("LT") }}
                    </div>
                    <div class="text-center">-</div>
                    <div class="display-5">
                      {{ slot.end_time | moment("LT") }}
                    </div>
                  </div>
                  <div class="col ml--2">
                    <h4 class="mb-0 display-5">
                      {{ slot.name }}
                      <span v-if="slot.attendees_limit">
                        ({{ slot.attendees_count }}/{{ slot.attendees_limit }})
                      </span>
                      <span v-if="!slot.attendees_limit">
                        ({{ slot.attendees_count }})
                      </span>
                    </h4>
                    <p class="text-md text-muted mb-0" v-html="slot.excerpt"></p>
                    <div class="text-info" v-if="slot.attendees_limit">
                      ● Limite de places {{ slot.attendees_limit }}
                    </div>
                    <div class="text-info" v-if="slot.ask_for_alergies">
                      ● Demander les allergies
                    </div>
                    <div class="text-info" v-if="slot.allow_extra_guests">
                      ● Autoriser {{ slot.maximum_extra_guests }} invité(s)
                    </div>
                  </div>
                  <div class="col-auto">
                    <base-button
                      type="primary"
                      size="sm"
                      @click="openSlotModal(slot, eventDate)"
                    >
                      <i class="fas fa-edit"></i>
                      Modifier
                    </base-button>
                    <br />
                    <base-button
                      type="info"
                      size="sm"
                      @click="downloadAttendeesReport(slot)"
                    >
                      <i class="fas fa-file-pdf"></i>
                      Rapport participants
                    </base-button>
                  </div>
                </div>
              </card>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <!-- Modal add slot -->
    <modal :show.sync="showModal" modal-classes="modal-secondary">
      <form class="" @submit.prevent="saveAddSlot">
        <base-input
          label="Nom"
          placeholder="Nom"
          v-model="slotModel.name"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.name" />

        <base-input
          label="Description"
          placeholder="Description"
          input-classes="form-control-alternative"
        >
          <html-editor v-model="slotModel.excerpt" name="description" />
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />

        <base-input label="Date">
          <el-select
            name="Date"
            v-model="slotModel.eventDate.id"
            prepend-icon="fas fa-calendar"
          >
            <el-option
              v-for="date in event.eventDates"
              :key="date.id"
              :value="date.id"
              :label="dateFormatter(date)"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.eventDate" />

        <base-input
          label="Heure de début (HH:MM:SS)"
          placeholder="HH:MM:SS"
          v-maska="'##:##:##'"
          v-model="slotModel.start_time"
          input-classes="form-control-alternative"
        />
        <validation-error :errors="apiValidationErrors.start_time" />

        <base-input
          label="Heure de fin (HH:MM:SS)"
          placeholder="HH:MM:SS"
          v-maska="'##:##:##'"
          v-model="slotModel.end_time"
          input-classes="form-control-alternative"
        />
        <validation-error :errors="apiValidationErrors.end_time" />

        <base-input
          label="Limite de places"
          type="number"
          v-model="slotModel.attendees_limit"
          input-classes="form-control-alternative"
        />
        <validation-error :errors="apiValidationErrors.attendees_limit" />

        <base-checkbox v-model="slotModel.ask_for_alergies" class="mb-3">
          <span class="form-control-label">Demander les allergies</span>
        </base-checkbox>
        <validation-error :errors="apiValidationErrors.ask_for_alergies" />

        <base-checkbox v-model="slotModel.allow_extra_guests" class="mb-3">
          <span class="form-control-label">Autoriser des invités</span>
        </base-checkbox>
        <validation-error :errors="apiValidationErrors.allow_extra_guests" />

        <base-input
          v-if="slotModel.allow_extra_guests"
          label="Maximum d'invités"
          type="number"
          v-model="slotModel.maximum_extra_guests"
          input-classes="form-control-alternative"
        />
        <validation-error :errors="apiValidationErrors.maximum_extra_guests" />
      </form>

      <template slot="footer" v-if="!slotModel.id">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveAddSlot"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Ajouter une plage
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closeSlotModal"
          :disabled="loading"
        >
          Fermer
        </button>
      </template>

      <template slot="footer" v-if="slotModel.id">
        <base-button
          native-type="submit"
          type="primary"
          class="new-event--add"
          @click="saveEditSlot"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Modifier
        </base-button>
        <base-button
          type="danger"
          @click="deleteSlot(slotModel.id)"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Supprimer
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="closeSlotModal"
          :disabled="loading"
        >
          Fermer
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Collapse, CollapseItem, Select, Option } from "element-ui";
import moment from "moment";
import { cloneDeep, snakeCase } from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

export default {
  name: "event-view-slot",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["event"],

  data() {
    return {
      loading: false,
      showModal: false,
      collapsed: [],
      slotModel: {
        type: "event-time-slots",
        name: null,
        excerpt: "",
        start_time: null,
        end_time: null,
        attendees_limit: null,
        attendees_count: null,
        ask_for_alergies: null,
        allow_extra_guests: false,
        maximum_extra_guests: null,
        relationshipNames: ["eventDate"],
        eventDate: {
          type: "event-dates",
          id: null,
        },
      },
    };
  },

  methods: {
    expandAll() {
      this.collapsed = this.event.eventDates.map((item) => item.id);
    },

    collapseAll() {
      this.collapsed = [];
    },

    openSlotModal(slot, eventDate) {
      if (slot && eventDate) {
        this.slotModel.id = slot.id;
        this.slotModel.name = slot.name;
        this.slotModel.excerpt = slot.excerpt;
        this.slotModel.eventDate.id = eventDate.id;
        this.slotModel.start_time = moment(slot.start_time).format("HH:mm:ss");
        this.slotModel.end_time = moment(slot.end_time).format("HH:mm:ss");
        this.slotModel.attendees_limit = slot.attendees_limit;
        this.slotModel.ask_for_alergies = !!slot.ask_for_alergies;
        this.slotModel.allow_extra_guests = !!slot.allow_extra_guests;
        this.slotModel.maximum_extra_guests = slot.maximum_extra_guests;
      } else {
        delete this.slotModel.id;
        this.slotModel.name = null;
        this.slotModel.excerpt = null;
        this.slotModel.eventDate.id = null;
        this.slotModel.start_time = null;
        this.slotModel.end_time = null;
        this.slotModel.attendees_limit = null;
        this.slotModel.ask_for_alergies = null;
        this.slotModel.allow_extra_guests = false;
        this.slotModel.maximum_extra_guests = null;
      }
      this.resetApiValidation();
      this.showModal = true;
    },

    closeSlotModal() {
      this.showModal = false;
      this.loading = false;
    },

    async deleteSlot(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("eventTimeSlots/destroy", id);
        this.$emit("slotsUpdated", null);

        this.$notify({
          type: "success",
          message: "Plage ajoutée.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveAddSlot() {
      this.loading = true;

      const timeslotData = cloneDeep(this.slotModel);
      const eventDate = this.event.eventDates.find(
        (eventDate) => eventDate.id == this.slotModel.eventDate.id
      );

      if (eventDate) {
        timeslotData.start_time = moment(eventDate.date)
          .add(moment.duration(timeslotData.start_time))
          .toISOString();
        timeslotData.end_time = moment(eventDate.date)
          .add(moment.duration(timeslotData.end_time))
          .toISOString();
      } else {
        delete timeslotData.eventDate;
      }
      timeslotData.ask_for_alergies = !!timeslotData.ask_for_alergies;

      try {
        await this.$store.dispatch("eventTimeSlots/add", timeslotData);
        this.$emit("slotsUpdated", null);

        this.$notify({
          type: "success",
          message: "Plage modifiée.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveEditSlot() {
      this.loading = true;

      const timeslotData = cloneDeep(this.slotModel);
      const eventDate = this.event.eventDates.find(
        (eventDate) => eventDate.id == this.slotModel.eventDate.id
      );

      if (eventDate) {
        timeslotData.start_time = moment(eventDate.date)
          .add(moment.duration(timeslotData.start_time))
          .toISOString();
        timeslotData.end_time = moment(eventDate.date)
          .add(moment.duration(timeslotData.end_time))
          .toISOString();
      } else {
        delete timeslotData.eventDate;
      }
      timeslotData.ask_for_alergies = !!timeslotData.ask_for_alergies;

      try {
        await this.$store.dispatch("eventTimeSlots/update", timeslotData);
        this.$emit("slotsUpdated", null);

        this.$notify({
          type: "success",
          message: "Plage ajoutée.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    dateFormatter(eventDate) {
      return moment(eventDate.date).format("LL");
    },

    async downloadAttendeesReport(slot) {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "eventTimeSlots/getAttendeesReport",
          slot.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.event.name)}_${snakeCase(
            slot.name
          )}-participants.pdf`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
  },

  created() {},
};
</script>
