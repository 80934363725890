<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-10">
              <h3 class="mb-0">
                {{ event.name }}
                <base-button type="primary" icon size="sm" @click="editEvent()">
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>
                <base-button
                  type="info"
                  icon
                  size="sm"
                  @click="downloadAccountingReport()"
                >
                  <i class="fas fa-file-pdf"></i>
                  Rapport comptabilité
                </base-button>
                <base-button
                  type="info"
                  icon
                  size="sm"
                  @click="downloadSignatureSheetReport()"
                >
                  <i class="fas fa-file-pdf"></i>
                  Feuille de signatures
                </base-button>
              </h3>
            </div>
            <div class="col-2 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
                >Retour a la liste</base-button
              >
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="global"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="true">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    Global
                  </span>
                  <event-view-global :event="event" />
                </tab-pane>

                <tab-pane title="slots" id="2">
                  <span slot="title">
                    <i class="ni ni-calendar-grid-58" />
                    Plages
                  </span>
                  <event-view-slot :event="event" @slotsUpdated="get" />
                </tab-pane>

                <tab-pane title="packages" id="3">
                  <span slot="title">
                    <i class="ni ni-box-2" />
                    Forfaits
                  </span>
                  <event-view-package :event="event" @packagesUpdated="get" />
                </tab-pane>

                <tab-pane title="kiosks" id="4">
                  <span slot="title">
                    <i class="ni ni-shop" />
                    Kiosques
                  </span>
                  <event-view-kiosk :event="event" @kiosksUpdated="get" />
                </tab-pane>

                <tab-pane title="subsidies" id="5">
                  <span slot="title">
                    <i class="ni ni-money-coins" />
                    Subventions
                  </span>
                  <event-view-subsidy :event="event" @subsidiesUpdated="get" />
                </tab-pane>

                <tab-pane title="customUsers" id="6">
                  <span slot="title">
                    <i class="ni ni-badge" />
                    Utilisateurs spéciaux
                  </span>
                  <event-view-custom-user
                    :event="event"
                    @customUsersUpdated="get"
                  />
                </tab-pane>

                <tab-pane title="slots" id="7">
                  <span slot="title">
                    <i class="ni ni-single-02" />
                    Inscriptions
                  </span>
                  <event-view-registration
                    :event="event"
                    @registrationsUpdated="get"
                  />
                </tab-pane>

                <tab-pane title="subsidies" id="8">
                  <span slot="title">
                    <i class="ni ni-money-coins" />
                    Codes promo
                  </span>
                  <event-view-promo-code
                    :event="event"
                    @promoCodesUpdated="get"
                  />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane } from "@/components";
import EventViewGlobal from "./partials/EventViewGlobal.vue";
import EventViewSlot from "./partials/EventViewSlot.vue";
import EventViewPackage from "./partials/EventViewPackage.vue";
import EventViewKiosk from "./partials/EventViewKiosk.vue";
import EventViewSubsidy from "./partials/EventViewSubsidy.vue";
import EventViewCustomUser from "./partials/EventViewCustomUser.vue";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import EventViewRegistration from "./partials/EventViewRegistration.vue";
import { snakeCase } from "lodash";
import swal from "sweetalert2";
import EventViewPromoCode from './partials/EventViewPromoCode.vue';

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    EventViewGlobal,
    EventViewSlot,
    EventViewPackage,
    EventViewKiosk,
    EventViewSubsidy,
    EventViewRegistration,
    EventViewCustomUser,
    EventViewPromoCode,
  },

  data() {
    return {
      event: {
        type: "events",
        excerpt: null,
        early_registration_date: null,
        registration_limit_date: null,
        eventDates: null,
        start_date: null,
        end_date: null,
        allowedRoles: [],
      },
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("events/get", id);
        this.event = this.$store.getters["events/event"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      }
    },

    async editEvent(row) {
      this.$router.push({
        name: "Edit Event",
        params: { id: this.event.id },
      });
    },

    goBack() {
      this.$router.push({ name: "List Events" });
    },

    async downloadAccountingReport() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "events/getAccountingReport",
          this.event.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.event.name)}-rapport-comptabilité.pdf`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    async downloadSignatureSheetReport() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "events/getSignatureSheetReport",
          this.event.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.event.name)}-feuille-de-signature.pdf`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
  },
};
</script>
