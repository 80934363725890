<template>
  <div class="ml-4 mt-2 row" v-if="timeSlot.allow_extra_guests">
    <div class="col-12 text-center heading">
      Accompagnants ({{ timeSlot.maximum_extra_guests }})
    </div>
    <div class="col-12 mt-2 row">
      <base-checkbox
        class="col-6"
        v-for="guest in guests"
        v-bind:key="guest.id"
        v-model="guestsModel[guest.id]"
        :disabled="isGuestDisabled(guest.id)"
      >
        <div class="display-7 font-weight-600">
          {{ guest.name }} -
          {{ guestsTypes[guest.guest_type] }}
          {{ guest.age ? "(" + guest.age + " ans)" : null }}
        </div>
      </base-checkbox>
    </div>
  </div>
</template>
<script>
import { registrationGuestsTypes } from "../../../constants/registrationGuests";

export default {
  name: "registration-view-slots-guests",

  components: {},

  props: ["timeSlot", "guests", "disabled"],

  data() {
    return {
      guestsModel: {},
      guestsTypes: registrationGuestsTypes,
    };
  },

  computed: {},

  methods: {
    changeGuest() {
      this.$emit("guestsUpdated", this.getCheckedGuests());
    },

    refreshGuestsModel() {
      const slot_guests = this.timeSlot.guests.map((item) => item.id);

      const guestsData = {};
      for (const guest of this.guests) {
        guestsData[guest.id] = slot_guests.includes(guest.id);
      }
      this.guestsModel = { ...guestsData };
    },

    isGuestDisabled(guest_id) {
      if (this.disabled) {
        return true;
      }

      const checkedGuests = this.getCheckedGuests();

      if (this.timeSlot.attendees_limit) {
        if (
          checkedGuests.length + parseInt(this.timeSlot.attendees_count) + 1 >=
          parseInt(this.timeSlot.attendees_limit)
        ) {
          if (!this.guestsModel[guest_id]) {
            return true;
          }
        }
      }

      if (
        checkedGuests.length >= parseInt(this.timeSlot.maximum_extra_guests)
      ) {
        if (!this.guestsModel[guest_id]) {
          return true;
        }
      }

      return false;
    },

    getCheckedGuests() {
      const checkedGuests = [];
      for (const guest_id in this.guestsModel) {
        if (this.guestsModel[guest_id]) {
          checkedGuests.push(guest_id);
        }
      }
      return checkedGuests;
    },
  },

  created() {
    this.refreshGuestsModel();
  },

  mounted() {},

  watch: {
    guests: {
      handler: "refreshGuestsModel",
      deep: true,
    },
    timeSlot: {
      handler: "refreshGuestsModel",
      deep: true,
    },
    guestsModel: {
      handler: "changeGuest",
      deep: true,
    },
  },
};
</script>
