<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-registrations-center">
            <div class="col-8">
              <h3 class="mb-0">Modifier une inscription</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                Retour a la liste
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col">
                  <base-input label="Utilisateur">
                    <el-select
                      name="Utilisateur"
                      v-model="registration.user.id"
                      filterable
                    >
                      <el-option
                        v-for="user in all_users"
                        :key="user.id"
                        :value="user.id"
                        :label="user.name"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.user" />
                </div>
              </div>

              <div class="row" v-if="registration.user">
                <div class="col">
                  <base-input
                    label="Allergies"
                    prepend-icon="fas fa-pen"
                    placeholder="Allergies"
                    v-model="registration.alergies"
                  />
                  <validation-error :errors="apiValidationErrors.alergies" />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <base-input label="Evenement">
                    <el-select name="Evenement" v-model="event" filterable>
                      <el-option
                        v-for="event in all_events"
                        :key="event.id"
                        :value="event.id"
                        :label="event.name"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.event" />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <base-input label="Forfait">
                    <el-select
                      name="Event"
                      v-model="eventPackages"
                      :disabled="!event"
                      filterable
                      multiple
                    >
                      <el-option
                        v-for="pack in eventData ? eventData.packages : []"
                        :key="pack.id"
                        :value="pack.id"
                        :label="pack.name"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error
                    :errors="apiValidationErrors.eventPackages"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <base-input label="Role">
                    <el-select
                      name="Role"
                      v-model="registration.role.id"
                      filterable
                    >
                      <el-option
                        v-for="role in eventData ? eventData.allowedRoles : []"
                        :key="role.id"
                        :value="role.id"
                        :label="role.name"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.role" />
                </div>
              </div>

              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Modifier une inscription
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import { cloneDeep } from "lodash";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    BaseButton,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  data() {
    return {
      all_users: [],
      all_roles: [],
      all_events: [],
      all_event_packages: [],
      event: null,
      eventData: null,
      eventPackages: [],
      registration: {
        type: "registrations",
        relationshipNames: ["user", "eventPackages", "role"],
        user: {
          type: "users",
          id: null,
        },
        events: [],
        eventPackages: [],
        role: {
          type: "roles",
          id: null,
        },
      },
    };
  },

  watch: {
    event: {
      handler: "changeEvent",
      immediate: true,
    },
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List Registrations" });
    },

    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("registrations/get", id);
        this.registration = this.$store.getters["registrations/registration"];

        this.event =
          this.registration.events.length > 0
            ? this.registration.events[0].id
            : null;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async handleSubmit() {
      const registrationData = cloneDeep(this.registration);

      registrationData.eventPackages = [];

      for (const pack of this.eventPackages) {
        registrationData.eventPackages.push({
          type: "event-packages",
          id: pack,
        });
      }

      try {
        await this.$store.dispatch("registrations/update", registrationData);
        this.registration = await this.$store.getters[
          "registrations/registration"
        ];
        this.$notify({
          type: "success",
          message: "Inscription modifiée avec succes.",
        });
        this.viewRegistration();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.registration.id) {
          await this.$store.dispatch(
            "registrations/destroy",
            this.registration.id
          );
        }
      }
    },

    changeEvent() {
      this.eventPackages = [];
      if (this.event) {
        this.getEvent(this.event);
      }
    },

    async viewRegistration() {
      this.$router.push({
        name: "View Registration",
        params: { id: this.registration.id },
      });
    },

    async getEvent(id) {
      try {
        await this.$store.dispatch("events/get", id);
        this.eventData = await this.$store.getters["events/event"];
        this.registration.eventPackages.forEach((pack) => {
          this.eventPackages.push(pack.id);
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async getUsers() {
      try {
        await this.$store.dispatch("users/list", {
          sort: "name",
          filter: { active: 1 },
        });
        this.all_users = await this.$store.getters["users/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async getEvents() {
      try {
        await this.$store.dispatch("events/list", { sort: "name" });
        this.all_events = await this.$store.getters["events/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },

  created() {
    this.get();
    this.getUsers();
    this.getEvents();
  },
};
</script>
