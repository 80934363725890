<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>Plages</h1>
    </div>

    <div class="row">
      <div class="col-12">
        <form class="" @submit.prevent="">
          <div class="mb-5 event-dates-block">
            <div
              v-for="eventDate in eventDates"
              v-bind:key="eventDate.id"
              class="mb-5"
            >
              <span class="mb-4 heading-title font-weight-bold">
                {{ eventDate.date | moment("LL") }}
              </span>
              <div class="container-fluid">
                <div
                  v-for="slot in eventDate.eventTimeSlots"
                  v-bind:key="slot.id"
                  class="row mb-3 mt-2 ml-2"
                >
                  <!-- ADDONS -->
                  <div class="col-12" v-if="slot.addon">
                    <div>
                      <base-checkbox
                        v-model="addons[slot.addon.id]"
                        :disabled="isSlotDisabled(slot)"
                      >
                        <div class="display-7 font-weight-600">
                          <i
                            v-if="slot.addon"
                            class="ni ni-money-coins ml-1 text-info"
                          />
                          {{ slot.start_time | moment("LT") }} -
                          {{ slot.end_time | moment("LT") }} :
                          {{ slot.name }}
                          <span> ( ${{ slot.addon.price }} )</span>
                          <span v-if="slot.attendees_limit">
                            ({{ slot.attendees_count }}/{{
                              slot.attendees_limit
                            }})
                          </span>
                        </div>
                      </base-checkbox>
                    </div>
                    <registration-view-slots-guests
                      v-if="addons[slot.addon.id]"
                      :timeSlot="slot"
                      :guests="registration.guests"
                      :disabled="
                        ![
                          REGISTRATION_STATUS_DRAFT,
                          REGISTRATION_STATUS_REDRAFT,
                        ].includes(registration.status)
                      "
                      @guestsUpdated="(guests) => updateGuests(slot, guests)"
                    />
                  </div>

                  <!-- SLOTS -->
                  <div class="col-12" v-if="!slot.addon">
                    <div>
                      <base-checkbox
                        v-model="slots[slot.id]"
                        :disabled="isSlotDisabled(slot)"
                      >
                        <div class="display-7 font-weight-600">
                          {{ slot.start_time | moment("LT") }} -
                          {{ slot.end_time | moment("LT") }} : {{ slot.name }}
                          <span v-if="slot.attendees_limit">
                            ({{ slot.attendees_count }}/{{
                              slot.attendees_limit
                            }})
                          </span>
                        </div>
                      </base-checkbox>
                    </div>
                    <registration-view-slots-guests
                      v-if="slots[slot.id]"
                      :timeSlot="slot"
                      :guests="registration.guests"
                      :disabled="
                        ![
                          REGISTRATION_STATUS_DRAFT,
                          REGISTRATION_STATUS_REDRAFT,
                        ].includes(registration.status)
                      "
                      @guestsUpdated="(guests) => updateGuests(slot, guests)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <validation-error :errors="apiValidationErrors.addons" />
            <validation-error :errors="apiValidationErrors.eventTimeSlots" />
          </div>

          <div
            class="mt-5 mb-3 text-center"
            v-if="
              [REGISTRATION_STATUS_DRAFT, REGISTRATION_STATUS_REDRAFT].includes(
                registration.status
              )
            "
          >
            <base-button
              native-type="submit"
              type="primary"
              class="new-event--add"
              @click="saveSlots"
              :disabled="loading"
            >
              <span v-if="loading" class="btn-inner--icon">
                <i class="fas fa-spinner fa-spin"></i>
              </span>
              Enregistrer
            </base-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style scoped>
span.mb-4.heading-title.font-weight-bold {
  font-size: 1.2rem;
}
</style>
<script>
import { cloneDeep } from "lodash";
import {
  REGISTRATION_STATUS_DRAFT,
  REGISTRATION_STATUS_REDRAFT,
} from "../../../constants/registrations";
import RegistrationViewSlotsGuests from "./RegistrationViewSlotsGuests.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "registration-view-slots",

  components: {
    ValidationError,
    RegistrationViewSlotsGuests,
  },

  mixins: [formMixin],

  props: ["registration"],

  data() {
    return {
      loading: false,
      slots: {},
      guests: {},
      addons: {},
      eventDates: {},
      REGISTRATION_STATUS_DRAFT: REGISTRATION_STATUS_DRAFT,
      REGISTRATION_STATUS_REDRAFT: REGISTRATION_STATUS_REDRAFT,
    };
  },

  computed: {},

  methods: {
    async saveSlots() {
      this.resetApiValidation();

      if (
        ![REGISTRATION_STATUS_DRAFT, REGISTRATION_STATUS_REDRAFT].includes(
          this.registration.status
        )
      ) {
        return;
      }
      this.loading = true;
      try {
        const registrationData = cloneDeep(this.registration);
        registrationData.eventTimeSlots = [];
        registrationData.addons = [];

        for (const id in this.slots) {
          if (this.slots[id]) {
            registrationData.eventTimeSlots.push({
              type: "event-time-slots",
              id: id,
            });
          }
        }

        for (const id in this.addons) {
          if (this.addons[id]) {
            registrationData.addons.push({
              type: "event-package-addons",
              id: id,
            });
          }
        }

        await this.$store.dispatch("registrations/update", registrationData);

        for (const guest of this.registration.guests) {
          const guest_time_slots = [];
          for (const slot_id in this.guests) {
            if (this.guests[slot_id]) {
              if (this.guests[slot_id].includes(guest.id.toString())) {
                guest_time_slots.push({
                  type: "event-time-slots",
                  id: slot_id,
                });
              }
            }
          }

          const guestData = {
            type: "registration-guests",
            id: guest.id,
            name: guest.name,
            age: guest.age,
            guest_type: guest.guest_type,
            alergies: guest.alergies,
            relationshipNames: ["registration", "eventTimeSlots"],
            registration: {
              type: "registrations",
              id: this.registration.id,
            },
            eventTimeSlots: guest_time_slots,
          };

          await this.$store.dispatch("registrationGuests/update", guestData);
        }

        this.$emit("slotsUpdated", null);
        this.$notify({
          type: "success",
          message: "Inscription enregistrée",
        });
        this.loading = false;
      } catch (error) {
        let errorMessage = "Oops, something went wrong!";
        try {
          errorMessage = error.response.data.errors[0].detail;
        } catch (e) {
          console.log(e);
        }
        this.$notify({
          type: "danger",
          message: errorMessage,
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    updateGuests(slot, guests) {
      this.guests[slot.id] = guests;
    },

    isSlotDisabled(slot) {
      if (
        ![REGISTRATION_STATUS_DRAFT, REGISTRATION_STATUS_REDRAFT].includes(
          this.registration.status
        )
      ) {
        return true;
      }

      if (slot.attendees_limit) {
        if (slot.attendees_limit - slot.attendees_count < 1) {
          return true;
        }
      }

      return false;
    },

    refreshEventDates() {
      /** format and get event dates */
      const eventDates = {};
      for (const pack of this.registration.eventPackages) {
        for (const slot of pack.eventTimeSlots) {
          const eventDate = slot.eventDate;
          if (!eventDates[eventDate.id]) {
            eventDates[eventDate.id] = eventDate;
            eventDates[eventDate.id].eventTimeSlots = {};
          }
          eventDates[eventDate.id].eventTimeSlots[slot.id] = slot;
        }

        for (const addon of pack.addons) {
          const slot = addon.eventTimeSlot;
          const eventDate = slot.eventDate;
          slot.addon = addon;

          if (!eventDates[eventDate.id]) {
            eventDates[eventDate.id] = eventDate;
            eventDates[eventDate.id].eventTimeSlots = {};
          }
          eventDates[eventDate.id].eventTimeSlots[slot.id] = slot;
        }
      }
      this.eventDates = eventDates;

      /** set addons and slots models */
      for (const addon of this.registration.addons) {
        this.addons[addon.id] = true;
      }
      for (const slot of this.registration.eventTimeSlots) {
        this.slots[slot.id] = true;
      }
    },
  },

  mounted() {},

  watch: {
    registration: {
      handler: "refreshEventDates",
      deep: true,
    },
  },
};
</script>
