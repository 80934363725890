<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">Modifier un evenement</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="viewEvent"
                type="button"
                class="btn btn-sm btn-primary"
                >Retour a l'evenement</base-button
              >
            </div>
          </div>
          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <base-input
                label="Nom"
                prepend-icon="fas fa-pen"
                placeholder="Nom"
                v-model="event.name"
              />
              <validation-error :errors="apiValidationErrors.name" />

              <base-input
                label="Description"
                prepend-icon="fas fa-pen"
                placeholder="Description"
              >
                <textarea
                  class="form-control"
                  rows="3"
                  v-model="event.excerpt"
                ></textarea>
              </base-input>
              <validation-error :errors="apiValidationErrors.excerpt" />

              <base-input
                label="Date debut preinscription"
                prepend-icon="fas fa-calendar"
              >
                <flat-picker
                  :config="{
                    allowInput: true,
                    minDate: 'today',
                    locale: flatPickrLocale,
                  }"
                  class="form-control datepicker"
                  v-model="event.early_registration_date"
                >
                </flat-picker>
              </base-input>
              <validation-error
                :errors="apiValidationErrors.early_registration_date"
              />

              <base-input
                label="Date de fin d'inscription"
                prepend-icon="fas fa-calendar"
              >
                <flat-picker
                  :config="{
                    allowInput: true,
                    minDate: 'today',
                    locale: flatPickrLocale,
                  }"
                  class="form-control datepicker"
                  v-model="event.registration_limit_date"
                >
                </flat-picker>
              </base-input>
              <validation-error
                :errors="apiValidationErrors.registration_limit_date"
              />

              <base-input
                label="Dates Evenement"
                prepend-icon="fas fa-calendar"
              >
                <flat-picker
                  :config="{
                    allowInput: true,
                    minDate: 'today',
                    mode: 'multiple',
                    locale: flatPickrLocale,
                  }"
                  class="form-control datepicker"
                  v-model="event_dates"
                >
                </flat-picker>
              </base-input>
              <validation-error :errors="apiValidationErrors.eventDates" />

              <base-input label="Role">
                <el-select
                  multiple
                  name="role"
                  v-model="roles"
                  prepend-icon="fas fa-user"
                >
                  <el-option
                    v-for="role in all_roles"
                    :key="role.id"
                    :value="role.id"
                    :label="role.name"
                  >
                  </el-option>
                </el-select>
              </base-input>
              <validation-error :errors="apiValidationErrors.roles" />

              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  Modifier un evenement
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import flatPicker from "vue-flatpickr-component";
import moment from "moment";
import { French } from "flatpickr/dist/l10n/fr.js";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    BaseButton,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPicker,
  },
  mixins: [formMixin],

  data() {
    return {
      all_roles: [],
      roles: [],
      event_dates: null,
      event: {
        type: "events",
        excerpt: null,
        early_registration_date: null,
        registration_limit_date: null,
        relationshipNames: ["allowedRoles"],
        allowedRoles: [],
      },
      flatPickrLocale: French,
    };
  },

  created() {
    this.get();
    this.getRoles();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("events/get", id);
        this.event = this.$store.getters["events/event"];
        this.event.allowedRoles.forEach((tag) => {
          this.roles.push(tag.id);
        });
        this.event_dates = this.event.eventDates
          .map((val) => moment(val.date).format("YYYY-MM-DD"))
          .join(", ");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async getRoles() {
      try {
        await this.$store.dispatch("roles/list", { sort: "name" });
        this.all_roles = await this.$store.getters["roles/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async viewEvent() {
      this.$router.push({
        name: "View Event",
        params: { id: this.event.id },
      });
    },

    async handleSubmit() {
      try {
        /** Delete event dates not selected, create selected eventDates */
        const newEventDates = this.event_dates
          ? this.event_dates.split(", ")
          : [];
        const oldEventDates = this.event.eventDates.map((val) =>
          moment(val.date).format("YYYY-MM-DD")
        );

        this.event.early_registration_date = moment(
          this.event.early_registration_date
        ).toISOString();
        this.event.registration_limit_date = moment(
          this.event.registration_limit_date
        ).toISOString();

        this.event.allowedRoles = [];

        this.roles.forEach((roleId) => {
          this.event.allowedRoles.push({
            id: roleId,
            type: "roles",
          });
        });

        await this.$store.dispatch("events/update", this.event);

        for (const date of newEventDates) {
          if (!oldEventDates.includes(date)) {
            await this.$store.dispatch("eventDates/add", {
              type: "event-dates",
              date: moment(date).toISOString(),
              relationshipNames: ["event"],
              event: {
                type: "events",
                id: this.event.id,
              },
            });
          }
        }
        for (const date of oldEventDates) {
          if (!newEventDates.includes(date)) {
            const eventDate = this.event.eventDates.find(
              (val) => moment(val.date).format("YYYY-MM-DD") === date
            );
            await this.$store.dispatch("eventDates/destroy", eventDate.id);
          }
        }

        this.$notify({
          type: "success",
          message: "Evenement mis a jour",
        });
        this.viewEvent();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
