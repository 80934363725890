<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ registration.code }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">Date</dt>
          <dd class="col-sm-8">
            {{ registration.date | moment("LLLL") }}
          </dd>

          <dt class="col-sm-4">Status</dt>
          <dd class="col-sm-8">
            <registration-status-badge :registration="registration" />
          </dd>

          <dt class="col-sm-4">Montant inscription</dt>
          <dd class="col-sm-8">
            <span> ${{ registration.price.toFixed(2) }} </span>
          </dd>

          <dt class="col-sm-4">Montant facture</dt>
          <dd class="col-sm-8">
            <span v-if="registration.activeInvoices.length > 0">
              ${{ registration.activeInvoices[0].pricing.total.toFixed(2) }}
            </span>
          </dd>

          <dt class="col-sm-4">Utilisateur</dt>
          <dd class="col-sm-8">
            <a href="#" @click.prevent="goToUser(registration.user)">
              {{ registration.user.name }}
            </a>
          </dd>

          <dt class="col-sm-4" v-if="registration.alergies">Allergies</dt>
          <dd class="col-sm-8" v-if="registration.alergies">
            {{ registration.alergies }}
          </dd>

          <dt class="col-sm-4">Role</dt>
          <dd class="col-sm-8">
            <a href="#" @click.prevent="goToRole(registration.role)">
              {{ registration.role.name }}
            </a>
          </dd>

          <dt class="col-sm-4">Evenement</dt>
          <dd class="col-sm-8">
            <a
              v-for="event in registration.events"
              :key="event.id"
              href="#"
              @click.prevent="goToEvent(event)"
            >
              {{ event.name }}
            </a>
          </dd>

          <dt class="col-sm-4">Forfaits</dt>
          <dd class="col-sm-8">
            <a
              v-for="pack in registration.eventPackages"
              :key="pack.id"
              href="#"
              @click.prevent="goToEvent(pack.event)"
            >
              {{ pack.name }} <br />
            </a>
          </dd>

          <dt class="col-sm-4" v-if="registration.invoices.length > 0">
            Factures
          </dt>
          <dd class="col-sm-8" v-if="registration.invoices.length > 0">
            <div v-for="invoice in registration.invoices" :key="invoice.id">
              <a href="#" @click.prevent="goToInvoice(invoice)">
                {{ invoice.code }}
              </a>
              <invoice-status-badge :invoice="invoice" />
            </div>
          </dd>

          <dt class="col-sm-4">Date envoi reçu</dt>
          <dd class="col-sm-8">
            {{ registration.receipt_sent_last_date | moment("LLLL") }}
          </dd>

          <dt class="col-sm-4">Historique envoi reçu</dt>
          <dd class="col-sm-8">
            <span
              v-for="(item, key) of registration.receipt_sent_history"
              :key="key"
            >
              {{ item.receipt_sent_last_date | moment("LLLL") }} <br />
            </span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { allCountries } from "country-region-data";
import { registrationStatusesOption } from "../../../constants/registrations";
import RegistrationStatusBadge from "./RegistrationStatusBadge.vue";
import InvoiceStatusBadge from "../../InvoiceManagement/partials/InvoiceStatusBadge.vue";

export default {
  name: "registration-view-global",

  components: { RegistrationStatusBadge, InvoiceStatusBadge },

  props: ["registration"],

  data() {
    return {
      statusesOptions: registrationStatusesOption,
    };
  },

  computed: {},

  methods: {
    goToUser(user) {
      this.$router.push({
        name: "Edit User",
        params: { id: user.id },
      });
    },

    goToRole(role) {
      this.$router.push({
        name: "Edit Role",
        params: { id: role.id },
      });
    },

    goToEvent(event) {
      this.$router.push({
        name: "View Event",
        params: { id: event.id },
      });
    },

    goToInvoice(invoice) {
      this.$router.push({
        name: "View Invoice",
        params: { id: invoice.id },
      });
    },

    getCountry(countryCode) {
      const country = allCountries.find((el) => el[1] == countryCode);
      return country ? country[0] : null;
    },

    async cancelRegistration() {
      this.resetApiValidation();
      if (!this.registrationModel.id) {
        return;
      }
      this.loading = true;
      try {
        await this.$store.dispatch(
          "registrations/cancel",
          this.registrationModel.id
        );
        const registration = this.$store.getters["registrations/registration"];
        this.$emit("registrationsUpdated", null);
        this.$notify({
          type: "success",
          message: "Inscription annulée.",
        });
        this.loading = false;
        this.openRegistrationModal(registration);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
