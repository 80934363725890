<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">Kiosques</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openKioskModal()"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          Ajouter un kiosque
        </base-button>
      </div>
    </div>

    <div class="row" v-if="event.kiosks && event.kiosks.length > 0">
      <div class="col-12 text-right">
        <base-button
          @click="collapseAll"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-compress-arrows-alt"></i>
          </span>
          Tout plier
        </base-button>
        <base-button
          @click="expandAll"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-expand-arrows-alt"></i>
          </span>
          Tout déplier
        </base-button>
      </div>
      <div class="col-12">
        <el-collapse :value="collapsed">
          <el-collapse-item
            v-for="kiosk in event.kiosks"
            v-bind:key="kiosk.id"
            :name="kiosk.id"
          >
            <template slot="title">
              <span class="display-6">
                <i class="ni ni-shop" />
                $ {{ kiosk.price }} - {{ kiosk.name }}
                <span v-if="kiosk.is_invoice_paied" class="text-success">
                  (<i class="ni ni-check-bold" />)
                </span>
              </span>
            </template>
            <div>
              <card class="slot-item">
                <div class="row align-items-center">
                  <div class="col-auto text-center">
                    <div class="display-5">$ {{ kiosk.price }}</div>
                  </div>
                  <div class="col ml-4">
                    <div class="text-md mb-3 text-center">
                      <h1>
                        {{ kiosk.name }}
                        <span
                          v-if="kiosk.is_invoice_paied"
                          class="text-success"
                        >
                          (<i class="ni ni-check-bold" /> Payé)
                        </span>
                      </h1>
                    </div>
                    <div>
                      <p class="text-md text-muted mb-3">
                        {{ kiosk.excerpt }}
                      </p>
                    </div>
                    <div>
                      <div class="text-info" v-if="kiosk.booked">● Reservé</div>
                      <div class="text-info" v-if="kiosk.user">
                        ● Reservé par
                        <a href="#" @click.prevent="goToUser(kiosk.user)">{{
                          kiosk.user.name
                        }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div>
                      <base-button
                        type="primary"
                        size="sm"
                        @click="openKioskModal(kiosk)"
                        v-if="!kiosk.is_invoice_paied"
                      >
                        <i class="fas fa-edit"></i>
                        Modifier
                      </base-button>
                    </div>

                    <div>
                      <base-button
                        type="primary"
                        size="sm"
                        @click="openKioskModal(kiosk)"
                        v-if="kiosk.is_invoice_paied"
                      >
                        <i class="fas fa-eye"></i>
                        Voir
                      </base-button>
                    </div>

                    <div v-if="!kiosk.active_invoice">
                      <base-button
                        type="primary"
                        size="sm"
                        @click="createKioskInvoice(kiosk)"
                        :disabled="loading"
                      >
                        <i class="fas fa-money-bill-alt"></i>
                        Facturer
                      </base-button>
                    </div>

                    <div v-if="kiosk.active_invoice">
                      <base-button
                        type="primary"
                        size="sm"
                        @click="viewKioskInvoice(kiosk)"
                        :disabled="loading"
                      >
                        <i class="fas fa-money-bill-alt"></i>
                        Voir la facture
                      </base-button>
                    </div>
                  </div>
                </div>
              </card>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <!-- Modal add slot -->
    <modal :show.sync="showModal" modal-classes="modal-secondary">
      <form class="" @submit.prevent="saveAddKiosk">
        <base-input
          label="Nom"
          placeholder="Nom"
          v-model="kioskModel.name"
          input-classes="form-control-alternative"
          :disabled="kioskModel.is_invoice_paied"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.name" />

        <base-input
          label="Description"
          placeholder="Description"
          input-classes="form-control-alternative"
          :disabled="kioskModel.is_invoice_paied"
        >
          <textarea
            class="form-control"
            rows="3"
            v-model="kioskModel.excerpt"
            :disabled="kioskModel.is_invoice_paied"
          ></textarea>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />

        <base-input
          label="Prix"
          placeholder="Prix"
          v-model="kioskModel.price"
          input-classes="form-control-alternative"
          :disabled="kioskModel.is_invoice_paied"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.price" />

        <base-checkbox
          v-model="kioskModel.booked"
          :disabled="kioskModel.is_invoice_paied"
          class="mb-3"
        >
          <span class="form-control-label">Réservé</span>
        </base-checkbox>
        <validation-error :errors="apiValidationErrors.booked" />

        <base-input label="Reservé par" v-if="kioskModel.booked">
          <el-select
            name="Reservé par"
            v-model="kioskModel.user.id"
            prepend-icon="fas fa-calendar"
            :disabled="kioskModel.is_invoice_paied"
          >
            <el-option :value="null" label="Non défini"> </el-option>
            <el-option
              v-for="user in all_users"
              :key="user.id"
              :value="user.id"
              :label="user.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.user" />
      </form>

      <template slot="footer" v-if="!kioskModel.id">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveAddKiosk"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Ajouter un kiosque
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closeKioskModal"
          :disabled="loading"
        >
          Fermer
        </button>
      </template>

      <template slot="footer" v-if="kioskModel.id">
        <base-button
          native-type="submit"
          type="primary"
          class="new-event--add"
          @click="saveEditKiosk"
          :disabled="loading"
          v-if="!kioskModel.is_invoice_paied"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Modifier
        </base-button>
        <base-button
          type="danger"
          @click="deleteKiosk(kioskModel.id)"
          :disabled="loading"
          v-if="!kioskModel.is_invoice_paied"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Supprimer
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="closeKioskModal"
          :disabled="loading"
        >
          Fermer
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Collapse, CollapseItem, Select, Option } from "element-ui";
import moment from "moment";
import { cloneDeep } from "lodash";
import {
  TAX_TPS_NAME,
  TAX_TPS_VALUE,
  TAX_TVQ_NAME,
  TAX_TVQ_VALUE,
  TAX_TYPE_PERCENTAGE,
} from "../../../constants/invoices";

export default {
  name: "event-view-kiosk",

  components: {
    BaseInput,
    ValidationError,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["event"],

  data() {
    return {
      loading: false,
      showModal: false,
      all_users: [],
      collapsed: [],
      kioskModel: {
        type: "event-kiosks",
        name: null,
        excerpt: null,
        price: null,
        user: {
          type: "users",
          id: null,
        },
        booked: false,
        relationshipNames: ["event", "user"],
        event: {
          type: "events",
          id: null,
        },
      },
    };
  },

  methods: {
    expandAll() {
      this.collapsed = this.event.kiosks.map((item) => item.id);
    },

    collapseAll() {
      this.collapsed = [];
    },

    openKioskModal(kiosk) {
      if (kiosk) {
        this.kioskModel.id = kiosk.id;
        this.kioskModel.name = kiosk.name;
        this.kioskModel.excerpt = kiosk.excerpt;
        this.kioskModel.price = kiosk.price;
        this.kioskModel.booked = !!kiosk.booked;
        this.kioskModel.is_invoice_paied = kiosk.is_invoice_paied;
        this.kioskModel.user = {
          type: "users",
          id: kiosk.user ? kiosk.user.id : null,
        };
      } else {
        delete this.kioskModel.id;
        this.kioskModel.name = null;
        this.kioskModel.excerpt = null;
        this.kioskModel.price = null;
        this.kioskModel.booked = false;
        this.kioskModel.is_invoice_paied = false;
        this.kioskModel.user = {
          type: "users",
          id: null,
        };
      }
      this.resetApiValidation();
      this.showModal = true;
    },

    closeKioskModal() {
      this.showModal = false;
      this.loading = false;
    },

    goToUser(user) {
      this.$router.push({
        name: "Edit User",
        params: { id: user.id },
      });
    },

    async getUsers() {
      try {
        await this.$store.dispatch("users/list", {
          sort: "name",
          filter: { active: 1 },
        });
        this.all_users = await this.$store.getters["users/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async deleteKiosk(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("eventKiosks/destroy", id);
        this.$emit("kiosksUpdated", null);

        this.$notify({
          type: "success",
          message: "Kiosk supprimé.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveAddKiosk() {
      this.loading = true;
      const kioskData = cloneDeep(this.kioskModel);
      kioskData.event.id = this.event.id;
      kioskData.price = parseFloat(kioskData.price);
      if (!kioskData.user.id) {
        kioskData.user = null;
      }

      try {
        await this.$store.dispatch("eventKiosks/add", kioskData);
        this.$emit("kiosksUpdated", null);

        this.$notify({
          type: "success",
          message: "Kiosque ajouté.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveEditKiosk() {
      this.loading = true;
      const kioskData = cloneDeep(this.kioskModel);
      kioskData.event.id = this.event.id;
      kioskData.price = parseFloat(kioskData.price);
      if (!kioskData.user.id) {
        kioskData.user = null;
      }

      try {
        await this.$store.dispatch("eventKiosks/update", kioskData);
        this.$emit("kiosksUpdated", null);

        this.$notify({
          type: "success",
          message: "Kiosque Modifié.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async createKioskInvoice(kiosk) {
      this.loading = true;
      let invoice = null;
      let invoiceItem = null;

      try {
        const invoiceData = {
          type: "invoices",
          billing_firstname: "A completer",
          billing_lastname: "A completer",
          billing_country: "CA",
          billing_state: "Quebec",
          billing_city: "A completer",
          billing_zipcode: "A completer",
          billing_address: "A completer",
          billing_email: "a-completer@temporaire.com",
          excerpt: "",
          relationshipNames: ["recipient"],
        };
        await this.$store.dispatch("invoices/add", invoiceData);
        invoice = await this.$store.getters["invoices/invoice"];

        const invoiceItemData = {
          type: "invoice-items",
          code: "Kiosque - " + kiosk.name,
          excerpt: `Reservation du kiosque ${kiosk.name} à l'évènement ${this.event.name}`,
          unit_price: kiosk.price,
          quantity: 1,
          discount: 0,
          taxes: [
            {
              name: TAX_TPS_NAME,
              type: TAX_TYPE_PERCENTAGE,
              value: TAX_TPS_VALUE,
            },
            {
              name: TAX_TVQ_NAME,
              type: TAX_TYPE_PERCENTAGE,
              value: TAX_TVQ_VALUE,
            },
          ],
          relationshipNames: ["invoice", "invoiceable"],
          invoice: {
            type: "invoices",
            id: invoice.id,
          },
          invoiceable: {
            type: "event-kiosks",
            id: kiosk.id,
          },
        };
        await this.$store.dispatch("invoiceItems/add", invoiceItemData);
        invoiceItem = await this.$store.getters["invoices/invoice"];

        this.loading = false;
        this.$notify({
          type: "success",
          message: "Invoice added successfully.",
        });
        this.$router.push({
          name: "Edit Invoice",
          params: { id: invoice.id },
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        if (invoice.id) {
          await this.$store.dispatch("invoices/destroy", invoice.id);
        }
        if (invoiceItem.id) {
          await this.$store.dispatch("invoiceItems/destroy", invoiceItem.id);
        }
      }
    },

    viewKioskInvoice(kiosk) {
      this.$router.push({
        name: "View Invoice",
        params: { id: kiosk.active_invoice },
      });
    },
  },

  created() {
    this.getUsers();
  },

  watch: {},
};
</script>
