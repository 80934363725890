import service from "@/store/services/payments-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  payment: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, payment) => {
    state.payment = payment;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((payment) => {
      commit("SET_RESOURCE", payment);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((payment) => {
      commit("SET_RESOURCE", payment);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((payment) => {
      commit("SET_RESOURCE", payment);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  paypalCreateOrder({ commit, dispatch }, params) {
    return service.paypalCreateOrder(params, this.$axios);
  },

  paypalCancelOrder({ commit, dispatch }, params) {
    return service.paypalCancelOrder(params, this.$axios);
  },

  paypalCapturePayment({ commit, dispatch }, params) {
    return service.paypalCapturePayment(params, this.$axios);
  },

  completeInvoicePayment({ commit, dispatch }, params) {
    return service.completeInvoicePayment(params, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  payment: (state) => state.payment,
  url: (state) => state.url,
};

const payments = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default payments;
