import service from "@/store/services/events-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  event: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, event) => {
    state.event = event;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((event) => {
      commit("SET_RESOURCE", event);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((event) => {
      commit("SET_RESOURCE", event);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((event) => {
      commit("SET_RESOURCE", event);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  upload({ commit, dispatch }, { event, image }) {
    return service.upload(event, image, this.$axios).then((url) => {
      commit("SET_URL", url);
    });
  },

  getRegistrationsSummaryExport({ commit, dispatch }, params) {
    return service.getRegistrationsSummaryExport(params, this.$axios);
  },

  getRegistrationsSummaryReport({ commit, dispatch }, params) {
    return service.getRegistrationsSummaryReport(params, this.$axios);
  },

  getRegistrationsDetailsReport({ commit, dispatch }, params) {
    return service.getRegistrationsDetailsReport(params, this.$axios);
  },

  getAccountingReport({ commit, dispatch }, params) {
    return service.getAccountingReport(params, this.$axios);
  },

  getSignatureSheetReport({ commit, dispatch }, params) {
    return service.getSignatureSheetReport(params, this.$axios);
  },

  getSubsidiesReport({ commit, dispatch }, params) {
    return service.getSubsidiesReport(params, this.$axios);
  },

  getRegistrationsReceiptsReport({ commit, dispatch }, params) {
    return service.getRegistrationsReceiptsReport(params, this.$axios);
  },

  getPackagesRegistrations({ commit, dispatch }, params) {
    return service.getPackagesRegistrations(params, this.$axios);
  },

  sendRegistrationsReceipts({ commit, dispatch }, params) {
    return service.sendRegistrationsReceipts(params, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  event: (state) => state.event,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((event) => ({
      id: event.id,
      name: event.name,
    }));
  },
};

const events = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default events;
