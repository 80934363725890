export const REGISTRATION_STATUS_DRAFT = "DRAFT";
export const REGISTRATION_STATUS_REDRAFT = "REDRAFT";
export const REGISTRATION_STATUS_INVOICED = "INVOICED";
export const REGISTRATION_STATUS_COMPLETED = "COMPLETED";
export const REGISTRATION_STATUS_CANCELED = "CANCELED";

export const registrationStatusesOption = {
  [REGISTRATION_STATUS_DRAFT]: "Brouillon",
  [REGISTRATION_STATUS_INVOICED]: "Facturée",
  [REGISTRATION_STATUS_COMPLETED]: "Completée",
  [REGISTRATION_STATUS_CANCELED]: "Annulée",
  [REGISTRATION_STATUS_REDRAFT]: "Rééditée",
};
