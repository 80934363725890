<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <h3 class="mb-0">
                {{ invoice.name }}
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="editInvoice()"
                  v-if="invoice.status == INVOICE_STATUS_DRAFT"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                    Modifier
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="md"
                  @click="validateInvoice()"
                  v-if="invoice.status == INVOICE_STATUS_DRAFT"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-check-bold"></i>
                    Valider
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="md"
                  @click="sendInvoice()"
                  v-if="invoice.status != INVOICE_STATUS_DRAFT"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-send"></i>
                    Envoyer
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="md"
                  @click="generatePayment()"
                  :disabled="loading"
                  v-if="canGeneratePayment()"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-money-coins"></i>
                    Ajouter un paiement
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="md"
                  @click="refundInvoice()"
                  :disabled="loading"
                  v-if="canRefundInvoice()"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-money-coins"></i>
                    Rembourser
                  </span>
                </base-button>

                <base-button
                  type="danger"
                  icon
                  size="md"
                  @click="cancelInvoice()"
                  :disabled="loading"
                  v-if="invoice.status !== INVOICE_STATUS_CANCELED"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-fat-remove"></i>
                    Annuler
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                Retour a la liste
              </base-button>
            </div>
          </div>
        </template>
        <div class="row py-3 align-items-center">
          <div class="col-12">
            <p class="text-muted text-center">
              Pour appliquer un paiement, la facture doit tout d'abord avoir été
              envoyé au client. Un bouton est disponible à cet effet.
            </p>
          </div>
        </div>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="details"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="false">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    Global
                  </span>
                  <invoice-view-global :invoice="invoice" />
                </tab-pane>

                <tab-pane title="details" id="2" :active="true">
                  <span slot="title">
                    <i class="ni ni-bullet-list-67" />
                    Details
                  </span>
                  <invoice-view-details
                    :invoice="invoice"
                    @invoiceItemsUpdated="get"
                  />
                </tab-pane>

                <tab-pane title="payments" id="3" :active="false">
                  <span slot="title">
                    <i class="ni ni-money-coins" />
                    Paiements
                  </span>
                  <invoice-view-payments :invoice="invoice" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Tabs, TabPane } from "@/components";
import InvoiceViewGlobal from "./partials/InvoiceViewGlobal.vue";
import InvoiceViewDetails from "./partials/InvoiceViewDetails.vue";
import InvoiceViewPayments from "./partials/InvoiceViewPayments.vue";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_SENT,
} from "../../constants/invoices";
import { paymentSourcesOption } from "../../constants/payments";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    InvoiceViewGlobal,
    InvoiceViewDetails,
    InvoiceViewPayments,
  },

  data() {
    return {
      invoice: {
        type: "invoices",
        items: [],
      },
      loading: false,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_SENT: INVOICE_STATUS_SENT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("invoices/get", id);
        this.invoice = this.$store.getters["invoices/invoice"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      }
    },

    async editInvoice(row) {
      this.$router.push({
        name: "Edit Invoice",
        params: { id: this.invoice.id },
      });
    },

    async generatePayment(row) {
      this.$router.push({
        name: "Add Payment",
        params: {},
        query: { invoice_id: this.invoice.id },
      });
    },

    async sendInvoice() {
      try {
        this.loading = true;
        await this.$store.dispatch("invoices/send", this.invoice.id);
        this.invoice = this.$store.getters["invoices/invoice"];
        this.$notify({
          type: "success",
          message: `Facture envoyée`,
        });
        this.loading = false;
      } catch (error) {
        let message = null;
        if (error.response.data.errors.length > 0) {
          message = error.response.data.errors[0].detail;
        }
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong! : ${message}`,
        });
        this.loading = false;
      }
    },

    async validateInvoice() {
      try {
        this.loading = true;
        await this.$store.dispatch("invoices/validate", this.invoice.id);
        this.invoice = this.$store.getters["invoices/invoice"];
        this.$notify({
          type: "success",
          message: `Facture envoyée`,
        });
        this.loading = false;
      } catch (error) {
        let message = null;
        if (error.response.data.errors.length > 0) {
          message = error.response.data.errors[0].detail;
        }
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong! : ${message}`,
        });
        this.loading = false;
      }
    },

    async cancelInvoice() {
      const confirmation = await swal.fire({
        title: `Annuler cette facture?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch("invoices/cancel", this.invoice.id);
        this.invoice = this.$store.getters["invoices/invoice"];
        this.$notify({
          type: "success",
          message: `Facture annulée`,
        });
        this.loading = false;
      } catch (error) {
        let message = null;
        if (error.response.data.errors.length > 0) {
          message = error.response.data.errors[0].detail;
        }
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong! : ${message}`,
        });
        this.loading = false;
      }
    },

    canRefundInvoice() {
      if (this.invoice.status === INVOICE_STATUS_CANCELED) {
        if (this.invoice.total_paied > 0) {
          return true;
        }
      }
      if (this.invoice.pricing) {
        return (
          this.invoice.total_paied.toFixed(2) -
            this.invoice.pricing.total.toFixed(2) >
          0
        );
      }
      return false;
    },

    canGeneratePayment() {
      if (this.invoice.status == INVOICE_STATUS_SENT) {
        if (this.invoice.pricing.total === 0) {
          return true;
        }
      }
      if (this.invoice.pricing) {
        return (
          this.invoice.pricing.total.toFixed(2) -
            this.invoice.total_paied.toFixed(2) >
          0
        );
      }
      return false;
    },

    async refundInvoice() {
      const { value: paymentSource } = await swal.fire({
        title: "Methode de remboursement",
        input: "select",
        inputOptions: paymentSourcesOption,
        inputPlaceholder: "Methode de remboursement",
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (!value) {
              resolve("Vous devez choisir une methode");
            } else {
              resolve();
            }
          });
        },
      });

      try {
        this.loading = true;
        await this.$store.dispatch("invoices/refund", {
          invoiceId: this.invoice.id,
          paymentSource: paymentSource,
        });
        this.invoice = this.$store.getters["invoices/invoice"];
        this.$notify({
          type: "success",
          message: `Facture Remboursée`,
        });
        this.loading = false;
      } catch (error) {
        let message = null;
        if (error.response.data.errors.length > 0) {
          message = error.response.data.errors[0].detail;
        }
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong! : ${message}`,
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List Invoices" });
    },
  },
};
</script>
