var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"true":"","link":{
          name: 'Tableau de bord',
          icon: 'ni ni-shop text-primary',
          path: '/dashboard',
        }}}),(_vm.roles.includes('administrator'))?_c('sidebar-item',{attrs:{"opened":_vm.$route.path.startsWith('/events'),"link":{
          name: 'Evenements',
          path: '/events',
          icon: 'ni ni-calendar-grid-58 text-info',
        }}}):_vm._e(),(_vm.roles.includes('administrator'))?_c('sidebar-item',{attrs:{"opened":_vm.$route.path.startsWith('/invoices'),"link":{
          name: 'Factures',
          path: '/invoices',
          icon: 'ni ni-single-copy-04 text-primary',
        }}}):_vm._e(),(_vm.roles.includes('administrator'))?_c('sidebar-item',{attrs:{"opened":_vm.$route.path.startsWith('/payments'),"link":{
          name: 'Paiements',
          path: '/payments',
          icon: 'ni ni-money-coins text-primary',
        }}}):_vm._e(),(_vm.roles.includes('administrator'))?_c('sidebar-item',{attrs:{"opened":_vm.$route.path.startsWith('/registrations'),"link":{
          name: 'Inscriptions',
          path: '/registrations',
          icon: 'ni ni-single-02 text-primary',
        }}}):_vm._e(),(_vm.roles.includes('administrator'))?_c('sidebar-item',{attrs:{"opened":_vm.$route.path.startsWith('/admin'),"link":{
          name: 'Administration',
          icon: 'ni ni-settings text-success',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Roles',
            path: '/admin/roles',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Utilisateurs',
            path: '/admin/users',
          }}})],1):_vm._e()],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }