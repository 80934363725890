var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"mb-5 event-dates-block"},[_vm._l((_vm.eventDates),function(eventDate){return _c('div',{key:eventDate.id,staticClass:"mb-5"},[_c('span',{staticClass:"mb-4 heading-title font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("moment")(eventDate.date,"LL"))+" ")]),_c('div',{staticClass:"container-fluid"},_vm._l((eventDate.eventTimeSlots),function(slot){return _c('div',{key:slot.id,staticClass:"row mb-3 mt-2 ml-2"},[(slot.addon)?_c('div',{staticClass:"col-12"},[_c('div',[_c('base-checkbox',{attrs:{"disabled":_vm.isSlotDisabled(slot)},model:{value:(_vm.addons[slot.addon.id]),callback:function ($$v) {_vm.$set(_vm.addons, slot.addon.id, $$v)},expression:"addons[slot.addon.id]"}},[_c('div',{staticClass:"display-7 font-weight-600"},[(slot.addon)?_c('i',{staticClass:"ni ni-money-coins ml-1 text-info"}):_vm._e(),_vm._v(" "+_vm._s(_vm._f("moment")(slot.start_time,"LT"))+" - "+_vm._s(_vm._f("moment")(slot.end_time,"LT"))+" : "+_vm._s(slot.name)+" "),_c('span',[_vm._v(" ( $"+_vm._s(slot.addon.price)+" )")]),(slot.attendees_limit)?_c('span',[_vm._v(" ("+_vm._s(slot.attendees_count)+"/"+_vm._s(slot.attendees_limit)+") ")]):_vm._e()])])],1),(_vm.addons[slot.addon.id])?_c('registration-view-slots-guests',{attrs:{"timeSlot":slot,"guests":_vm.registration.guests,"disabled":![
                        _vm.REGISTRATION_STATUS_DRAFT,
                        _vm.REGISTRATION_STATUS_REDRAFT ].includes(_vm.registration.status)},on:{"guestsUpdated":function (guests) { return _vm.updateGuests(slot, guests); }}}):_vm._e()],1):_vm._e(),(!slot.addon)?_c('div',{staticClass:"col-12"},[_c('div',[_c('base-checkbox',{attrs:{"disabled":_vm.isSlotDisabled(slot)},model:{value:(_vm.slots[slot.id]),callback:function ($$v) {_vm.$set(_vm.slots, slot.id, $$v)},expression:"slots[slot.id]"}},[_c('div',{staticClass:"display-7 font-weight-600"},[_vm._v(" "+_vm._s(_vm._f("moment")(slot.start_time,"LT"))+" - "+_vm._s(_vm._f("moment")(slot.end_time,"LT"))+" : "+_vm._s(slot.name)+" "),(slot.attendees_limit)?_c('span',[_vm._v(" ("+_vm._s(slot.attendees_count)+"/"+_vm._s(slot.attendees_limit)+") ")]):_vm._e()])])],1),(_vm.slots[slot.id])?_c('registration-view-slots-guests',{attrs:{"timeSlot":slot,"guests":_vm.registration.guests,"disabled":![
                        _vm.REGISTRATION_STATUS_DRAFT,
                        _vm.REGISTRATION_STATUS_REDRAFT ].includes(_vm.registration.status)},on:{"guestsUpdated":function (guests) { return _vm.updateGuests(slot, guests); }}}):_vm._e()],1):_vm._e()])}),0)])}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.addons}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.eventTimeSlots}})],2),(
            [_vm.REGISTRATION_STATUS_DRAFT, _vm.REGISTRATION_STATUS_REDRAFT].includes(
              _vm.registration.status
            )
          )?_c('div',{staticClass:"mt-5 mb-3 text-center"},[_c('base-button',{staticClass:"new-event--add",attrs:{"native-type":"submit","type":"primary","disabled":_vm.loading},on:{"click":_vm.saveSlots}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" Enregistrer ")])],1):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-5"},[_c('h1',[_vm._v("Plages")])])}]

export { render, staticRenderFns }