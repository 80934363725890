import store from "../store";

export default async function admin({ next, router }) {
  try {
    await store.dispatch("profile/me");
    let me = await store.getters["profile/me"];
    if (store.getters.isAuthenticated && me.roles[0].name !== "administrator") {
      return router.push({ path: "/dashboard" });
    }
  } catch (e) {
    if (e.response) {
      if ([401, 403].includes(e.response.status)) {
        await store.dispatch("logout");
      }
    } else {
      throw e;
    }
  }
  return next();
}
