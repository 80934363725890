<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">Accompagnants</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          v-if="
            [REGISTRATION_STATUS_DRAFT, REGISTRATION_STATUS_REDRAFT].includes(
              registration.status
            )
          "
          @click="openGuestModal()"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          Ajouter un Accompagnant
        </base-button>
      </div>
    </div>

    <div
      class="row"
      v-if="registration.guests && registration.guests.length > 0"
    >
      <div class="col-12">
        <card
          class="slot-item"
          v-for="guest in registration.guests"
          v-bind:key="guest.id"
          :name="guest.id"
        >
          <div class="row align-items-center">
            <div class="col ml-4">
              <div class="text-md mb-3 text-center">
                <h1>
                  {{ guest.name }}
                </h1>
                <h3>
                  {{ guestsTypes[guest.guest_type] }}
                  {{ guest.age ? guest.age + " ans" : null }}
                </h3>
              </div>
              <div
                class="text-md mb-3 text-center text-danger"
                v-if="guest.alergies"
              >
                <h2>
                  Allergies :
                  <span class="text-danger"> {{ guest.alergies }} </span>
                </h2>
              </div>
            </div>
            <!--<div class="col ml-4">
              <div class="text-md mb-3 text-center">
                <h4>Participe a :</h4>
              </div>
            </div>-->
            <div
              class="col-auto"
              v-if="registration.status === REGISTRATION_STATUS_DRAFT"
            >
              <div>
                <base-button
                  type="primary"
                  size="sm"
                  @click="openGuestModal(guest)"
                  v-if="!guest.is_invoice_paied"
                >
                  <i class="fas fa-edit"></i>
                  Modifier
                </base-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!-- Modal add slot -->
    <modal :show.sync="showModal" modal-classes="modal-secondary">
      <form class="" @submit.prevent="saveAddGuest">
        <base-input
          label="Nom"
          placeholder="Nom"
          v-model="guestModel.name"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.name" />

        <base-input label="Type">
          <el-select name="type" v-model="guestModel.guest_type">
            <el-option
              v-for="(type, key) in guestsTypes"
              :key="key"
              :value="key"
              :label="type"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.guest_type" />

        <base-input
          v-if="guestModel.guest_type === GUEST_TYPE_CHILD"
          label="Age"
          placeholder="Age"
          v-model="guestModel.age"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.age" />

        <base-input
          label="Allergies"
          placeholder="Allergies"
          v-model="guestModel.alergies"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.alergies" />
      </form>

      <template slot="footer" v-if="!guestModel.id">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveAddGuest"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Ajouter un Accompagnant
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closeGuestModal"
          :disabled="loading"
        >
          Fermer
        </button>
      </template>

      <template slot="footer" v-if="guestModel.id">
        <base-button
          native-type="submit"
          type="primary"
          class="new-event--add"
          @click="saveEditGuest"
          :disabled="loading"
          v-if="!guestModel.is_invoice_paied"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Modifier
        </base-button>
        <base-button
          type="danger"
          @click="deleteGuest(guestModel.id)"
          :disabled="loading"
          v-if="!guestModel.is_invoice_paied"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Supprimer
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="closeGuestModal"
          :disabled="loading"
        >
          Fermer
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Collapse, CollapseItem, Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import {
  registrationGuestsTypes,
  GUEST_TYPE_CHILD,
} from "../../../constants/registrationGuests";
import {
  REGISTRATION_STATUS_DRAFT,
  REGISTRATION_STATUS_REDRAFT,
} from "../../../constants/registrations";

export default {
  name: "registration-guests",

  components: {
    BaseInput,
    ValidationError,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["registration"],

  data() {
    return {
      loading: false,
      showModal: false,
      all_users: [],
      collapsed: [],
      guestModel: {
        type: "registration-guests",
        name: null,
        age: null,
        guest_type: null,
        alergies: null,
        relationshipNames: ["registration", "eventTimeSlots"],
        registration: {
          type: "registrations",
          id: null,
        },
        eventTimeSlots: [],
      },
      guestsTypes: registrationGuestsTypes,
      GUEST_TYPE_CHILD: GUEST_TYPE_CHILD,
      REGISTRATION_STATUS_DRAFT: REGISTRATION_STATUS_DRAFT,
      REGISTRATION_STATUS_REDRAFT: REGISTRATION_STATUS_REDRAFT,
    };
  },

  methods: {
    expandAll() {
      this.collapsed = this.registration.guests.map((item) => item.id);
    },

    collapseAll() {
      this.collapsed = [];
    },

    openGuestModal(guest) {
      if (guest) {
        this.guestModel.id = guest.id;
        this.guestModel.name = guest.name;
        this.guestModel.guest_type = guest.guest_type;
        this.guestModel.age = guest.age;
        this.guestModel.alergies = guest.alergies;
      } else {
        delete this.guestModel.id;
        this.guestModel.name = null;
        this.guestModel.guest_type = null;
        this.guestModel.age = null;
        this.guestModel.alergies = null;
      }
      this.resetApiValidation();
      this.showModal = true;
    },

    closeGuestModal() {
      this.showModal = false;
      this.loading = false;
    },

    async deleteGuest(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("registrationGuests/destroy", id);
        this.$emit("guestsUpdated", null);

        this.$notify({
          type: "success",
          message: "Guest supprimé.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveAddGuest() {
      this.loading = true;
      const guestData = cloneDeep(this.guestModel);
      guestData.registration.id = this.registration.id;

      try {
        await this.$store.dispatch("registrationGuests/add", guestData);
        this.$emit("guestsUpdated", null);

        this.$notify({
          type: "success",
          message: "Accompagnant ajouté.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveEditGuest() {
      this.loading = true;
      this.loading = true;
      const guestData = cloneDeep(this.guestModel);
      guestData.registration.id = this.registration.id;

      try {
        await this.$store.dispatch("registrationGuests/update", guestData);
        this.$emit("guestsUpdated", null);

        this.$notify({
          type: "success",
          message: "Accompagnant Modifié.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
  },

  watch: {},
};
</script>
