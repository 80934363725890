import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/payments?include=invoice`, options)
    .then((response) => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta,
      };
    });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/payments/${id}?include=invoice`, options)
    .then((response) => {
      let payment = jsona.deserialize(response.data);
      delete payment.links;
      return payment;
    });
}

function add(payment) {
  const payload = jsona.serialize({
    stuff: payment,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/payments`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(payment) {
  const payload = jsona.serialize({
    stuff: payment,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/payments/${payment.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/payments/${id}`, options);
}

function paypalCreateOrder(id) {
  const payload = jsona.serialize({
    stuff: {
      invoice: id,
      type: 'paypal-payments',
    },
    includeNames: [],
  })

  const options = {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  }

  return axios
    .post(`${url}/payments/paypal/orders`, payload, options)
    .then((response) => {
      return response.data
    });
}

function paypalCancelOrder(id) {
  const options = {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  }

  return axios
    .get(`${url}/payments/paypal/orders/${id}/cancel`, options)
    .then((response) => {
      return response.data
    });
}

function paypalCapturePayment(id) {
  const payload = {}

  const options = {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  }

  return axios
    .post(`${url}/payments/paypal/orders/${id}/capture`, payload, options)
    .then((response) => {
      return response.data
    });
}

function completeInvoicePayment(id) {
  const options = {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  }

  return axios
    .get(`${url}/invoices/${id}/complete_invoice_payment`, options)
    .then((response) => {
      const registration = jsona.deserialize(response.data)
      delete (registration).links
      return registration
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  paypalCreateOrder,
  paypalCancelOrder,
  paypalCapturePayment,
  completeInvoicePayment,
};
