<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">
                {{ payment.code }}
              </h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                Retour a la liste
              </base-button>
            </div>
          </div>
        </template>
        <div>
          <div
            class="
              col-12
              justify-content-center justify-content-sm-between
              flex-wrap
            "
          >
            <template>
              <div class="container-fluid">
                <div class="row mb-5">
                  <h1>{{ payment.code }}</h1>
                </div>

                <div class="row mb-5">
                  <div class="col-12">
                    <dl class="row">
                      <dt class="col-sm-4">Date</dt>
                      <dd class="col-sm-8">
                        {{ payment.date | moment("LLLL") }}
                      </dd>

                      <dt class="col-sm-4" v-if="payment.invoice">Facture</dt>
                      <dd class="col-sm-8" v-if="payment.invoice">
                        <a
                          href=""
                          @click.prevent="goToInvoice(payment.invoice)"
                        >
                          {{ payment.invoice.code }} (${{
                            payment.invoice.total_paied.toFixed(2)
                          }}
                          / ${{ payment.invoice.pricing.total.toFixed(2) }})
                        </a>
                      </dd>

                      <dt class="col-sm-4">Montant</dt>
                      <dd class="col-sm-8">
                        <span> ${{ payment.amount.toFixed(2) }} </span>
                      </dd>

                      <dt class="col-sm-4">Source</dt>
                      <dd class="col-sm-8">
                        <span> {{ sourcesOption[payment.source] }} </span>
                      </dd>

                      <dt class="col-sm-4">Statut</dt>
                      <dd class="col-sm-8">
                        <payment-status-badge :payment="payment" />
                      </dd>

                      <dt class="col-sm-4" v-if="payment.excerpt">Note</dt>
                      <dd class="col-sm-8" v-if="payment.excerpt">
                        <div v-html="payment.excerpt"></div>
                      </dd>

                      <dt class="col-sm-4" v-if="payment.transaction_id">
                        Id paiement
                      </dt>
                      <dd class="col-sm-8" v-if="payment.transaction_id">
                        {{ payment.transaction_id }}
                      </dd>

                      <dt class="col-sm-4" v-if="payment.transaction_data">
                        Données paiement
                      </dt>
                      <dd class="col-sm-8" v-if="payment.transaction_data">
                        {{ payment.transaction_data }}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import {
  paymentSourcesOption,
  paymentStatusesOption,
} from "../../constants/payments";
import PaymentStatusBadge from "./PaymentStatusBadge.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PaymentStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  data() {
    return {
      payment: {
        type: "payments",
        amount: 0,
        invoice: {
          pricing: {
            total: 0,
          },
          total_paied: 0,
        },
      },
      statusesOption: paymentStatusesOption,
      sourcesOption: paymentSourcesOption,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("payments/get", id);
        this.payment = this.$store.getters["payments/payment"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      }
    },

    async editPayment(row) {
      this.$router.push({
        name: "Edit Payment",
        params: { id: this.payment.id },
      });
    },

    goBack() {
      this.$router.push({ name: "List Payments" });
    },

    goToInvoice(invoice) {
      this.$router.push({
        name: "View Invoice",
        params: { id: invoice.id },
      });
    },
  },
};
</script>
