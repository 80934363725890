import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
// import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import roles from "./modules/roles-module";
import events from "./modules/events-module";
import eventDates from "./modules/event-dates-module";
import eventTimeSlots from "./modules/event-time-slots-module";
import eventPackageAddons from "./modules/event-package-addons-module";
import eventPackages from "./modules/event-packages-module";
import eventKiosks from "./modules/event-kiosks-module";
import eventSubsidies from "./modules/event-subsidies-module";
import eventCustomUsers from "./modules/event-custom-users-module";
import invoices from "./modules/invoices-module";
import invoiceItems from "./modules/invoice-items-module";
import payments from "./modules/payments-module";
import registrations from "./modules/registrations-module";
import registrationGuests from "./modules/registration-guests-module";
import promoCodes from "./modules/promo-codes-module";

import reset from "./modules/reset";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    // alerts,
    profile,
    users,
    roles,
    events,
    eventDates,
    eventTimeSlots,
    eventPackageAddons,
    eventPackages,
    eventKiosks,
    eventSubsidies,
    eventCustomUsers,
    invoices,
    invoiceItems,
    payments,
    registrations,
    registrationGuests,
    reset,
    promoCodes,
  },
});
