<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">Inscriptions</span>

        <base-dropdown>
          <template slot="title-container">
            <base-button type="primary" size="sm">
              <i class="fas fa-cog"></i>
              Actions
            </base-button>
          </template>
          <a
            class="dropdown-item"
            href="javascript:;"
            @click="downloadRegistrationsSummaryReport()"
          >
            <i class="fas fa-file-pdf"></i>
            Résumé des inscriptions
          </a>

          <a
            class="dropdown-item"
            href="javascript:;"
            @click="downloadRegistrationsReceiptsReport()"
          >
            <i class="fas fa-file-pdf"></i>
            Télécharger les reçus des inscriptions
          </a>

          <a
            class="dropdown-item"
            href="javascript:;"
            @click="
              () => {
                showSendReceiptsModal = true;
              }
            "
          >
            <i class="fas fa-paper-plane"></i>
            Envoyer les reçus des inscriptions
          </a>

          <a
            class="dropdown-item"
            href="javascript:;"
            @click="downloadRegistrationsDetailsReport()"
          >
            <i class="fas fa-file-pdf"></i>
            Détails des inscriptions
          </a>

          <a
            class="dropdown-item"
            href="javascript:;"
            @click="downloadRegistrationsSummaryExport()"
          >
            <i class="fas fa-file-excel"></i>
            Résumé des inscriptions excel
          </a>

          <a
            class="dropdown-item"
            href="javascript:;"
            @click="downloadPackagesRegistrationsReport()"
          >
            <i class="fas fa-file-pdf"></i>
            Inscriptions Forfaits
          </a>
        </base-dropdown>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openRegistrationModal()"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          Ajouter une inscription
        </base-button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <event-view-registration-list
          :event="event"
          :all_users="all_users"
          :key="renderKey"
        />
      </div>
    </div>

    <!-- Modal add slot -->
    <modal :show.sync="showModal" modal-classes="modal-secondary">
      <form class="" @submit.prevent="saveAddRegistration">
        <base-input label="Utilisateur">
          <el-select
            filterable
            name="Utilisateur"
            v-model="registrationModel.user.id"
          >
            <el-option
              v-for="user in all_users"
              :key="user.id"
              :value="user.id"
              :label="user.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.user" />

        <base-input label="Forfaits">
          <el-select multiple name="Forfaits" v-model="eventPackages">
            <el-option
              v-for="pack in event.packages"
              :key="pack.id"
              :value="pack.id"
              :label="pack.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.eventPackages" />

        <base-input label="Role">
          <el-select name="Role" v-model="registrationModel.role.id">
            <el-option
              v-for="role in event.allowedRoles"
              :key="role.id"
              :value="role.id"
              :label="role.name"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.allowedRoles" />
      </form>

      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveAddRegistration"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Ajouter une inscription
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closeRegistrationModal"
          :disabled="loading"
        >
          Fermer
        </button>
      </template>
    </modal>

    <!-- send receipts modal -->
    <event-view-registration-send-receipt-modal
      :event="event"
      :showModal="showSendReceiptsModal"
      @onCloseModal="closeSendReceiptModal"
    />
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Collapse, CollapseItem, Select, Option } from "element-ui";
import EventViewRegistrationList from "./EventViewRegistrationList.vue";
import { cloneDeep, snakeCase } from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  REGISTRATION_STATUS_CANCELED,
  REGISTRATION_STATUS_DRAFT,
} from "../../../constants/registrations";
import EventViewRegistrationSendReceiptModal from "./EventViewRegistrationSendReceiptModal.vue";

export default {
  name: "event-view-registration",

  components: {
    BaseInput,
    ValidationError,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
    EventViewRegistrationList,
    EventViewRegistrationSendReceiptModal,
  },

  mixins: [formMixin],

  props: ["event"],

  data() {
    return {
      loading: false,
      showModal: false,
      all_users: [],
      collapsed: [],
      eventPackages: [],
      registrationModel: {
        type: "registrations",
        relationshipNames: [
          "user",
          "eventPackages",
          "role",
          "eventTimeSlots",
          "addons",
        ],
        user: {
          type: "users",
          id: null,
        },
        eventPackages: [],
        eventTimeSlots: [],
        addons: [],
        role: {
          type: "roles",
          id: null,
        },
      },
      REGISTRATION_STATUS_DRAFT: REGISTRATION_STATUS_DRAFT,
      REGISTRATION_STATUS_CANCELED: REGISTRATION_STATUS_CANCELED,
      showSendReceiptsModal: false,
      renderKey: 0,
    };
  },

  methods: {
    openRegistrationModal() {
      delete this.registrationModel.id;
      this.registrationModel.user = {
        type: "users",
        id: null,
      };
      this.registrationModel.eventPackages = [];
      this.registrationModel.role = {
        type: "roles",
        id: null,
      };
      this.resetApiValidation();
      this.showModal = true;
    },

    closeRegistrationModal() {
      this.showModal = false;
      this.loading = false;
    },

    async getUsers() {
      try {
        await this.$store.dispatch("users/list", {
          sort: "name",
          filter: { active: 1 },
        });
        this.all_users = await this.$store.getters["users/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    async saveAddRegistration() {
      this.resetApiValidation();
      this.loading = true;
      const registrationData = cloneDeep(this.registrationModel);
      this.eventPackages.forEach((eventPackage) => {
        registrationData.eventPackages.push({
          type: "event-packages",
          id: eventPackage,
        });
      });
      try {
        await this.$store.dispatch("registrations/add", registrationData);
        const registration = this.$store.getters["registrations/registration"];
        this.$emit("registrationsUpdated", null);
        this.$notify({
          type: "success",
          message: "Inscription ajouté.",
        });
        this.loading = false;
        this.showModal = false;
        this.viewRegistration(registration);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    viewRegistration(row) {
      this.$router.push({
        name: "View Registration",
        params: { id: row.id },
      });
    },

    async downloadRegistrationsSummaryReport() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "events/getRegistrationsSummaryReport",
          this.event.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.event.name)}-résumé-participants.pdf`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    async downloadRegistrationsSummaryExport() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "events/getRegistrationsSummaryExport",
          this.event.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.event.name)}-résumé-participants.xlsx`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    async downloadRegistrationsDetailsReport() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "events/getRegistrationsDetailsReport",
          this.event.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.event.name)}-details-participants.pdf`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    async downloadRegistrationsReceiptsReport() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "events/getRegistrationsReceiptsReport",
          this.event.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.event.name)}-recus-inscriptions.pdf`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    async downloadPackagesRegistrationsReport() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "events/getPackagesRegistrations",
          this.event.id
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${snakeCase(this.event.name)}-forfaits-inscriptions.pdf`
        );
        document.body.appendChild(link);
        link.click();

        swal.close();
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    closeSendReceiptModal(refresh) {
      this.showSendReceiptsModal = false;
      if (refresh) {
        this.renderKey++;
      }
    },
  },

  created() {
    this.getUsers();
  },

  watch: {},
};
</script>
