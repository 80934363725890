<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">Codes promo</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openPromoCodeModal()"
          type="button"
          class="btn btn-sm btn-primary"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          Ajouter un code promo
        </base-button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card
          class="slot-item"
          v-for="promoCode in event.promoCodes"
          v-bind:key="promoCode.id"
        >
          <div class="row align-items-center">
            <div class="col-10 text-left">
              <div class="display-5">
                $ {{ promoCode.code }}
                {{ promoCode.excerpt ? ` - ${promoCode.excerpt}` : null }}
              </div>
              <div class="text-info">
                ● Maximum d'utilisations: {{ promoCode.max_usages_count }}
              </div>
              <div class="text-info">
                ● Réduction:
                {{
                  promoCode.discount_type == DISCOUNT_TYPE_AMOUNT
                    ? `CAD ${promoCode.discount_value}`
                    : `${promoCode.discount_value} %`
                }}
              </div>
            </div>
            <div class="col-2">
              <div>
                <base-button
                  class="my-auto"
                  type="primary"
                  size="sm"
                  @click="openPromoCodeModal(promoCode)"
                >
                  <i class="fas fa-edit"></i>
                  Modifier
                </base-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!-- Modal add slot -->
    <modal :show.sync="showModal" modal-classes="modal-secondary">
      <form class="" @submit.prevent="saveAddPromoCode">
        <base-input
          label="code"
          placeholder="Code"
          v-model="promoCodesModel.code"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.code" />

        <base-input
          label="Maximum d'utilisations"
          placeholder="Maximum d'utilisations"
          v-model="promoCodesModel.max_usages_count"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.max_usages_count" />

        <!-- <base-input label="Type réduction">
          <el-select
            filterable
            name="Type réduction"
            v-model="promoCodesModel.discount_type"
          >
            <el-option :value="DISCOUNT_TYPE_PERCENTAGE" label="Pourcentage"> </el-option>
            <el-option :value="DISCOUNT_TYPE_AMOUNT" label="Montant"> </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.discount_type" /> -->

        <base-input
          :label="
            promoCodesModel.discount_type == DISCOUNT_TYPE_AMOUNT
              ? 'Montant'
              : 'Pourcentage'
          "
          :placeholder="
            promoCodesModel.discount_type == DISCOUNT_TYPE_AMOUNT
              ? 'Montant'
              : 'Pourcentage'
          "
          v-model="promoCodesModel.discount_value"
          input-classes="form-control-alternative"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.discount_value" />

        <base-input label="Categorie réduction">
          <el-select
            filterable
            name="Categorie réduction"
            v-model="promoCodesModel.discount_registration_type"
          >
            <el-option :value="PACKAGE_TYPE_REGISTRATION" label="Inscription">
            </el-option>
            <el-option :value="PACKAGE_TYPE_SOCIAL" label="Social"> </el-option>
          </el-select>
        </base-input>
        <validation-error
          :errors="apiValidationErrors.discount_registration_type"
        />

        <base-input
          label="Description"
          placeholder="Description"
          input-classes="form-control-alternative"
        >
          <textarea
            class="form-control"
            rows="3"
            v-model="promoCodesModel.excerpt"
          ></textarea>
        </base-input>
        <validation-error :errors="apiValidationErrors.excerpt" />
      </form>

      <template slot="footer" v-if="!promoCodesModel.id">
        <button
          type="submit"
          class="btn btn-primary"
          @click="saveAddPromoCode"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Ajouter un code promo
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closePromoCodeModal"
          :disabled="loading"
        >
          Fermer
        </button>
      </template>

      <template slot="footer" v-if="promoCodesModel.id">
        <base-button
          native-type="submit"
          type="primary"
          class="new-event--add"
          @click="saveEditPromoCode"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Modifier
        </base-button>
        <base-button
          type="danger"
          @click="deletePromoCode(promoCodesModel.id)"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          Supprimer
        </base-button>
        <base-button
          type="link"
          class="ml-auto"
          @click="closePromoCodeModal"
          :disabled="loading"
        >
          Fermer
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import {
  DISCOUNT_TYPE_AMOUNT,
  DISCOUNT_TYPE_PERCENTAGE,
  PACKAGE_TYPE_REGISTRATION,
  PACKAGE_TYPE_SOCIAL,
} from "@/constants/promoCodes";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { Collapse, CollapseItem, Select, Option } from "element-ui";
import { cloneDeep } from "lodash";

export default {
  name: "event-view-promo-codes",

  components: {
    BaseInput,
    ValidationError,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["event"],

  data() {
    return {
      loading: false,
      showModal: false,
      all_users: [],
      collapsed: [],
      promoCodesModel: {
        type: "promo-codes",
        code: null,
        excerpt: null,
        max_usages_count: 999999,
        discount_type: DISCOUNT_TYPE_AMOUNT,
        discount_value: null,
        discount_registration_type: PACKAGE_TYPE_REGISTRATION,
        relationshipNames: ["event"],
        event: {
          type: "events",
          id: this.event.id,
        },
      },
      DISCOUNT_TYPE_AMOUNT,
      DISCOUNT_TYPE_PERCENTAGE,
      PACKAGE_TYPE_REGISTRATION,
      PACKAGE_TYPE_SOCIAL,
    };
  },

  methods: {
    openPromoCodeModal(promoCodes) {
      if (promoCodes) {
        this.promoCodesModel.id = promoCodes.id;
        this.promoCodesModel.code = promoCodes.code;
        this.promoCodesModel.excerpt = promoCodes.excerpt;
        this.promoCodesModel.max_usages_count = promoCodes.max_usages_count;
        this.promoCodesModel.discount_type = promoCodes.discount_type;
        this.promoCodesModel.discount_value = promoCodes.discount_value;
        this.promoCodesModel.discount_registration_type =
          promoCodes.discount_registration_type;
        this.promoCodesModel.event = {
          type: "events",
          id: this.event.id,
        };
      } else {
        delete this.promoCodesModel.id;
        this.promoCodesModel.code = null;
        this.promoCodesModel.excerpt = null;
        this.promoCodesModel.max_usages_count = 999999;
        this.promoCodesModel.discount_type = DISCOUNT_TYPE_AMOUNT;
        this.promoCodesModel.discount_value = null;
        this.promoCodesModel.discount_registration_type =
          PACKAGE_TYPE_REGISTRATION;
        this.promoCodesModel.event = {
          type: "events",
          id: this.event.id,
        };
      }
      this.resetApiValidation();
      this.showModal = true;
    },

    closePromoCodeModal() {
      this.showModal = false;
      this.loading = false;
    },

    async deletePromoCode(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("promoCodes/destroy", id);
        this.$emit("promoCodesUpdated", null);

        this.$notify({
          type: "success",
          message: "code promo supprimé.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveAddPromoCode() {
      this.loading = true;
      const promoCodesData = cloneDeep(this.promoCodesModel);
      promoCodesData.discount_value = parseFloat(promoCodesData.discount_value);

      try {
        await this.$store.dispatch("promoCodes/add", promoCodesData);
        this.$emit("promoCodesUpdated", null);

        this.$notify({
          type: "success",
          message: "code promo ajouté.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    async saveEditPromoCode() {
      this.loading = true;
      const promoCodesData = cloneDeep(this.promoCodesModel);
      promoCodesData.discount_value = parseFloat(promoCodesData.discount_value);

      try {
        await this.$store.dispatch("promoCodes/update", promoCodesData);
        this.$emit("promoCodesUpdated", null);

        this.$notify({
          type: "success",
          message: "Code promo Modifié.",
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
  },

  watch: {},
};
</script>
