import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/events`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/events/${id}?include=eventDates,eventDates.eventTimeSlots,allowedRoles,user,packages,packages.addons,kiosks,subsidies,customUsers,customUsers.user,promoCodes`,
      options
    )
    .then((response) => {
      let event = jsona.deserialize(response.data);
      delete event.links;
      return event;
    });
}

function add(event) {
  const payload = jsona.serialize({
    stuff: event,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/events?include=eventDates,allowedRoles`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(event) {
  const payload = jsona.serialize({
    stuff: event,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/events/${event.id}?include=eventDates,allowedRoles`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/events/${id}`, options);
}

function upload(event, image) {
  const payload = new FormData();
  payload.append("attachment", image);

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/uploads/events/${event.id}/profile-image`, payload, options)
    .then((response) => {
      return response.data.url;
    });
}

function getRegistrationsSummaryExport(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(
    `${url}/events/${id}/exports/registrations-summary`,
    options
  );
}

function getRegistrationsSummaryReport(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(
    `${url}/events/${id}/reports/registrations-summary`,
    options
  );
}

function getRegistrationsDetailsReport(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(
    `${url}/events/${id}/reports/registrations-details`,
    options
  );
}

function getAccountingReport(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(`${url}/events/${id}/reports/accounting`, options);
}

function getSignatureSheetReport(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(`${url}/events/${id}/reports/signature-sheet`, options);
}

function getSubsidiesReport(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(`${url}/events/${id}/reports/subsidies`, options);
}

function getRegistrationsReceiptsReport(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(
    `${url}/events/${id}/reports/registrations-receipts`,
    options
  );
}


function sendRegistrationsReceipts(data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/events/${data.event_id}/reports/registrations-receipts/send`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function getPackagesRegistrations(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };

  return axios.get(`${url}/events/${id}/reports/packages-registrations`, options);
}


export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  getRegistrationsSummaryExport,
  getRegistrationsSummaryReport,
  getRegistrationsDetailsReport,
  getAccountingReport,
  getSignatureSheetReport,
  getSubsidiesReport,
  getRegistrationsReceiptsReport,
  getPackagesRegistrations,
  sendRegistrationsReceipts,
};
