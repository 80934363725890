<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ invoice.code }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">Créé le</dt>
          <dd class="col-sm-8">
            {{ invoice.created_at | moment("LLLL") }}
          </dd>

          <dt class="col-sm-4">Créé par</dt>
          <dd class="col-sm-8">
            <a href="#" @click.prevent="goToUser(invoice.user)">
              {{ invoice.user ? invoice.user.name : null }}
            </a>
          </dd>

          <dt class="col-sm-4">Destinataire</dt>
          <dd class="col-sm-8">
            <a href="#" @click.prevent="goToUser(invoice.recipient)">
              {{ invoice.recipient ? invoice.recipient.name : null }}
            </a>
          </dd>

          <dt class="col-sm-4">Status</dt>
          <dd class="col-sm-8">
            <invoice-status-badge :invoice="invoice" />
          </dd>

          <dt class="col-sm-4">Total</dt>
          <dd class="col-sm-8" v-if="invoice.pricing">
            <span> ${{ invoice.pricing.total.toFixed(2) }} </span>
          </dd>

          <dt class="col-sm-4">Total payé</dt>
          <dd class="col-sm-8">
            <span>
              ${{ invoice.total_paied ? invoice.total_paied.toFixed(2) : 0 }}
            </span>
          </dd>

          <dt class="col-sm-4" v-if="invoice.excerpt">Note</dt>
          <dd class="col-sm-8" v-if="invoice.excerpt">
            <div v-html="invoice.excerpt"></div>
          </dd>
        </dl>
      </div>

      <div class="col-12 text-center mb-2 mt-5">
        <h2>Addresse de facturation</h2>
      </div>

      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">Nom</dt>
          <dd class="col-sm-8">
            {{ invoice.billing_lastname }}
          </dd>

          <dt class="col-sm-4">Prénom</dt>
          <dd class="col-sm-8">
            {{ invoice.billing_firstname }}
          </dd>

          <dt class="col-sm-4">Pays</dt>
          <dd class="col-sm-8">
            {{ invoice.billing_country }}
          </dd>

          <dt class="col-sm-4">Etat</dt>
          <dd class="col-sm-8">
            {{ invoice.billing_state }}
          </dd>

          <dt class="col-sm-4">Ville</dt>
          <dd class="col-sm-8">
            {{ invoice.billing_city }}
          </dd>

          <dt class="col-sm-4">Code postal</dt>
          <dd class="col-sm-8">
            {{ invoice.billing_zipcode }}
          </dd>

          <dt class="col-sm-4">Addresse</dt>
          <dd class="col-sm-8">
            {{ invoice.billing_address }}
          </dd>

          <dt class="col-sm-4">Courriel</dt>
          <dd class="col-sm-8">
            {{ invoice.billing_email }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { allCountries } from "country-region-data";
import { invoiceStatusesOption } from "../../../constants/invoices";
import InvoiceStatusBadge from "./InvoiceStatusBadge.vue";

export default {
  name: "invoice-view-global",

  components: { InvoiceStatusBadge },

  props: ["invoice"],

  data() {
    return {
      statusesOptions: invoiceStatusesOption,
    };
  },

  computed: {},

  methods: {
    goToUser(user) {
      this.$router.push({
        name: "Edit User",
        params: { id: user.id },
      });
    },
  },

  mounted() {},

  watch: {},
};
</script>
