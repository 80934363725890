<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-payments-center">
            <div class="col-8">
              <h3 class="mb-0">Ajouter un paiement</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                Retour a la liste
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col">
                  <base-input label="Facture">
                    <el-select
                      filterable
                      name="Facture"
                      v-model="payment.invoice.id"
                      @change="selectInvoice"
                      :disabled="!!queryInvoiceId"
                    >
                      <el-option
                        v-for="invoice in all_invoices"
                        :key="invoice.id"
                        :value="invoice.id"
                        :label="invoice.code"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.invoice" />
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <base-input label="Source">
                    <el-select
                      name="Source"
                      v-model.lazy="payment.source"
                      placeholder="Source"
                      @change="changePaymentSource"
                    >
                      <el-option
                        v-for="(label, val) in sourcesOption"
                        :key="val"
                        :value="val"
                        :label="label"
                      ></el-option>
                    </el-select>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.source" />
                </div>
              </div>

              <div v-if="payment.source === PAYMENT_SOURCE_MANUAL">
                <div class="row">
                  <div class="col">
                    <base-input label="Statut">
                      <el-select name="Statut" v-model="payment.status">
                        <el-option
                          v-for="(label, val) in statusesOption"
                          v-bind:key="val"
                          :value="val"
                          :label="label"
                        />
                      </el-select>
                    </base-input>
                    <validation-error :errors="apiValidationErrors.status" />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <base-input
                      label="Montant"
                      placeholder="Montant"
                      v-model="payment.amount"
                      type="number"
                      step="0.01"
                      input-classes="form-control-alternative"
                    >
                    </base-input>
                    <validation-error :errors="apiValidationErrors.amount" />
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <base-input label="Note">
                      <html-editor v-model="payment.excerpt" name="editor" />
                    </base-input>
                    <validation-error :errors="apiValidationErrors.excerpt" />
                  </div>
                </div>

                <div v-if="[PAYMENT_SOURCE_PAYPAL].includes(payment.source)">
                  <h2 class="mt-5">Details source</h2>
                  <div class="row">
                    <div class="col">
                      <base-input
                        label="Id paiement"
                        v-model="payment.transaction_id"
                      >
                      </base-input>
                      <validation-error
                        :errors="apiValidationErrors.transaction_id"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <base-input label="Données paiement">
                        <textarea
                          class="form-control"
                          rows="6"
                          v-model="payment.transaction_data"
                        ></textarea>
                      </base-input>
                      <validation-error
                        :errors="apiValidationErrors.transaction_data"
                      />
                    </div>
                  </div>
                </div>

                <div class="my-4">
                  <base-button
                    type="button"
                    class="btn btn-sm btn-primary"
                    native-type="submit"
                  >
                    Ajouter un paiement
                  </base-button>
                </div>
              </div>

              <div
                v-if="payment.source === PAYMENT_SOURCE_PAYPAL"
                class="text-center"
              >
                <i v-if="paypalLoading" class="fa fa-spin fa-spinner fa-4x"></i>
                <div id="paypal-button-container"></div>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import { loadScript } from "@paypal/paypal-js";
import { allCountries } from "country-region-data";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import {
  paymentSourcesOption,
  paymentStatusesOption,
  PAYMENT_SOURCE_MANUAL,
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_SOURCE_PAYPAL,
} from "../../constants/payments";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    BaseButton,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  data() {
    return {
      statusesOption: paymentStatusesOption,
      sourcesOption: paymentSourcesOption,
      selectedCountry: null,
      all_invoices: [],
      payment: {
        type: "payments",
        relationshipNames: ["invoice"],
        invoice: {
          type: "invoices",
          id: null,
        },
        amount: null,
        status: null,
        source: null,
      },
      PAYMENT_SOURCE_PAYPAL: PAYMENT_SOURCE_PAYPAL,
      PAYMENT_SOURCE_MANUAL: PAYMENT_SOURCE_MANUAL,
      paypal: null,
      paypalLoading: false,
      queryInvoiceId: null,
    };
  },
  created() {
    this.queryInvoiceId = this.$route.query.invoice_id;
    this.getInvoices();
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List Payments" });
    },

    async viewPayment() {
      this.$router.push({
        name: "View Payment",
        params: { id: this.payment.id },
      });
    },

    async handleSubmit() {
      const paymentData = cloneDeep(this.payment);
      delete paymentData.id;

      try {
        await this.$store.dispatch("payments/add", paymentData);
        this.payment = await this.$store.getters["payments/payment"];
        this.$notify({
          type: "success",
          message: "Payment added successfully.",
        });
        this.viewPayment();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.payment.id) {
          await this.$store.dispatch("payments/destroy", this.payment.id);
        }
      }
    },

    async getInvoices() {
      try {
        const params = { sort: "code" };
        if (this.queryInvoiceId) {
          params.filter = { id: this.queryInvoiceId };
        }
        await this.$store.dispatch("invoices/list", params);
        this.all_invoices = await this.$store.getters["invoices/dropdown"];
        if (this.$route.query.invoice_id) {
          this.selectInvoice(this.$route.query.invoice_id);
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    getAllCountries() {
      return allCountries;
    },

    selectCountry(countryCode) {
      const country = allCountries.find((el) => el[1] == countryCode);
      this.selectedCountry = country;
    },

    getRegions() {
      return this.selectedCountry ? this.selectedCountry[2] : [];
    },

    async selectInvoice(id) {
      const invoices_list = await this.$store.getters["invoices/list"];
      const invoice = invoices_list.find((item) => item.id === id);
      this.payment.invoice.id = invoice.id;
      this.payment.source = PAYMENT_SOURCE_MANUAL;
      this.payment.status = PAYMENT_STATUS_COMPLETED;
      this.payment.amount = (
        invoice.pricing.total - invoice.total_paied
      ).toFixed(2);
    },

    createButtons() {
      this.paypal
        .Buttons({
          style: {
            layout: "vertical",
            color: "blue",
            shape: "rect",
            label: "paypal",
          },
          createOrder: async (_data) => {
            this.paypalLoading = true;
            const order = await this.$store.dispatch(
              "payments/paypalCreateOrder",
              this.payment.invoice.id
            );
            this.paypalLoading = false;
            return order.id;
          },
          onApprove: async (data) => {
            this.paypalLoading = true;
            await this.$store.dispatch(
              "payments/paypalCapturePayment",
              data.orderID
            );
            this.$router.push({
              name: "View Invoice",
              params: { id: this.payment.invoice.id },
            });
            this.paypalLoading = false;
          },
          onCancel: async (data) => {
            this.paypalLoading = true;
            await this.$store.dispatch(
              "payments/paypalCancelOrder",
              data.orderID
            );
            this.paypalLoading = false;
          },
          onError: async (err) => {
            await swal.fire({
              title: "Erreur du paiement paypal",
              text: err,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonClass: "btn btn-primary",
              cancelButtonClass: "btn btn-warning",
            });
            console.log(err);
            this.paypalLoading = false;
          },
        })
        .render("#paypal-button-container");
      this.paypalLoading = false;
    },

    changePaymentSource(paymentSource) {
      if (paymentSource === PAYMENT_SOURCE_PAYPAL) {
        this.paypalLoading = true;
        loadScript({
          "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID,
          currency: process.env.VUE_APP_DEFAULT_CURRENCY,
        })
          .then((paypal) => {
            this.paypal = paypal;
            this.paypalLoading = false;
            this.createButtons();
          })
          .catch(async (err) => {
            await swal.fire({
              title: "Erreur lors de l'affichage des boutons paypal",
              text: err,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonClass: "btn btn-primary",
              cancelButtonClass: "btn btn-warning",
            });
            console.error("failed to load the PayPal JS SDK script", err);
          });
      }
    },
  },
};
</script>
