<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1 mb-5 text-center">
        <h1>
          Pour modifier votre profil merci de suivre le lien
          <a
            class="text-info"
            href="https://chirurgiequebec.ca/wp-admin/profile.php"
            target="_blank"
            >Modifier le profil</a
          >
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 order-xl-1">
        <div>
          <user-edit-card :user="user" />
        </div>
      </div>
      <div class="col-xl-6 order-xl-2">
        <div>
          <user-password-card :user="user" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserEditCard from "@/views/Components/User/UserEditCard.vue"; 
import UserPasswordCard from "@/views/Components/User/UserPasswordCard.vue";


export default {
  layout: "DashboardLayout",

  components: {
    UserEditCard,
    UserPasswordCard,
  },

  data() {
    return {
      user: {
        type: "profile",
        name: null,
        email: null,
        profile_image: null,
      },
    };
  },
  created() {
    this.getProfile();
  },

  methods: {
    async getProfile() {
      await this.$store.dispatch("profile/me");
      this.user = await { ...this.$store.getters["profile/me"] };
    },
  },
};
</script>
