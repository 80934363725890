<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">Paiements</span>
      </div>
    </div>

    <div class="row mb-5">
      <el-table
        class="table-responsive align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="invoice.payments"
      >
        <el-table-column label="Code" min-width="80px" prop="code">
          <template slot-scope="{ row }">
            <a href="#" @click.prevent="viewPayment(row)">
              {{ row.code }}
            </a>
          </template>
        </el-table-column>

        <el-table-column label="Statut" prop="status">
          <template v-slot="{ row }">
            <payment-status-badge :payment="row" />
          </template>
        </el-table-column>

        <el-table-column
          label="Date"
          min-width="100px"
          prop="date"
          sortable="date"
        >
          <template v-slot="{ row }">
            {{ row.date | moment("LLLL") }}
          </template>
        </el-table-column>

        <el-table-column label="Statut" prop="status">
          <template v-slot="{ row }">
            {{ statusesOption[row.status] }}
          </template>
        </el-table-column>

        <el-table-column label="Montant" prop="amount">
          <template v-slot="{ row }"> ${{ row.amount.toFixed(2) }} </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="row mb-5">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">Total payé</dt>
          <dd class="col-sm-6 text-right">
            ${{
              invoice.total_paied !== undefined
                ? invoice.total_paied.toFixed(2)
                : null
            }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { paymentStatusesOption } from "../../../constants/payments";
import PaymentStatusBadge from "../../PaymentManagement/PaymentStatusBadge.vue";

export default {
  name: "invoice-view-details",

  components: {
    PaymentStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["invoice"],

  data() {
    return {
      statusesOption: paymentStatusesOption,
    };
  },

  computed: {},

  methods: {
    viewPayment(row) {
      this.$router.push({
        name: "View Payment",
        params: { id: row.id },
      });
    },
  },

  mounted() {},

  watch: {},
};
</script>
